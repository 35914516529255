import {Grid, TextField, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";

const DestinationInfo = ({destinationAddress, rmaNumber}) => {
    const {t} = useTranslation();
    if(!destinationAddress) return null;
    const {street, city, state, postal_code} = destinationAddress;
    return <>
        <Grid item xs={12} my={2}>
            <Typography variant="subtitle1"><Trans>DESTINATION NAME (Petaluma/LB/Milpitas)</Trans></Typography>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField variant="standard" value={street} disabled fullWidth required label={t('Address')}/>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField variant="standard" value={state} disabled fullWidth required label={t('State')}/>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField variant="standard" value={city} disabled fullWidth required label={t('City')}/>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField variant="standard" value={postal_code} disabled fullWidth required label={t('Zip Code')}/>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField variant="standard" value={rmaNumber} disabled fullWidth label={t('RMA #')}/>
        </Grid>
    </>
}
export default DestinationInfo;