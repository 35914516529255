import Box from "@mui/material/Box";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card, CircularProgress,
    Divider,
    Grid,
    IconButton,
    Link,
    Tooltip,
    Typography
} from "@mui/material";
import {Trans} from "react-i18next";
import {MdAddCircleOutline, MdCheck, MdDownload, MdExpandMore, MdInfo, MdLocalShipping} from "react-icons/md";
import returnDispositionService from "../../services/returnDispositionService";
import {useState} from "react";
import {logGAEvent} from "../../analytics/ga4";
import {toast} from "react-toastify";

export const FedexSection = ({rmaNumber, isScrap}) => {
    const [labels, setLabels] = useState([1]);
    const downloadLabel = async (index) => {
        logGAEvent({
            action: 'return_disposition_download_label',
            category: 'rma',
            label: 'return_disposition',
            value: rmaNumber
        });
        try{
            setLabels(prev => prev.map((e, i) => i === index ? 2 : e));
            await returnDispositionService.generateLabel(rmaNumber, `return_label#${index+1}.pdf`);
            setLabels(prev => prev.map((e, i) => i === index ? 0 : e));
        }catch (e) {
            toast.error(e?.response?.data?.message || 'Sorry unknown error occurred!');
            setLabels(prev => prev.map((e, i) => i === index ? 1 : e));
        }
    }
    const addMoreLabels = () => {
        setLabels(prev => [...prev, 1]);
        logGAEvent({
            action: 'return_disposition_add_more_label',
            category: 'rma',
            label: 'return_disposition',
            value: rmaNumber
        });
    }
    const labelIcons = [<MdCheck/>, <MdDownload/>, <CircularProgress variant="indeterminate" size={20} color="inherit"/> ]
    return (<Card><Accordion defaultExpanded={!isScrap}>
        <AccordionSummary sx={{backgroundColor: '#f7f7f7'}}
                          expandIcon={<MdExpandMore size={30}/>}>
            <Box>
                <Typography variant="h6"><Trans>Non-Storage Devices RMA</Trans></Typography>
                <Typography variant="caption"><Trans>Follow the steps to generate your return shipping
                    label(s)</Trans><InfoDialog/></Typography>
            </Box>
            <Divider/>
        </AccordionSummary>
        <AccordionDetails>
            <Typography sx={{mb: 2}} variant="h6"><Trans>Your Return Labels</Trans></Typography>
            <Divider/>
            {labels.map((label, index) => <Box key={index}>
                <Box py={1} display="flex" alignItems="center">
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={2} md={1}><MdLocalShipping size={30} style={{marginRight: 10}}
                                                                  color="#555"/></Grid>
                        <Grid item xs={10} md={2}><Typography variant="subtitle1"><Trans>Your Return Label
                            # </Trans>{index + 1}</Typography></Grid>
                        <Grid item xs={12} md={9}>
                            <Typography variant="body2"><Trans>Click to Download</Trans></Typography>
                        </Grid>
                    </Grid>
                    <IconButton disabled={!label} aria-label={`download-btn-${index}`}
                                onClick={() => downloadLabel(index)}
                                style={{marginLeft: 'auto'}}>{labelIcons[label]}</IconButton>
                </Box>
                <Divider/></Box>)}
            <Box pt={3}><Button onClick={addMoreLabels} startIcon={<MdAddCircleOutline/>}
                                variant="outlined">
                <Trans>Add Additional Labels</Trans></Button></Box>
        </AccordionDetails>
    </Accordion></Card>);
};
const InfoDialog = () => {
    return <Tooltip title={<Box>
        <ol>
            <li><Trans>fedex_instruction_1</Trans></li>
            <li><Trans>fedex_instruction_2</Trans></li>
            <li><Trans i18nKey="fedex_instruction_3">Apply the return shipping label to the box and then schedule
                a <Link target="_blank" href="http://www.fedex.com/pickup">pickup</Link>, or drop it off at your
                nearest <Link target="_blank" href="http://www.fedex.com/locate/">Fedex</Link> location.</Trans></li>
        </ol>
    </Box>} arrow>
        <IconButton size="small" aria-label='info'><MdInfo/></IconButton>
    </Tooltip>
}
export default FedexSection;