import {Card, CardContent, CardHeader} from "@mui/material";
import {makeStyles} from "@mui/styles";
const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white
    }

}));
export default function TableWrapperCard({title, icon, children}) {
    const classes = useStyles();
    return <Card>
        <CardHeader title={title} avatar={icon} className={classes.header}/>
        <CardContent>
            {children}
        </CardContent>
    </Card>
}