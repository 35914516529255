import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {MdCheckCircle} from 'react-icons/md';
import Typography from '@mui/material/Typography';
import {Trans, useTranslation} from "react-i18next";
import {Alert, Box, CircularProgress, FormControl, InputLabel, Link, MenuItem, Select, TextField} from "@mui/material";
import WarningMessage from "./WarningMessage";
import {useTheme} from "@mui/styles";
import {InfoFilled} from "@enphase/designsystems/Icons";
import {useDispatch, useSelector} from "react-redux";
import {loadTopics} from "../../model/topics";
import SiteSearch from "./SiteSearch";
import {loadShippingAddress} from "../../model/shippingAddress";
import systemsService from "../../services/systemsService";
import DevicesSelection from "./DevicesSelection";
import caseService from "../../services/caseService";
import DialogTitleWithCloseButton from "../DialogTitle";
import ShippingAddressSelection from "./ShippingAddressSelection";
import {logGAEvent, logGAException} from "../../analytics/ga4";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    }, '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function CreateRMADialog() {
    const {t} = useTranslation();
    const theme = useTheme();
    const {site: selectedSite} = useSelector(state => state.site);
    const [open, setOpen] = useState(false);
    const [site, setSite] = useState({});
    const [devices, setDevices] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [selectedShippingAddress, setSelectedShippingAddress] = useState('');
    const [error, setError] = useState('');
    const [description, setDescription] = useState('');
    const [selectedTopic, setSelectedTopic] = useState('');
    const {shippingAddress, topics} = useSelector(state => state);
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const [createdCaseNumber, setCreatedCaseNumber] = useState('');
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    useEffect(() => {
        setSelectedTopic('');
        setSite(+selectedSite.id > 0 ? selectedSite : {});
        setSelectedShippingAddress('');
        setDescription('');
        dispatch(loadTopics());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSite.id, open]);

    useEffect(() => {
        if (!site?.id) return;
        setSelectedDevices([]);
        setError('');
        dispatch(loadShippingAddress({systemId: site.id}));
        systemsService.getDevicesForSystemId(site.id)
            .then(data => setDevices(data))
            .catch(error => {
                setDevices([]);
                setError(error?.response?.data?.code || t('Error loading devices'));
            })
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site.id, open]);

    const handleClickOpen = () => {
        logGAEvent({
            action: 'create_rma',
            category: 'rma',
            label: 'create_rma',
            value: site.id
        });
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const isValid = () => {
        if(!selectedSite.id){
            return false;
        }
        if(!description){
            return false;
        }
        if(!selectedTopic){
            return false;
        }
        if(selectedDevices.length === 0){
            return false;
        }
        if(!selectedShippingAddress){
            return false;
        }
        return true;
    }

    const onSubmit = () => {
        setSubmitting(true);
        const [category, index] = selectedShippingAddress.split('~!');
        const shipping_address = shippingAddress.data.filter(c => c.category === category)[0].address[index];
        logGAEvent({
            action: 'create_rma_submit',
            category: 'rma',
            label: 'create_rma',
            value: site.id
        });
        caseService.createCase(site.id, {
            "site_id": site.id,
            "description": description,
            "topic": selectedTopic,
            "serial_numbers": selectedDevices,
            shipping_address
        }).then(data => {
            setCreatedCaseNumber(data.case_number);
            handleClose();
            setOpenSuccessDialog(true);
            setSubmitting(false);
        })
            .catch(error => {
                logGAException({description: 'error in rma creation', fatal: true});
                setError(error?.response?.data?.code);
                setSubmitting(false);
            });
    };

    return (<div>
        <Button variant="contained" onClick={handleClickOpen} >
            <Trans>Create RMA</Trans>
        </Button>
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="create-rma-dialog"
            open={open}
            maxWidth="md"
            fullWidth
        >
            <DialogTitleWithCloseButton id="create-rma-dialog-title" onClose={handleClose}>
                <Trans>New RMA Request</Trans>
            </DialogTitleWithCloseButton>
            <DialogContent dividers>
                {site?.id && error && <Box mb={5}><Alert severity="error"><Trans>{error}</Trans></Alert></Box>}
                <SiteSearch selectedSite={site} setSelectedSite={setSite}/>
                <Box my={5}/>
                <WarningMessage/>
                <Box my={5}/>
                <TextField
                    id="description"
                    label={t('Description')}
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                />
                <Box my={5}/>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="select-topic-label"><Trans>Topic</Trans></InputLabel>
                    <Select
                        labelId="select-topic-label"
                        id="select-topic"
                        value={selectedTopic}
                        onChange={e => setSelectedTopic(e.target.value)}
                        label={t('Topic')}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {topics?.data?.map(topic => <MenuItem key={topic.key}
                                                              value={topic.key}>{topic.title}</MenuItem>)}
                    </Select>
                </FormControl>
                <Box my={5}/>
                <Typography variant="body2">
                    <Trans>Have you tried searching our Enphase Support resources?</Trans>
                    <Link
                        target="_blank"
                        rel="noreferrer noopener"
                        href={'https://support.enphase.com/s/'}>
                        <Trans>Learn More</Trans></Link>
                </Typography>
                <Box my={5}/>
                <DevicesSelection devices={devices} selectedDevices={selectedDevices}
                                  setSelectedDevices={setSelectedDevices}/>
                <Box my={5}/>
                <Box style={{backgroundColor: '#d1eff6'}} p={theme.spacing(2)}
                     borderRadius={theme.shape.borderRadius / 4}>
                    <Typography variant="body2">
                        <Trans>In the event a Warranty Claim is issued for your selected device(s), please choose a
                            shipping address you would like to receive replacement order.</Trans>
                    </Typography>
                </Box>
                <Box my={5}/>
                <ShippingAddressSelection shippingAddress={shippingAddress}
                                          selectedShippingAddress={selectedShippingAddress}
                                          setSelectedShippingAddress={setSelectedShippingAddress}/>
                <Box my={5}/>
                <Box display="flex" alignItems="start">
                    <InfoFilled/>
                    <Typography variant="caption" ml={1}>
                        <Trans>You will receive an email confirmation containing details of the following submitted
                            request.</Trans>
                    </Typography>
                </Box>
                <Box my={5}/>
            </DialogContent>
            <DialogActions>
                <Button autoFocus disabled={submitting} onClick={handleClose} variant="outlined" color="inherit"
                        name="cancel">
                    <Trans>Cancel</Trans>
                </Button>
                <Button onClick={onSubmit} name="submit" variant="contained"
                        endIcon={submitting && <CircularProgress variant="indeterminate" size={20}/>}
                        disabled={submitting || !isValid()}
                        color="primary"><Trans>Submit</Trans></Button>
            </DialogActions>
        </BootstrapDialog>
        <Dialog open={openSuccessDialog}>
            <DialogContent sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}} dividers>
                <MdCheckCircle color={theme.palette.primary.main} size={72}/>
                <Box my={theme.spacing(2)}><Typography variant="subtitle1" fontWeight={700}>
                    <Trans>Your RMA request has been submitted successfully</Trans></Typography></Box>
                <Typography><Trans>Case No.</Trans> - {createdCaseNumber}</Typography>
            </DialogContent>
            <DialogActions sx={{justifyContent: 'center'}}>
                <Button autoFocus onClick={() => setOpenSuccessDialog(false)} variant="outlined" color="inherit"
                        name="close">
                    <Trans>Close</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}
