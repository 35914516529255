import {useState} from "react";
import {InputAdornment, TextField} from "@mui/material";
import {Search} from "@enphase/designsystems/Icons";

export function TextFilter({onFilter, disabled}) {
    const [filter, setFilter] = useState('');

    function onBlur() {
        if (!filter) {
            onFilter('');
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        onFilter([filter]);
    }

    return <form onSubmit={handleSubmit} autoComplete="off">
        <TextField variant="outlined" size="small" fullWidth
                   label=" " InputLabelProps={{shrink: false}}
                   value={filter}
                   onChange={(e) => setFilter(e.target.value)}
                   disabled={disabled}
                   onBlur={onBlur}
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end" role="button" sx={{cursor: 'default'}} onClick={() => onFilter([filter])}>
                               <Search fontSize={20}/>
                           </InputAdornment>
                       ),
                   }}
        /></form>
}
