import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Pagination, PaginationItem} from "@mui/material";

export function PaginationSection({total,locale, rowsPerPage, handleChangePage, handleChangeRowsPerPage, page, align}) {
    const paginationOptions = [10, 20, 50, 100].map(option => <option key={option} value={option}>{option} {locale.items_per_page}</option>);
    return <Box display="flex" alignItems="center" justifyContent={align === 'left' ? 'start' : 'flex-end'}>
        <Typography variant="body2">{locale.Total} {total} {locale.Records}</Typography>
        <Pagination
            renderItem={(item) => <PaginationItem sx={{
                '&.Mui-selected': {
                    border: '1px solid #F3731F',
                    color: '#F3731F',
                    backgroundColor: '#fff',
                    borderRadius: 0.5
                }
            }}  {...item} />}
            size="small"
            color="primary"
            count={Math.ceil(total / rowsPerPage)}
            variant='text'
            shape="rounded"
            page={page}
            onChange={handleChangePage}
        />
        <select data-testid="select-page-size" onChange={handleChangeRowsPerPage} className="pagination-dropdown">
            {paginationOptions}
        </select>
    </Box>
}
