import {useEffect, useState} from "react";
import {Checkbox, MenuItem, TextField} from "@mui/material";

export function DropdownFilter({options, onFilter,disabled}) {
    const [filter, setFilter] = useState([]);
    useEffect(() => {
        if (!filter || filter.length < 1) {
            onFilter(null);
        } else {
            onFilter(filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);
    return <TextField select
                      SelectProps={{
                          multiple: true,
                          autoWidth: true,
                          renderValue: (selected) => (selected.length > 1) ? selected[0] +' + '+ (selected.length-1) : selected.join('')
                      }}
                      disabled={disabled}
                      sx={{minWidth: 200}}
                      variant="outlined" fullWidth
                      value={filter} size={"small"}
                      label={" "}
                      onChange={e => setFilter(e.target.value)}
                      InputLabelProps={{shrink: false}}>
        <MenuItem value={''}></MenuItem>
        {options.map(o => <MenuItem key={o.value} value={o.value}>
            <Checkbox checked={filter.indexOf(o.text) > -1}/> {o.text}
        </MenuItem>)}
    </TextField>;
}
