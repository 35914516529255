import {
    Box,
    ButtonBase,
    Chip,
    CircularProgress,
    Divider,
    FormHelperText,
    InputAdornment,
    Popover,
    TextField
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAllSites, selectSite} from "../model/site";
import {getValue, setValue} from "../services/localStorage";
import {useSiteSearch} from "./useSiteSearch";
import {logGAEvent} from "../analytics/ga4";

export default function GlobalSiteSearch() {
    const [open, setOpen] = useState(false);
    const anchorEl = useRef();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {site: selectedSite} = useSelector(state => state.site);
    const {inProgress, error, site, setSite, siteId, setSiteId, searchSite} = useSiteSearch();
    useEffect(() => {
        const stored = getValue('selectedSite');
        if (stored && stored === 'all') {
            dispatch(selectAllSites());
        } else if (stored) {
            dispatch(selectSite({site: JSON.parse(stored)}));
        } else {
            setValue('selectedSite', 'all');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelect = (site) => {
        if (site === 'all') {
            dispatch(selectAllSites());
            setValue('selectedSite', 'all');
        } else {
            logGAEvent({
                action: 'site_search',
                category: 'homepage',
                label: 'homepage',
                value: site.id
            });
            dispatch(selectSite({site}));
            setValue('selectedSite', JSON.stringify(site));
        }
        setOpen(false);
        setSiteId('');
    }
    const showHelperText = () => {
        if (!siteId || inProgress) {
            return '';
        } else if (error) {
            return t(error);
        } else if (site?.result?.length === 0) {
            return t('No Results Found');
        }
    }
    const handleClearSite = () => {
        setSite({});
        dispatch(selectSite({site: {}}));
        setTimeout(() => anchorEl.current.getElementsByTagName('input')[0].focus(), 100);
    }
    const handleBlur = () => {
        if (!site.id && !open) {
            dispatch(selectAllSites());
            setValue('selectedSite', 'all');
            setSiteId('');
        }
    }
    return <div>
        <Box>
            <TextField label={t('Enter Site Id')} ref={anchorEl}
                       style={{backgroundColor: '#fff', width: 300}}
                       size="small"
                       autoFocus
                       onBlur={handleBlur}
                       onClick={() => setOpen(true)}
                       InputProps={{
                           startAdornment: (<InputAdornment position={"start"}>
                               {selectedSite?.id &&
                                   <Chip
                                       label={`${t(selectedSite.name)}${selectedSite.id > 0 ? '- ' + selectedSite.id : ''}`}
                                       style={{textOverflow: 'ellipsis', maxWidth: 275}}
                                       size="small"
                                       onDelete={handleClearSite}/>}
                           </InputAdornment>),
                           endAdornment: (
                               <InputAdornment position='end'>
                                   {inProgress && <CircularProgress variant="indeterminate" size={20}/>}
                               </InputAdornment>
                           )
                       }} value={siteId} onChange={e => searchSite(e.target.value, () => setOpen(true))}
                       disabled={!!(selectedSite?.id)}
            />
            <FormHelperText>{showHelperText()}</FormHelperText>
        </Box>
        <Popover
            id="site-results"
            open={open}
            anchorEl={anchorEl.current}
            onClose={() => setOpen(false)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            {site?.result?.length > 0 && <ButtonBase onClick={() => handleSelect(site.result[0])}
                                                     style={{
                                                         width: anchorEl.current?.offsetWidth,
                                                         justifyContent: 'start',
                                                         textAlign: 'left',
                                                         wordBreak: 'break-all',
                                                         paddingLeft: 16,
                                                         paddingRight: 16
                                                     }}
                                                     sx={{p: 2}}>{site.result[0].name} - {site.result[0].id}</ButtonBase>}
            <Divider/>
            <ButtonBase onClick={() => handleSelect('all')}
                        style={{width: anchorEl.current?.offsetWidth, justifyContent: 'start'}}
                        sx={{p: 2}}><Trans>Select All Sites</Trans></ButtonBase>
        </Popover>
    </div>
}