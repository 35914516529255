import {Alert, Button, Card, CardHeader, LinearProgress} from "@mui/material";
import RMAStatus from "./RMAStatus";
import {Trans, useTranslation} from "react-i18next";
import {useEffect} from "react";
import useSubmittedDateRange from "../useSubmittedDateRange";
import {useDispatch, useSelector} from "react-redux";
import {clearAllSelection, loadRmaStatus} from "../../model/rmaStatus";


export default function Status() {
    const {t} = useTranslation();
    const {dateRange} = useSubmittedDateRange();
    const {error, loading, selected} = useSelector(state => state.rmaStatus);
    const {site} = useSelector(state => state.site);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadRmaStatus({...dateRange, siteId: site.id}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.fromDate, dateRange.toDate, site.id]);
    const ClearAllButton = selected.length > 0 ? <Button onClick={() => dispatch(clearAllSelection())}><Trans>Clear All</Trans></Button>: null;
    return <Card>
        <CardHeader title={t("RMA Status")}
                    action={ClearAllButton}/>
        {loading && <LinearProgress variant="indeterminate"/>}
        {error && <Alert severity="error">{error}</Alert>}
        <RMAStatus/>
    </Card>;
}