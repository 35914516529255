import {FormControl, InputLabel, LinearProgress, ListSubheader, MenuItem, Select} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import React from "react";

export default function ShippingAddressSelection({
                                                     selectedShippingAddress,
                                                     setSelectedShippingAddress,
                                                     shippingAddress
                                                 }) {
    const {t} = useTranslation();
    const options = [];
    const {loading, data, error} = shippingAddress;
    if(!loading && !error) {
        data.forEach(category => {
            options.push(<ListSubheader key={category.category}>{category.category}</ListSubheader>);
            category.address.forEach((address, index) => {
                options.push(<MenuItem key={category.category + index}
                                       style={{display: "block"}}
                                       value={`${category.category}~!${index}`}>
                    <Typography component={'div'}>{address.street}</Typography>
                    <Typography
                        component={'div'}>{address.city}, {address.state}, {address.country}, {address.postal_code}</Typography>
                </MenuItem>);
            })
        })
    }
    if(loading){
        return <LinearProgress variant="indeterminate"/>
    }
    return <FormControl variant="standard" fullWidth>
        <InputLabel id="select-shipping-address-label"><Trans>Shipping Address</Trans></InputLabel>
        <Select
            labelId="select-shipping-address-label"
            id="select-shipping-address"
            value={selectedShippingAddress}
            disabled={options.length < 1}
            onChange={e => setSelectedShippingAddress(e.target.value)}
            label={t('Shipping Address')}
        >
            <MenuItem value="">
                <em><Trans>None</Trans></em>
            </MenuItem>
            {options}
        </Select>
    </FormControl>;
}