import Dialog from "@mui/material/Dialog";
import {DialogTitle, Divider, IconButton} from "@mui/material";
import {Trans} from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import FedexSection from "./FedexSection";
import ChRobinsonSection from "./ChRobinsonSection";
import {MdClose} from "react-icons/md";
import RecycleSection from "./RecycleSection";

const RDDialog = ({open, handleClose, rmaNumber, returnLabelTypes=[]}) => {
    const isScrap = returnLabelTypes.includes('scrap')
    return (
        <Dialog open={open} fullWidth maxWidth="lg">
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Trans>Initiate Return - {{rmaNumber}}</Trans>
                <IconButton onClick={handleClose}><MdClose/></IconButton>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                {returnLabelTypes.includes('scrap') ? <RecycleSection/>: null}
                {returnLabelTypes.includes('fedex') ? <FedexSection isScrap={isScrap} rmaNumber={rmaNumber}/>: null}
                {returnLabelTypes.includes('ch_robinson') || returnLabelTypes.includes('freeway') ? <ChRobinsonSection isScrap={isScrap} rmaNumber={rmaNumber} returnLabelTypes={returnLabelTypes}/>: null}
            </DialogContent>
        </Dialog>
    );
};
export default RDDialog;