import {Button, CircularProgress} from "@mui/material";
import {Trans} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import returnDispositionService from "../../services/returnDispositionService";
import {useSelector} from "react-redux";
import {logGAEvent, logGAException} from "../../analytics/ga4";
import RDDialog from "./RDDialog";

const ReturnDisposition = () => {
    const {data: rmaDetails} = useSelector(state => state.rmaDetails);
    const [eligibility, setIsEligibility] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const isRMAApproved =  useMemo(() =>!!rmaDetails?.legacy_rma_issued_date, [rmaDetails?.legacy_rma_issued_date]);
    const handleClick = ()=> {
        setOpen(true);
        logGAEvent({
            action: 'return_disposition_btn',
            category: 'rma',
            label: 'return_disposition',
            value: rmaDetails.rma_number
        });
    }
    useEffect(() => {
        if (isRMAApproved && rmaDetails?.rma_number) {
            setLoading(true);
            setError('');
            returnDispositionService.isEligible(rmaDetails.rma_number)
                .then(data => setIsEligibility(data))
                .catch(error => {
                    logGAException({description: 'Failed to check eligibility for rd', fatal: true});
                    setError('Failed to check eligibility for return');
                }).finally(() => setLoading(false));
        }
    }, [rmaDetails.rma_number, isRMAApproved]);
    if(loading){
        return <CircularProgress variant="indeterminate"/>;
    }
    if(!isRMAApproved || error){
        return null;
    }
    return <>
        {eligibility.eligible ? <Button onClick={handleClick} variant="outlined"><Trans>Initiate Return</Trans></Button> : null}
        <RDDialog
            open={open}
            rmaNumber={rmaDetails.rma_number}
            returnLabelTypes={eligibility.return_label_type}
            handleClose={() => setOpen(false)}/>
    </>
}

export default ReturnDisposition;