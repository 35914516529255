import Cookies from 'js-cookie';
import tokenService from "./tokenService";

export const setValue = (key, value) => Cookies.set(tokenService.addSessionIdAndEmailString(key), value, {
    sameSite: 'none',
    secure: true
});

export const storeToken = (key, token) => Cookies.set(key, token, {
    sameSite: 'none',
    secure: true
});
export const retrieveToken = (key) => Cookies.get(key);

export const getValue = key => Cookies.get(tokenService.addSessionIdAndEmailString(key));

export const removeKey = key => {
    const s = tokenService.addSessionIdAndEmailString(key);
    console.log(s);
    Cookies.remove(s, {sameSite: 'none', secure: true});
    console.log('removed', key);
}
