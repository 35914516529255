import {DialogTitle, IconButton} from "@mui/material";
import {MdClose} from "react-icons/md";

export default function DialogTitleWithCloseButton(props){

        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <MdClose />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
}