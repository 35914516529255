import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Trans} from "react-i18next";
import tokenService from "../../services/tokenService";
import {logGAEvent} from "../../analytics/ga4";

const headingStyle = {fontWeight: 700, my: 2};
export default function NotEligibleModal() {
    const [open, setOpen] = useState(false);
    const [token, setToken] = useState('');
    useEffect(() => {
        const token = tokenService.getToken();
        setToken(token);
    }, []);
    const onClick = () =>{
        setOpen(true);
        logGAEvent({
            action: 'lre_not_eligible_dialog',
            category: 'rma_details_page',
        });
    }
    return <div>
        <Button variant="outlined" onClick={onClick}><Trans>Apply for Labor Reimbursement</Trans></Button>
        <Dialog open={open} maxWidth="lg" fullWidth onClose={() => setOpen(false)}>
            <DialogTitle><Trans>Not Qualified</Trans></DialogTitle>
            <DialogContent>
                <Typography sx={headingStyle} variant="subtitle1"><Trans>Labour Reimbursement</Trans></Typography>
                <Typography><Trans>not_eligible_para_1</Trans></Typography>
                <Typography sx={headingStyle} variant="subtitle1"><Trans>In order to qualify:</Trans></Typography>
                <Typography component="div">
                    <ul>
                        <li><Trans>Must be a reimbursement-eligible product as determined by Enphase.</Trans></li>
                        <li><Trans>A defective microinverter must have previously been detected and monitored by
                            Enlighten monitoring software.</Trans></li>
                        <li><Trans>The unit installation date must be within two years of the claim date.</Trans></li>
                        <li><Trans>The defective microinverter must be returned to Enphase Energy if instructed to do so
                            per the RMA approval.</Trans></li>
                        <li><Trans>No claims older than 3 months / 6 months (based on your country) will be
                            approved.</Trans></li>
                    </ul>
                </Typography>
                <Typography mb={2}><Trans i18nKey="not_eligible_tnc">For more details please refer to the <a
                    rel="noreferrer"  target="_blank"
                    href="https://enphase.com/installers/resources/warranty">T&C</a> for Labor
                    reimbursement.</Trans></Typography>
                <Typography><Trans i18nKey="not_eligible_lpp">Note: For US sites you can extend the 2-year warranty
                    period up to 10 years from <a
                        target="_blank" rel="noreferrer"
                        href={`https://enlighten.enphaseenergy.com/manager/dashboard/labor-protection?token=${token}`}>here</a>.
                    To learn more, click <a href="https://enphase.com/en-us/lpp-overview" target="_blank"
                                            rel="noreferrer">here</a></Trans></Typography>
            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
                <Button variant="contained" onClick={() => setOpen(false)}><Trans>Ok</Trans></Button>
            </DialogActions>
        </Dialog>
    </div>;
}