import ga4 from 'react-ga4'
import getConfig from "../services/env";

export const getTrackingId = () =>  getConfig().google_analytics_id;

export const init = (userId) => getTrackingId() ? ga4.initialize(getTrackingId(),{
    gaOptions: {cookie_flags: 'secure;samesite=none', user_id: userId}
}) : null;

export const logGAEvent = ({action, category, label, value}) => {
    if (!getTrackingId()) return;
    const options = {action, category};
    if (label) options.label = label;
    if (value) options.value = value;
    return ga4.event(options);
}
export const logGAException = ({description, fatal}) => {
    if (!getTrackingId()) return;
    window.gtag('event', 'exception', {
        description,fatal
    });
}

export const sendPageView = (path) => {
    if (!getTrackingId()) return;
    return ga4.send({
        hitType: 'pageview',
        page: path
    })
}