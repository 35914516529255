import React from "react";
import {Alert, Snackbar} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {closeSnackbar} from "../model/snackbar";

export default function SnackbarView() {
    const {open, message, severity, delay} = useSelector(state => state.snackbar);
    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar());
    };
    return <Snackbar open={open} autoHideDuration={delay} onClose={handleClose}
                     anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
        <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
            {message}
        </Alert>
    </Snackbar>;
}