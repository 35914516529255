import {useDispatch} from "react-redux";
import {openSnackbar} from "../model/snackbar";

export default function useSnackbar(delay) {
    let running = false;

    const snackbars = [];
    const dispatch = useDispatch();

    function triggerSnack() {
        if (!snackbars.length) {
            running = false;
            return;
        }
        running = true;
        const bar = snackbars.shift();
        dispatch(openSnackbar(bar));
        setTimeout(triggerSnack, delay + 100);
    }

    function enqueueSnackbar({message, severity}) {
        snackbars.push({message, severity, delay});
        if (!running) {
            triggerSnack();
        }
    }

    return enqueueSnackbar;
}