import {Step, StepLabel, Stepper, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import Paper from "@mui/material/Paper";
import {InfoFilled} from "@enphase/designsystems/Icons";

export default function Status({status = {}}) {

    const {t} = useTranslation();

    function getPayText() {
        if (!status.approved?.completedDate) return '';
        return status.paid?.completedDate ?
            `${t('Payment dispatch date:')} ${status.paid?.completedDate}` : `${t('Expected Payment Date:')} ${status.paid?.expectedDate}`;
    }

    function getStep() {
        if (status.paid?.completedDate) {
            return 4;
        } else if (status.approved?.completedDate) {
            return 2
        }
        return 1;
    }

    return <Paper sx={{p: 4}} variant="outlined">
        <Stepper activeStep={getStep()}>
            <Step>
                <StepLabel>
                    <Typography variant="subtitle1">
                        <Trans>Requested</Trans>
                    </Typography>
                    <Typography variant="caption">{status.requested?.completedDate}</Typography>
                </StepLabel>
            </Step>
            <Step>
                <StepLabel>
                    <Typography variant="subtitle1">
                        <Trans>In Process</Trans>
                    </Typography>
                </StepLabel>
            </Step>
            <Step>
                <StepLabel>
                    <Typography variant="subtitle1">
                        <Trans>Approved</Trans>
                    </Typography>
                    <Typography variant="caption">{status.approved?.completedDate}</Typography>
                </StepLabel>
            </Step>
            <Step>
                <StepLabel>
                    <Typography variant="subtitle1">
                        <Trans>Paid</Trans>
                    </Typography>
                    <Typography variant="caption">{getPayText()} </Typography>
                    {status.paid?.completedDate ? <span
                        title={t("You will receive your payment after a few days of the payment dispatch date based on your payment mode i.e. wire transfer (within 48hrs) and check transfer (within 4-5 business days)")}>
                        <InfoFilled/>
                    </span> : null}
                </StepLabel>
            </Step>
        </Stepper>
    </Paper>
}