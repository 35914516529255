import {Box} from "@mui/material";
import SubmittedDateRangeSelection from "../components/SubmittedDateRangeSelection";
import RMAListView from "../components/RMAListView";
import Status from "../components/StatusView";

export default function HomePage() {

    return <>
        <SubmittedDateRangeSelection/>
        <Box mt={2}><Status/></Box>
        <Box mt={2}><RMAListView/></Box>
    </>
}