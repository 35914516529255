import {
    MdOutlineCancel,
    MdOutlineChecklistRtl,
    MdOutlineHourglassEmpty,
    MdOutlineLocalShipping
} from "react-icons/md";
import {IoMdCheckmarkCircleOutline} from "react-icons/io";

export const config = {
    IN_PROGRESS: {icon: <MdOutlineHourglassEmpty size="40" color="gray"/>, color: '#FFA854'},
    APPROVED: {icon: <IoMdCheckmarkCircleOutline size="40" color="gray"/>, color: '#7CC48F'},
    PENDING_APPROVAL: {icon: <MdOutlineChecklistRtl size="40" color="gray"/>, color: '#EEC963'},
    SHIPPED: {icon: <MdOutlineLocalShipping size="40" color="gray"/>, color: '#A4B6E4'},
    CANCELLED: {icon: <MdOutlineCancel size="40" color="gray"/>, color: '#ED787D'},
}