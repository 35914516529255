import {Box, Card, CardActionArea, CardContent, CardHeader, Typography} from "@mui/material";
import {makeStyles, useTheme} from "@mui/styles";
import {useState} from "react";
import {AiFillCheckCircle} from "react-icons/ai";

const useStyles = makeStyles(theme => ({
    dot: {
        height: 10,
        width: 10,
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: 10
    },
    cardSelected: {
        outline: `2px solid ${theme.palette.primary.light}`,
    },
    cardHover: {
        boxShadow: '0px 0px 5px 1px rgb(243 115 31 / 50%)',
        transition: 'box-shadow 0ms'
    },
    icon: {
        position: 'absolute',
        top: -8,
        right: -8,
        margin: 0,
    }
}));
export default function StatusCard({icon, color, text, number, isSelected, onSelect}) {
    const classes = useStyles();
    const theme = useTheme();
    const [hover, setHover] = useState(false);
    return <Box position="relative" sx={{margin: 1, flexGrow: 1, flexBasis: 160}}>
        {isSelected ? <AiFillCheckCircle size={25} className={classes.icon} color={theme.palette.primary.main}/> :
            hover? <AiFillCheckCircle size={25} className={classes.icon} color={theme.palette.primary[100]}/>: null}
        <Card className={isSelected? classes.cardSelected: (hover? classes.cardHover: '')}
                 square={isSelected}
                 onMouseOver={() =>setHover(!isSelected)}
                 onMouseLeave={() => setHover(false)}
                 variant="outlined"
    >

        <CardActionArea onClick={onSelect}>
            <CardHeader title={icon} disableTypography sx={{paddingBottom: 0}}
                        />
            <CardContent sx={{paddingTop: 1}}>
                <Box display="flex" justifyContent="start" alignItems="baseline">
                    <span className={classes.dot} style={{backgroundColor: color}}/>
                    <Typography variant="caption" sx={{mr: 1, whiteSpace: 'nowrap'}}>{text}</Typography>
                    <Typography variant="subtitle1">{number}</Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card></Box>
}