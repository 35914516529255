import systemsService from "../services/systemsService";
import {useRef, useState} from "react";
import {useTranslation} from "react-i18next";

export function useSiteSearch() {
    const [inProgress, setInProgress] = useState(false);
    const [siteId, setSiteId] = useState('');
    const [error, setError] = useState('');
    const [site, setSite] = useState({});
    const timer = useRef(null);
    const {t} = useTranslation();

    function searchSite(searchString, onSuccess) {
        if (isNaN(searchString))
            return;
        setSiteId(searchString);
        setError('');
        if (timer.current) clearTimeout(timer.current);
        if (!searchString) return;
        timer.current = setTimeout(() => {
            setInProgress(true);
            systemsService.getSystems(searchString)
                .then(res => {
                    setSite(res);
                    onSuccess();
                    setInProgress(false);
                }).catch(error => {
                setInProgress(false);
                if (error?.response?.status === 403) {
                    setError(t('System sync issue - Please contact Enphase Support'))
                } else {
                    setError(error?.response?.data?.code)
                }
            });
        }, 1000);
    }

    return {inProgress, error, site,setSite, siteId,setSiteId, searchSite};
}