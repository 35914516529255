import {retrieveToken, storeToken} from "./localStorage";
import jwtDecode from 'jwt-decode';

class TokenService {
    getToken = () => retrieveToken(`_rma_dashboard_token_`);
    setToken = (token) => storeToken(`_rma_dashboard_token_`, token);

    setTokenFromUrl = (search) => {
        const searchParams = new URLSearchParams(search);
        const token = searchParams.get('token');
        this.setToken(token);
    }
    decodeToken = () => {
        let decoded = {error: null};
        try {
            decoded = jwtDecode(this.getToken())['data'];
            decoded['error'] = null;
        } catch (error) {
            console.log('JWT token verify error:', error);
            decoded.error = error.message;
        }
        return decoded;
    };
    addSessionIdAndEmailString = (key) => {
        const decoded = this.decodeToken();
        return key + ((decoded.error) ? '' : decoded.session_id + decoded.email_id);
    }
}

export default new TokenService();