import {useTranslation} from "react-i18next";
import CardView from "../CardView";
import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import AddressComponent from "./AddressComponent";
import {setChangeRegisteredAddress, updateAddress} from "./formReducer";

export default function RegisteredAddress({registeredAddress,changeRegisteredAddress, dispatch, errors}) {
    const {t} = useTranslation();
    function updateRegisteredAddress(value){
        dispatch(setChangeRegisteredAddress(value));
    }
    return <CardView title={t('Registered Address')}>
        <AddressComponent address={registeredAddress} disabled={!changeRegisteredAddress} errors={errors} handleChange={(name, value) => dispatch(updateAddress(name, value))}/>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}> <FormControlLabel value={changeRegisteredAddress} onChange={e => updateRegisteredAddress(e.target.checked)} control={<Checkbox/>}
                                                          label={t('Do you want to change your registered Address?')}/></Grid>
        </Grid>
    </CardView>
}