import {all, call} from 'redux-saga/effects';
import {rmaDetailsSaga} from "./model/rmaDetails";
import {rmaStatusSaga} from "./model/rmaStatus";
import {caseDetailsSaga} from "./model/caseDetails";
import {topicsSaga} from "./model/topics";
import {shippingAddressSaga} from "./model/shippingAddress";

export default function* rootSaga () {
    yield all([
        call(rmaDetailsSaga),
        call(rmaStatusSaga),
        call(caseDetailsSaga),
        call(topicsSaga),
        call(shippingAddressSaga)
    ]);
}