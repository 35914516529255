import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {MdInfo} from "react-icons/md";
import {useState} from "react";
import {Trans} from "react-i18next";

export default function InstructionsModal() {
    const [open, setOpen] = useState(false);
    return <div>
        <IconButton onClick={() => setOpen(true)}><MdInfo size={20}/></IconButton>
        <Dialog open={open} maxWidth="lg" fullWidth onClose={() => setOpen(false)}>
            <DialogTitle><Trans>Instructions</Trans></DialogTitle>
            <DialogContent>
                <Typography><Trans>instructions_para_1</Trans></Typography>


                <Typography sx={{my: 2}} variant="subtitle1"><Trans>To claim a reimbursement as part of this
                    program:</Trans></Typography>

                <Typography component="div">
                    <ol>
                        <li>
                            <div><Trans>Obtain a Returned Merchandise Authorization (RMA).</Trans>

                                <ul>
                                    <li><Trans i18nKey="link_to_support">Contact <a target="_blank"  rel="noreferrer" href="https://enlighten.enphaseenergy.com/support">Enphase Customer Support</a></Trans></li>
                                    <li><Trans>Documentation of ownership may be required to obtain an RMA.</Trans></li>
                                    <li><Trans>Equipment received without authorization is not eligible for replacement or reimbursement.</Trans></li>
                                    <li><Trans>Currently shipping products are covered for the term of two years from
                                        date
                                        of install, subject to change without notice.</Trans></li>
                                    <li><Trans>At its discretion, Enphase may require the failed unit to be sent back before providing a replacement.</Trans></li>
                                    <li><Trans>At its discretion Enphase may provide the option of scrapping locally or
                                        returning the failed inverter to Enphase.</Trans></li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <div>
                                <ul>
                                    <li><Trans>We ship the replacement microinverter.</Trans></li>
                                    <li><Trans>Upon issuance of the RMA, Enphase Energy will ship the replacement microinverter to the address specified.</Trans></li>
                                    <li><Trans>If the instruction is to scrap, you may replace the inverter and scrap the
                                        failed inverter.</Trans></li>
                                    <li><Trans i18nKey="print_label">If instructed to return the failed inverter;
                                        Ïplease place the defective device into the same shipping box, <a target="_blank" rel="noreferrer" href="https://returnlabel.enphaseenergy.com/">print a shipping tag</a>, and either call the shipping vendor or drop off the box at the nearest shipping location.</Trans></li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div>
                                <ul>
                                    <li><Trans>We issue the reimbursement.</Trans></li>
                                    <li><Trans>In order to qualify:</Trans></li>
                                    <li><Trans>Must be an reimbursement eligible product as determined by Enphase.</Trans></li>
                                    <li><Trans>Defective microinverter must have previously been detected and monitored by
                                        Enlighten monitoring software.</Trans></li>
                                    <li><Trans>The unit installation date must be within two years of the claim date.</Trans></li>
                                    <li><Trans>The defective microinverter must be returned to Enphase Energy if instructed
                                        to do so per the RMA approval.</Trans></li>
                                    <li><Trans>No claims older than 3 months will be approved.</Trans></li>
                                </ul>
                            </div>
                        </li>
                    </ol>
                </Typography>
            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
                <Button variant="contained" onClick={() => setOpen(false)}><Trans>Ok</Trans></Button>
            </DialogActions>
        </Dialog>
    </div>;
}