import {useNavigate, useParams} from "react-router-dom";
import BreadCrumbView from "../components/BreadCrumbView";
import {Alert, Box, Button, CircularProgress, Grid, LinearProgress, Paper, Typography} from "@mui/material";
import RMAHeaderView from "../components/RMAHeaderView";
import ContactView from "../components/ContactView";
import ShipmentAddressView from "../components/ShipmentAddressView";
import RMAStatusCard from "../components/RMAStatusCard";
import TableWrapperCard from "../components/TableWrapperCard";
import {Trans, useTranslation} from "react-i18next";
import {MdFileCopy, MdList} from "react-icons/md";
import WarrantyClaimLinesView from "../components/WarrantyClaimLinesView";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadRmaDetails} from "../model/rmaDetails";
import CardView from "../components/CardView";
import {loadCaseDetails} from "../model/caseDetails";
import {sanitise} from "../services/helper";
import InstallerNotes from "../components/InstallerNotes";
import {selectSite} from "../model/site";

export default function RMADetailsPage() {
    const {id} = useParams();
    const {t} = useTranslation();
    const {loading, data: rmaDetails, error} = useSelector(state => state.rmaDetails);
    const {loading: caseLoading, data: caseDetails, error: caseError} = useSelector(state => state.caseDetails);
    const dispatch = useDispatch();
    useEffect(() => {
        if(id){
            dispatch(loadRmaDetails({id}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    useEffect(() => {
        if(rmaDetails?.rma_case?.case_number){
            dispatch(loadCaseDetails({caseNumber: rmaDetails?.rma_case?.case_number}));
        }
        if(rmaDetails?.rma_case?.site_id){
            dispatch(selectSite({site: {id: rmaDetails.rma_case.site_id, name: rmaDetails.rma_case.site_name}}));
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[rmaDetails?.rma_case?.case_number]);
    const navigate = useNavigate();
    if (loading) {
        return <LinearProgress variant="indeterminate"/>;
    } else if (error) {
        return <Box maxWidth="600px" marginX="auto" mt={20}>
            <Alert severity="error" action={<Button onClick={() => navigate('/')}><Trans>Back to home</Trans></Button>}>
                {error}
            </Alert>
        </Box>;
    }
    const {case_number} = rmaDetails?.rma_case || {case_number: ''};
    const {delivery_address, return_address} = rmaDetails;

    return <div>
        <BreadCrumbView current={`Case ${case_number}`}/>
        <Paper sx={{p: 2}}>
            <Box mb={2}><RMAHeaderView rmaDetails={rmaDetails} caseDetails={caseDetails}/></Box>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <ContactView contactDetails={rmaDetails.contact}/>
                </Grid>
                <Grid item md={4} xs={12}>
                    <RMAStatusCard status={rmaDetails.rma_customer_status}/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <CardView title={t('Description')} headerIcon={<MdFileCopy/>}>
                        <Box pt={2} data-testid="description">
                            {caseLoading&& <CircularProgress variant="indeterminate"/>}
                            {caseError && <Alert severity="error" color="error">{caseError}</Alert>}
                            {sanitise(caseDetails?.description)?.split(/\n/g).map((s, i) => <Typography key={i} variant="body2">{s}</Typography>)}
                        </Box>
                    </CardView>
                    <Box my={2}><ShipmentAddressView title={t("Shipping Address")}
                                                     address={delivery_address}
                    /></Box>
                    <Box my={2}><ShipmentAddressView title={t("Return Address")} isReturn={true}
                                                     address={return_address}/></Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InstallerNotes caseLoading={caseLoading} caseError={caseError} caseDetails={caseDetails}/>
                </Grid>
            </Grid>

            <Box mt={2}>
                <TableWrapperCard title={t("Warranty Claim Lines")} icon={<MdList/>}>
                    <WarrantyClaimLinesView warrantyClaimLines={rmaDetails.warranty_claim_lines}/>
                </TableWrapperCard>
            </Box>
        </Paper>
    </div>
}