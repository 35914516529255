import getConfig from "./env";
import api from "./api";

class SystemsService{
    getSystems = (systemId) => new Promise((resolve,reject) => {
        const url = `${getConfig().service_manager_url}/api/internal/installer/systems/${systemId}`;
        api.get(url)
            .then(({data})=> resolve(data))
            .catch(error => reject(error));
    }) ;
    getDevicesForSystemId = (systemId) => new Promise(((resolve, reject) => {
        const url = `${getConfig().service_manager_url}/api/internal/installer/systems/${systemId}/devices`;
        api.get(url)
            .then(({data})=> resolve(data))
            .catch(error => reject(error));
    }));
}
const systemsService = new SystemsService();
export default systemsService;