import getConfig from "../services/env";
import {removeKey} from "../services/localStorage";
import {useEffect} from "react";

export default function useRegisterLogout() {
    useEffect(() => {
        function listener(event) {
            console.log('inside listener', event);
            if (event.origin === getConfig().service_manager_url
                && event.data === 'logout') {
                removeKey('selectedSite');
                removeKey('startDate');
                removeKey('endDate');
            }
        }

        window.addEventListener("message", listener);
        return () => window.removeEventListener('message', listener);
    }, []);
}