import {Button} from "@mui/material";
import {Trans} from "react-i18next";
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import labourReimbursementService from "../services/labourReimbursementService";
import NotEligibleModal from "./LabourReimbursementForm/NotEligibleModal";
import CannotTrackDialog from "./LabourReimbursementForm/CannotTrackDialog";

export default function LabourReimbursementLink(){
    const {data: rmaDetails} = useSelector(state => state.rmaDetails);
    const [eligibility, setEligibility] = useState(false);
    const [feature, setFeature] = useState(false);
    const location = useLocation();
    useEffect(() =>{
        if(rmaDetails?.rma_number) {
            Promise.all([labourReimbursementService.checkEligibility(rmaDetails.rma_number),
                labourReimbursementService.checkLabourReimbursementEnabled()
            ]).then(([data, feature]) => {
                setEligibility(data.data);
                setFeature(feature.enabled);
            });
        }
    },[location.pathname, rmaDetails.rma_number]);
    if(!(rmaDetails.rma_number && location.pathname === `/rma-details/${rmaDetails.rma_number}`)){
        return null;
    }
    if(!feature){
        return null;
    }
    if(eligibility.eligible && eligibility.labour_reimbursement_id){
        let firstMay2022 = new Date(2022,4,1);
        const isBeforeFirstMay2022 = eligibility.reimbursement_created_date && eligibility.reimbursement_created_date < firstMay2022.getTime();
        if(isBeforeFirstMay2022){
            return <CannotTrackDialog/>;
        }
        return <Button role="button" disabled={isBeforeFirstMay2022} variant="outlined" LinkComponent={Link} to={`/rma-details/${rmaDetails.rma_number}/labour-reimbursement`}>
            <Trans><Trans>Track Labor Reimbursement</Trans></Trans>
        </Button>
    } else if(eligibility.eligible && !eligibility.labour_reimbursement_id) {
        return <Button role="button" variant="outlined" LinkComponent={Link}
                       to={`/rma-details/${rmaDetails.rma_number}/labour-reimbursement-form`}>
            <Trans><Trans>Apply for Labor Reimbursement</Trans></Trans>
        </Button>;
    }else if(eligibility && !eligibility.eligible){
        return <NotEligibleModal/>;
    }
    return null;

}