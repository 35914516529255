import Box from "@mui/material/Box";
import {AiFillCaretDown, AiFillCaretUp} from "react-icons/ai";

export function Sort({direction, column, setSortBy}) {
    const isSelectedAsc = direction['sort_by_' + column] === 'asc';
    const isSelectedDesc = direction['sort_by_' + column] === 'desc';
    return <Box sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
        <AiFillCaretUp fontSize={12} color={isSelectedAsc ? '#f37320' : '#bfbfbf'} data-testid='asc'
                       onClick={() => isSelectedAsc ? setSortBy({}) : setSortBy({['sort_by_' + column]: 'asc'})}/>
        <AiFillCaretDown fontSize={12} color={isSelectedDesc ? '#f37320' : '#bfbfbf'} data-testid='desc'
                         onClick={() => isSelectedDesc ? setSortBy({}) : setSortBy({['sort_by_' + column]: 'desc'})}/>
    </Box>
}
