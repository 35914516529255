import CardView from "./CardView";
import {Box, Typography, useMediaQuery} from "@mui/material";
import {MdPerson, MdEmail, MdPhone} from "react-icons/md";
import {Trans, useTranslation} from "react-i18next";
import {useTheme} from "@mui/styles";
import {sanitise} from "../services/helper";

export default function ContactView({contactDetails = {}}) {
    const theme = useTheme();
    const {t} = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return <CardView title={t('Account/Contact')} headerIcon={<MdPerson/>}>
        <Box display="flex" justifyContent="space-between" flexDirection={isMobile ? 'column' : 'row'}>
            <Box p={2} minWidth={200}>
                <Label><Trans>Account Name</Trans></Label>
                <Typography variant="body2">{sanitise(contactDetails.company_name)}</Typography>
            </Box>
            <Box p={2} minWidth={200}>
                <Label><Trans>Contact</Trans></Label>
                <Box display="flex" alignItems="center" mb={1}>
                    <MdEmail/>
                    <Typography variant="body2" sx={{ml: 2}}>{sanitise(contactDetails.email)}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <MdPhone/>
                    <Typography variant="body2" sx={{ml: 2}}>{sanitise(contactDetails.phone)}</Typography>
                </Box>
            </Box>
            <Box p={2} minWidth={200}>
                <Label><Trans>Account Owner</Trans></Label>
                <Typography variant="body2">{sanitise(contactDetails.first_name)} {sanitise(contactDetails.last_name)}</Typography>
            </Box>
        </Box>
    </CardView>
}

function Label({children}) {
    return <Typography variant="subtitle2" sx={{fontWeight: 700}}>{children}</Typography>
}