import CardView from "../CardView";
import {Trans, useTranslation} from "react-i18next";
import {Button, Card, CardActions, CardContent, Grid, Typography} from "@mui/material";
import {MdArrowDownward} from "react-icons/md";
import UploadFile from "../UploadFile";

export default function DocumentsUploadSection({
                                                   requiredDocuments, files,
                                                   setFiles,
                                                   country,
                                                   errors,
                                                   taxForm,
                                                   registeredAddressHasCompany,
                                                   changeRegisteredAddress,
                                                   newMailingAddress,
                                                   prefillDocument
                                               }) {
    const {t} = useTranslation();

    const instructionsMap = {
        US_update_html: <Grid key={'US_update_html'} item xs={12} md={6}><DocumentDownload
            url={'http://www.irs.gov/pub/irs-pdf/fw9.pdf'}
            text={<Trans>US_update_html</Trans>}/></Grid>,
        US_html: <Grid key={'US_html'} item xs={12} md={6}><DocumentDownload
            url={'http://www.irs.gov/pub/irs-pdf/fw9.pdf'}
            text={<Trans>US_html</Trans>}/></Grid>,
        CA_update_html: <Grid key={'CA_update_html'} item xs={12} md={6}><DocumentDownload
            url={'https://www.irs.gov/pub/irs-pdf/fw8bene.pdf'}
            text={<Trans>CA_update_html</Trans>}/></Grid>,
        CA_html: <Grid key={'CA_html'} item xs={12} md={6}><DocumentDownload
            url={'https://www.irs.gov/pub/irs-pdf/fw8bene.pdf'}
            text={<Trans>CA_html</Trans>}/></Grid>,
        other_html: <Grid key={'other_html'} item xs={12} md={6}><DocumentDownload onDownload={prefillDocument} text={
            <Trans>other_html</Trans>}/></Grid>,
        other_update_html: <Grid key={'other_update_html'} item xs={12} md={6}><DocumentDownload
            onDownload={prefillDocument} text={<Trans>other_update_html</Trans>}/></Grid>,
        text1: <Grid key={'text1'} item xs={12} md={6}><DocumentDownload onDownload={prefillDocument}
                                                                         text={<Trans>text1</Trans>}/></Grid>,
        text2: <Grid key={'text2'} item xs={12} md={6}><DocumentDownload onDownload={prefillDocument}
                                                                         text={<Trans>text2</Trans>}/></Grid>,
        text3: <Grid key={'text3'} item xs={12} md={6}><DocumentDownload onDownload={prefillDocument}
                                                                         text={<Trans>text3</Trans>}/></Grid>,
        text4: <Grid key={'text4'} item xs={12} md={6}><DocumentDownload onDownload={prefillDocument}
                                                                         text={<Trans>text4</Trans>}/></Grid>,
        text5: <Grid key={'text5'} item xs={12} md={6}><DocumentDownload onDownload={prefillDocument}
                                                                         text={<Trans>text5</Trans>}/></Grid>,
    }

    function getInstructions() {
        const instructions = []
        if (['US', 'VI', 'PR'].includes(country)) {
            instructions.push(taxForm ? instructionsMap.US_update_html : instructionsMap.US_html);
        } else if (['CA'].includes(country)) {
            instructions.push(taxForm ? instructionsMap.CA_update_html : instructionsMap.CA_html)
        }
        if (taxForm && registeredAddressHasCompany) {
            instructions.push(instructionsMap.text1)
        } else if (taxForm === false) {
            instructions.push(instructionsMap.text2)
        } else if (taxForm && changeRegisteredAddress && !newMailingAddress) {
            instructions.push(instructionsMap.text3)
        } else if (taxForm && newMailingAddress && !changeRegisteredAddress) {
            instructions.push(instructionsMap.text4)
        } else if (taxForm && changeRegisteredAddress && newMailingAddress) {
            instructions.push(instructionsMap.text5)
        } else if (taxForm) {
            instructions.push(instructionsMap.other_update_html)
        } else {
            instructions.push(instructionsMap.text2)
        }
        return instructions;
    }


    return <CardView title={t('Documents')}>
        <Grid container spacing={2} sx={{alignItems: 'stretch'}}>
            {getInstructions()}
            <Grid item xs={12}>
                <Typography><Trans>Must be a PDF file under 2 MB in size. Please be sure that details provided as part
                    of
                    this request must match with the form.</Trans></Typography>
            </Grid>
            {requiredDocuments.includes('pan') && <Grid item xs={12} md={6}>
                <UploadFile error={errors['pan']} required onChange={file => setFiles({...files, pan: file})} name="pan"
                            id="pan" text={t('pan')}/>
            </Grid>}
            {requiredDocuments.includes('gst') && <Grid item xs={12} md={6}>
                <UploadFile error={errors['gst']} required onChange={file => setFiles({...files, gst: file})} name="gst"
                            id="gst" text={t('gst')}/>
            </Grid>}
            {requiredDocuments.includes('cancelled_check') && <Grid item xs={12} md={6}>
                <UploadFile error={errors['cancelled_check']} required
                            onChange={file => setFiles({...files, cancelled_check: file})} name="cheque" id="cheque"
                            text={t('cancelled_check')}/>
            </Grid>}
            {requiredDocuments.includes('w_9') && <Grid item xs={12} md={6}>
                <UploadFile error={errors['w_9']} required onChange={file => setFiles({...files, w_9: file})} name="w_9"
                            id="w_9" text={t('w_9')}/>
            </Grid>}
            {requiredDocuments.includes('w8_ben') && <Grid item xs={12} md={6}>
                <UploadFile error={errors['w8_ben']} required onChange={file => setFiles({...files, w8_ben: file})}
                            name="w8_ben" id="w8_ben" text={t('w8_ben')}/>
            </Grid>}
            {requiredDocuments.includes('reimbursement_details') && <Grid item xs={12} md={6}>
                <UploadFile error={errors['reimbursement_details']}
                            onChange={file => setFiles({...files, reimbursement_details: file})} required
                            name="reimbursementDetails"
                            id="reimbursementDetails" text={t('reimbursement_details')}/>
            </Grid>}
        </Grid>

    </CardView>;
}

function DocumentDownload({text, onDownload, url}) {
    return <Card variant="outlined"
                 sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <CardContent>
            <Typography>{text}</Typography>
        </CardContent>
        <CardActions>
            {url ?
                <Button target="_blank" rel="noopener noreferrer" href={url} variant="contained" startIcon={<MdArrowDownward/>}><Trans>Download</Trans></Button> :
                <Button onClick={onDownload} variant="contained"
                        startIcon={<MdArrowDownward/>}><Trans>Download</Trans></Button>}
        </CardActions>
    </Card>
}