import {columns} from "./columns";
import {Trans, useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import sfdcService from "../../services/sfdcService";
import {mapRMAResponse} from "../../services/helper";
import useSubmittedDateRange, {getEndOfDay, getStartOfDay} from "../useSubmittedDateRange";
import {Alert, Button, ButtonBase, Stack, Switch, Typography} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import MaterialTableWithSearch from "../MaterialTableWithSearch";
import {logGAEvent} from "../../analytics/ga4";

export default function RMATableView() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [error, setError] = useState('');
    const [showActiveOnly, setShowActiveOnly] = useState(false);
    const {selected: selectedRmaStatus} = useSelector(state => state.rmaStatus);
    const {downloadInProgress} = useSelector(state => state.rmaDetails);
    const clearAll = useRef(null);
    const [search, setSearch] = useState(() => selectedRmaStatus.length > 0 ? ({
        page: 1,
        per_page: 10,
        rmaStatus: selectedRmaStatus.map(s => t(s))
    }) : ({
        page: 1,
        per_page: 10
    }));
    const {dateRange} = useSubmittedDateRange();
    const {site: selectedSite} = useSelector(state => state.site);
    const navigate = useNavigate();
    const forwardToRmaDetails = (rmaNumber) => {
        navigate(`rma-details/${rmaNumber}`);
    }

    const fetchRecords = (searchParams) => {
        setError('');
        setLoading(true);
        sfdcService.getRMAsForCompanyId(searchParams, showActiveOnly, getStartOfDay(dateRange.fromDate).toISOString(),
            getEndOfDay(dateRange.toDate).toISOString())
            .then(response => {
                    setData(mapRMAResponse(response));
                    setLoading(false);
            })
            .catch(error => {
                if (error.message !== 'canceled') {
                    setLoading(false);
                    setError(t('Error occurred while loading records!'))
                }
            });
    };

    function applySiteAndStatusFilters(currentPayload) {
        const payload = {...currentPayload};

        if (selectedSite.id && +selectedSite.id > 0) {
            payload.systemId = selectedSite.id;
        }
        if (selectedRmaStatus.length > 0) {
            payload.rmaStatus = selectedRmaStatus.map(s => t(s, {lng: 'en'}))
        }
        return payload;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchRecords(applySiteAndStatusFilters(search));
        }, 1000)
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.toDate, dateRange.fromDate, showActiveOnly, search, selectedSite.id, selectedRmaStatus]);
    useEffect(() => {
        if(clearAll.current){
            clearAll.current();
        }
    }, [dateRange.toDate, dateRange.fromDate, selectedSite.id, selectedRmaStatus]);

    const columnsShown = columns.map(d => d.key);
    const listProps = {
        tableHeader: '',
        loading: isLoading,
        tableLayout: 'fixed',
        showSerialNumber: false,
        rowKey: 'id',
        name: 'RMA_TABLE',
        columns: columns.map(column => ({...column, name: t(column.name)})),
        showHeader: true,
        columnsShown: columnsShown,
        allColumns: columns.map(d => d.key),
        onChange: (d) => {
            setSearch(d);
        },
        hideGlobalSearch: true,
        hideDatePicker: true,
        defaultPageSize: 10,
        defaultView: 'table',
        data: data,
        downloadButton: {
            onDownload: () => dispatch({
                type: 'rmaDetails/downloadcsv', payload: {
                    search: applySiteAndStatusFilters(search),
                    showActiveOnly,
                    fromDate: dateRange.fromDate.toISOString(),
                    toDate: dateRange.toDate.toISOString()
                }
            }),
            disabled: downloadInProgress,
            loading: downloadInProgress
        },
        locale: {
            Apply: t('Apply'),
            Reset: t('Reset'),
            Search: t('Search'),
            Total: t('Total'),
            No_Data: t('There are no rma that match your criteria.'),
            Records: t('Records'),
            Clear_all_filters: t('Clear_all_filters'),
            Download: t('Download'),
            Reset_table_layout: t('Reset_table_layout'),
            Show_Hide_columns: t('Show_Hide_columns'),
            Toggle_All: t('Toggle_All'),
            Type: t('Type'),
            Column_Name: t('Column_Name'),
            items_per_page: t('items_per_page')
        },
        tableDataKey: 'records',
        optionalTools:
            data && data.total === 0 ? (
                <div><Trans>There are no rma that match your criteria.</Trans></div>
            ) : null
    }
    listProps.columnExtraProps = {
        rmaNumber: {
            render: (value, record) => {
                return (
                    <Button size="small" onClick={() => forwardToRmaDetails(record.rmaNumber)}>
                        {record.rmaNumber}
                    </Button>
                );
            }
        },
        caseNumber: {
            name: 'Case Number',
            key: 'caseNumber',
            advanceSearch: true
        },
        rmaStatus: {
            filters: selectedRmaStatus.length > 0 ? null : columns[2].filters
        }
    }

    return <>
        <Stack direction="row" sx={{mb: 2}} spacing={1} alignItems="center">
            <ButtonBase onClick={() => setShowActiveOnly(false)}>
                <Typography variant="caption"><Trans>All RMAs</Trans></Typography>
            </ButtonBase>
            <Switch size="small" checked={showActiveOnly}
                    disabled={isLoading}
                    onChange={(_, checked) => setShowActiveOnly(checked)}
            />
            <ButtonBase onClick={() => setShowActiveOnly(true)}>
                <Typography variant="caption"><Trans>Active RMAs</Trans></Typography>
            </ButtonBase>
        </Stack>
        { isLoading && <span style={{display: "none"}}><Trans>Loading</Trans></span>}
        {error && <Alert severity="error">{error}</Alert>}
        <MaterialTableWithSearch
            onChange={(d) => setSearch(d)}
            total={data?.total || 0}
            isLoading={isLoading}
            columns={columns}
            disabledFilters={selectedRmaStatus.length > 0 ? ['rmaStatus'] : []}
            download={{
                onDownload: () => {
                    logGAEvent({
                        action: 'download_csv',
                        category: 'homepage',
                    });
                    return dispatch({
                        type: 'rmaDetails/downloadcsv', payload: {
                            search: applySiteAndStatusFilters(search),
                            showActiveOnly,
                            fromDate: dateRange.fromDate.toISOString(),
                            toDate: dateRange.toDate.toISOString()
                        }
                    })
                },
                downloadInProgress
            }}
            locale={listProps.locale}
            clearAllFiltersHandle={(clear) => clearAll.current = clear}
            columnExtraProps={listProps.columnExtraProps}
            records={data?.records || []}/>
    </>;
}