/* istanbul ignore file */
import {Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import RMADetailsPage from "./pages/RMADetailsPage";
import Layout from "./components/Layout";
import {DateRangeProvider} from "./components/useSubmittedDateRange";
import LabourReimbursementFormPage from "./pages/LabourReimbursementFormPage";
import TrackLabourReimbursement from "./pages/TrackLabourReimbursement";

export default function AppRoutes() {
    return <Routes>
        <Route path="/" element={<Layout/>}>
            <Route index element={<DateRangeProvider><HomePage/></DateRangeProvider>}/>
            <Route path="rma-details/:rmaNumber/labour-reimbursement" element={<TrackLabourReimbursement/>}/>
            <Route path="rma-details/:id/labour-reimbursement-form" element={<LabourReimbursementFormPage/>}/>
            <Route path="rma-details/:id" element={<RMADetailsPage/>}/>
        </Route>
    </Routes>;
}