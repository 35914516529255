
function validate(state, errors, t){
    if(!state.installer_contact_email){
        errors.installer_contact_email = t("Email is mandatory");
    }
    if(!state.installer_contact_name){
        errors.installer_contact_name = t("Contact Name is mandatory");
    }
    if(!state.installer_contact_phone){
        errors.installer_contact_phone = t("Phone is mandatory");
    }
    if(state.changeRegisteredAddress){
        const address = {};
        if(!state.address.address1){
            address.address1 = t("Street Address is mandatory");
        }
        if(!state.address.state){
            address.state = t("State/Province is mandatory");
        }
        if(!state.address.zip){
            address.zip = t("Zip/Postal Code is mandatory");
        }
        if(!state.address.city){
            address.city = t("City is mandatory");
        }
        if(!state.address.country){
            address.country = t("Country is mandatory");
        }
        if(JSON.stringify(address) !== '{}'){
            errors.address = address;
        }
    }
    if(state.cheque_payment){
        const mailing_address = {};
        if(!state.newMailingAddress && !state.mailing_address.city){
            mailing_address.error = t("Mailing address is mandatory");
        }
        if(state.newMailingAddress){
            if(!state.mailing_address.address1){
                mailing_address.address1 = t("Street Address is mandatory");
            }
            if(!state.mailing_address.state){
                mailing_address.state = t("State/Province is mandatory");
            }
            if(!state.mailing_address.zip){
                mailing_address.zip = t("Zip/Postal Code is mandatory");
            }
            if(!state.mailing_address.city){
                mailing_address.city = t("City is mandatory");
            }
            if(!state.mailing_address.country){
                mailing_address.country = t("Country is mandatory");
            }
        }
        if(JSON.stringify(mailing_address) !== '{}'){
            errors.mailing_address = mailing_address;
        }
    }
    return errors;
}
function validateFiles(requiredFiles, files, t){
    const errors = {};
    requiredFiles.forEach(fileName => {
        if(!files[fileName] || files[fileName].length === 0){
            errors[fileName] = t('file is mandatory', {file: fileName});
        }else if ((files[fileName].size / (1024*1024)) > 2){
            errors[fileName] = t('Must be a PDF file under 2 MB in size');
        }
    });
    return errors;
}

export function validateForm(requiredFiles, files, state, t){
    const errors = validateFiles(requiredFiles, files, t);
    validate(state, errors, t);
    return {type: 'VALIDATE', payload: {errors}};
}