import StatusChart from "./StatusChart";
import StatusCard from "./StatusCard";
import {config} from "./chartsConfig";
import {Box, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toggleSelectedStatus} from "../../model/rmaStatus";
import {logGAEvent} from "../../analytics/ga4";


export default function RMAStatus() {
    const theme = useTheme();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {data: status, selected} = useSelector(state => state.rmaStatus);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const handleClick = (status) =>{
        dispatch(toggleSelectedStatus({status}));
        logGAEvent({
            action: 'filter_by_status_card',
            category: 'homepage',
            label: 'homepage',
            value: status
        })
    }
    return <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}
                justifyContent="start"
                alignItems="center" sx={{flexWrap: 'wrap', paddingBottom: 2}}>
        <StatusChart status={status}/>
        <Box display="flex" flex={1} flexDirection={isMobile ? 'column' : 'row'}
             justifyContent="start"
             alignItems="stretch" sx={{flexWrap: 'wrap', paddingBottom: 2}}>
        {Object.entries(status)
            .map(([k, v]) => <StatusCard text={t(k)} icon={config[k].icon}
                                                color={config[k].color}
                                                isSelected={selected.includes(k)}
                                                onSelect={() => handleClick(k)}
                                                number={v} key={k}/>)}
        </Box>
    </Box>

}