import CardView from "../CardView";
import {Trans, useTranslation} from "react-i18next";
import {Checkbox, FormControlLabel, Grid, Radio, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import AddressComponent from "./AddressComponent";
import {replaceMailingAddress, setNewMailingAddress, updateCheckPayment, updateMailingAddress} from "./formReducer";

export default function PaymentDetails({mailingAddresses, dispatch, payByCheck, newAddress, errors}){
    const {t} = useTranslation();
    const [selectedAddress, setSelectedAddress] = useState();
    useEffect(() => {
        if(payByCheck){
            handleSetNewAddress('new');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[payByCheck]);

    function handleSetNewAddress(value){
        setSelectedAddress(value);
        dispatch(setNewMailingAddress(value==='new'))
    }
    return <CardView title={t('Payment Details')}>
        <FormControlLabel
            control={<Checkbox/>}
            value={payByCheck}
            onChange={() => dispatch(updateCheckPayment(!payByCheck))}
            label={t('Do you want your payments happen through check?')}/>
        {payByCheck && <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography fontWeight={700} variant="subtitle1"><Trans>Mail Cheque To:</Trans></Typography>
            </Grid>
            {mailingAddresses?.map(address => <Grid item xs={12} md={6} key={address.id}>
                <FormControlLabel control={<Radio
                    checked={selectedAddress === address.id}
                    onChange={(e)=> {
                        handleSetNewAddress(address.id);
                        dispatch(replaceMailingAddress(address));
                    }}
                    value={address.id}
                    name="mailing-addresses"
                />} label={<span>{address?.address1} {address?.address2}<br/>{ address?.city} {address?.state} {address?.zip} {address?.country}</span>}/>
            </Grid>)}
            <Grid item xs={12}>
                <FormControlLabel control={<Radio
                    checked={selectedAddress === 'new'}
                    onChange={(e)=> {
                        handleSetNewAddress(e.target.value);
                        dispatch(replaceMailingAddress({ country: 'US'}));
                    }}
                    value={'new'}
                    name="mailing-addresses"
                />} label={t('New Address')}/>
                {selectedAddress === 'new' && <AddressComponent errors={errors} handleChange={(name, value) => dispatch(updateMailingAddress(name, value))} address={newAddress}/>}
            </Grid>
        </Grid> }
    </CardView>
}