import {useState} from "react";
import {
    Checkbox,
    FormControlLabel, IconButton,
    InputAdornment,
    List,
    ListItem,
    ListSubheader,
    Popover,
    TextField, Tooltip
} from "@mui/material";
import {Search} from "@enphase/designsystems/Icons";
import {AiOutlineSetting} from "react-icons/ai";

export function SelectColumns({ columns, setVisibleColumns, visibleColumns, locale}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState('');

    const handleClose = () => setAnchorEl(null)
    function addToVisibleColumns(column) {
        const result = [...visibleColumns.map(vc => vc.key), column.key];
        setVisibleColumns(columns.filter(c => result.includes(c.key)));
    }

    return <><Tooltip title={locale.Show_Hide_columns}>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}
                    size="small"
                    sx={{border: '1px solid #ddd'}}>
            <AiOutlineSetting/>
        </IconButton>
    </Tooltip>
        <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
    >
        <List dense={true}>
            <ListSubheader>
                <TextField size="small"
                           label={" "}
                           InputLabelProps={{shrink: false}}
                           value={search}
                           onChange={e => setSearch(e.target.value)}
                           InputProps={{
                               startAdornment: <InputAdornment position={'start'}>
                                   <Search/>
                               </InputAdornment>
                           }}/>
            </ListSubheader>
            {columns
                .filter(column => column.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
                .map(column => <ListItem key={column.key}>
                    <FormControlLabel sx={{padding: 0}}
                                      control={<Checkbox checked={visibleColumns && visibleColumns.length > 0 && visibleColumns.map(c => c.key).includes(column.key)}
                                                         onChange={e => !e.target.checked ? setVisibleColumns(visibleColumns.filter(c => c.key !== column.key)) :
                                                             addToVisibleColumns(column)}
                                                         size="small"/>}
                                      label={column.name}/>
                </ListItem>)}
        </List>
    </Popover></>
}
