import {Accordion, AccordionDetails, AccordionSummary, Card, Divider, Grid, Link, Typography} from "@mui/material";
import {MdExpandMore} from "react-icons/md";
import Box from "@mui/material/Box";
import {Trans} from "react-i18next";
import {useEffect, useState} from "react";
import returnDispositionService from "../../services/returnDispositionService";

const RecycleSection = () => {
    const [usResources, setUSResources] = useState([]);
    const [caResources, setCAResources] = useState([]);
    useEffect(() => {
        Promise.all([returnDispositionService.getRecycleResources('US'),
            returnDispositionService.getRecycleResources('CA')])
            .then(([us, ca]) => {
                setUSResources(us);
                setCAResources(ca);
            })
    }, []);
    return (<Box mb={2}>
            <Card><Accordion defaultExpanded>
                <AccordionSummary sx={{backgroundColor: '#f7f7f7'}}
                                  expandIcon={<MdExpandMore size={30}/>}>
                    <Box>
                        <Typography variant="h6"><Trans>Approved to Recycle</Trans></Typography>
                        <Typography variant="caption"><Trans>You are approved to recycle the devices covered under this warranty claim. Enphase authorizes you to dispose of those devices according to the rules in your local jurisdiction to reduce the environmental impact of sending products back to us in Northern California.</Trans></Typography>
                    </Box>
                    <Divider/>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="h6"><Trans>Below are links to various recycling programs if you need assistance finding a facility near you.</Trans></Typography>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <h4>United States</h4>
                            <ul>
                                {usResources.map(us => <li key={us.state_or_province}>
                                    <Link target="_blank"
                                          rel="noopener noreferrer"
                                          href={us.link}>{us.state_or_province}</Link>
                                </li>)}
                            </ul>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <h4>Canada</h4>
                            <ul>
                                {caResources.map(ca => <li key={ca.state_or_province}>
                                    <Link target="_blank"
                                          rel="noopener noreferrer"
                                          href={ca.link}>{ca.state_or_province}</Link>
                                </li>)}
                            </ul>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            </Card></Box>
    );
};

export default RecycleSection;