import api from "./api";
import getConfig from "./env";

class ReturnDispositionService {
    isEligible = (rmaNumber) => new Promise((resolve, reject) => {
       const url = `${getConfig().rd_url}/api/rma/${rmaNumber}/is_eligible_for_return_label`;
       api.get(url)
           .then(({data}) => resolve(data))
           .catch(error => reject(error));
    });
    generateLabel = async (rmaNumber, name) => {
        const url = `${getConfig().rd_url}/api/rma/${rmaNumber}/generate_label`;
        const response = await api.get(url);
        const node = document.createElement('a');
        let objectUrl = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}));
        node.setAttribute('href', objectUrl);
        node.setAttribute('download', name);
        document.body.appendChild(node);
        node.style = "display: none";
        node.click();
        window.URL.revokeObjectURL(objectUrl);
        node.remove();
    };
    loadShippingDetails = (rmaNumber) => new Promise((resolve, reject) => {
        const url = `${getConfig().rd_url}/api/rma/${rmaNumber}/prerequisite_data`;
        api.get(url)
            .then(({data}) => resolve(data))
            .catch(error => reject(error));
    });
    submitStorageDetails = (rmaNumber, payload) =>new Promise((resolve, reject)=>{
       const url =`${getConfig().rd_url}/api/rma/${rmaNumber}/storage_return_process`;
        api.post(url, payload)
            .then(({data}) => resolve(data))
            .catch(error => reject(error));
    });
    getRecycleResources = (country) => new Promise((resolve, reject) => {
       const url = `${getConfig().rd_url}/api/recycle_resources?country=${country}`;
        api.get(url)
            .then(({data}) => resolve(data))
            .catch(error => reject(error));
    });
}
export default new ReturnDispositionService();