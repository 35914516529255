import api from './api';
import getConfig from "./env";
class TopicService{
    getTopics = ()=> new Promise((resolve, reject)=> {
        const url = `${getConfig().service_manager_url}/api/internal/installer/case_topics`;
        api.get(url)
            .then(({data}) => resolve(data))
            .catch(error => reject(error));
    });
}
const topicService = new TopicService();
export default topicService;