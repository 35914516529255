import getConfig from "./env";
import api from "./api";

class LabourReimbursementService{
    checkLabourReimbursementEnabled = ()=> new Promise((resolve, reject)=>{
        const url = `${getConfig().labour_reimbursement_url}/api/v1/features/LABOUR_REIMBURSEMENT_ELIGIBILITY/enabled`;
        return api.get(url)
            .then(({data})=> resolve(data))
            .catch(error => reject(error));
    });
    checkEligibility = (rmaNumber) => new Promise((resolve, reject) => {
        const url = `${getConfig().labour_reimbursement_url}/api/v1/rma/${rmaNumber}/labourReimbursementEligibility`;
        api.get(url)
            .then(({data})=> resolve(data))
            .catch(error => reject(error));
    });
    loadPrerequisites = (rmaNumber) => new Promise((resolve, reject) => {
        const url = `${getConfig().labour_reimbursement_url}/api/v1/rma/${rmaNumber}/labourReimbursement/prerequisites`;
        api.get(url)
            .then(({data})=> resolve(data))
            .catch(error => reject(error));
    });

    submitReimbursement = (rmaNumber,payload, files) => new Promise((resolve, reject) => {
        const url = `${getConfig().labour_reimbursement_url}/api/v1/rma/${rmaNumber}/labourReimbursement`;
        const formData = new FormData();
        Object.keys(files).forEach(fileName => {
            formData.append(fileName, files[fileName]);
        });
        const json = JSON.stringify(payload);
        const blob = new Blob([json], {
            type: 'application/json'
        });
        formData.append('details',blob);
        api.post(url, formData)
            .then(({data})=> resolve(data))
            .catch(error => reject(error));
    });

    loadReimbursement = (rmaNumber, reimbursementId) => new Promise((resolve, reject) => {
        const url = `${getConfig().labour_reimbursement_url}/api/v1/rma/${rmaNumber}/labourReimbursement/${reimbursementId}`;
        api.get(url)
            .then(({data}) => resolve(data))
            .catch(error => reject(error));
    });
    downloadDocument = (rmaNumber, reimbursementId,documentName) => new Promise(((resolve, reject) => {
        const url = `${getConfig().labour_reimbursement_url}/api/v1/rma/${rmaNumber}/labourReimbursement/${reimbursementId}/downloadDocument/${documentName}.pdf`;
        api.get(url,{responseType: 'blob'})
            .then((response) => {
                if(response.status === 204){
                    throw new Error('No document available');
                }
                let objectUrl = window.URL.createObjectURL(response.data);
                let anchor = document.createElement("a");
                document.body.appendChild(anchor);
                anchor.href = objectUrl;
                anchor.download = `${documentName}.pdf`;
                anchor.click();
                window.URL.revokeObjectURL(objectUrl);
                anchor.remove();
                resolve(true);
            }).catch(error => reject(error));
    }));

    prefillDocumentDownload = (rmaNumber, payload) =>new Promise((resolve, reject) => {
        const url = `${getConfig().labour_reimbursement_url}/api/v1/rma/${rmaNumber}/labourReimbursement/downloadPrefilledDocument`;
        api.post(url,payload,{responseType: 'blob'})
            .then((response) => {
                if(response.status === 204){
                    throw new Error('No document available');
                }
                let objectUrl = window.URL.createObjectURL(response.data);
                let anchor = document.createElement("a");
                document.body.appendChild(anchor);
                anchor.href = objectUrl;
                anchor.download = `document.pdf`;
                anchor.click();
                window.URL.revokeObjectURL(objectUrl);
                anchor.remove();
                resolve(true);
            }).catch(error => reject(error));
    });
}

export default new LabourReimbursementService();