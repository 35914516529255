import {createSlice} from '@reduxjs/toolkit';
import {call, put, takeEvery} from 'redux-saga/effects';
import sfdcService from "../services/sfdcService";

export const rmaDetailsSlice = createSlice({
    name: 'rmaDetails',
    initialState: {
        loading: false,
        data: [],
        error: '',
        downloadInProgress: false
    },
    reducers: {
        loadRmaDetails: (state) => {
            state.loading = true;
            state.error = '';
        },
        loadRmaDetailsSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        loadRmaDetailsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },
        downloadcsv: (state) => {
            state.downloadInProgress = true;
        },
        downloadCsvDone: (state) => {
            state.downloadInProgress = false;
        }
    },
});
function* workerFetchRmaDetails({payload: {id}}){
    try {
        const data = yield call(() => sfdcService.getRMALineItems(id));
        yield put(rmaDetailsSlice.actions.loadRmaDetailsSuccess({data}));
    }catch (error){
        yield put(rmaDetailsSlice.actions.loadRmaDetailsFailure({error: 'Error occurred while loading RMA!'}));
    }
}

function* workerDownloadCsv({payload: {search, showActiveOnly, fromDate, toDate}}){
    yield call(() => sfdcService.downloadCsv(search,showActiveOnly,fromDate, toDate));
    yield put(downloadCsvDone());
}

export function* rmaDetailsSaga() {
    yield takeEvery('rmaDetails/loadRmaDetails', workerFetchRmaDetails);
    yield takeEvery('rmaDetails/downloadcsv', workerDownloadCsv)
}

// Action creators are generated for each case reducer function
export const {
    loadRmaDetails,
    loadRmaDetailsFailure,
    loadRmaDetailsSuccess,
    downloadcsv,
    downloadCsvDone,
} = rmaDetailsSlice.actions

export default rmaDetailsSlice.reducer;