import CardView from "../CardView";
import {useTranslation} from "react-i18next";
import {Label} from "./RMADetails";
import {Grid, Typography} from "@mui/material";

export default function PaymentDetails({amount, mailingAddress,paymentCurrency, isCheckPayment, referenceNumber, paymentMode, isApproved}) {
    const {t} = useTranslation();
    return <CardView title={t('Payment Details')} stretch height={'100%'} >
        <Grid container spacing={2}>
            {paymentMode && <Grid item xs={12} md={6}>
                <Label text={t('Payment Mode')}/>
                <Typography>{paymentMode}</Typography>
            </Grid>}
            {isApproved && amount ? <Grid item xs={12} md={6}>
                <Label text={t('Total Amount to be paid')}/>
                <Typography>{paymentCurrency}{amount}</Typography>
            </Grid>: null}
            {isApproved && isCheckPayment ? <Grid item xs={12} md={6}>
                <Label text={t('Payment Address')}/>
                <Typography>{mailingAddress?.address1} {mailingAddress?.address2} {mailingAddress?.city}</Typography>
                <Typography>{mailingAddress?.zip} {mailingAddress?.state} {mailingAddress?.country}</Typography>
            </Grid>: null}
            {isApproved && referenceNumber? <Grid item xs={12} md={6}>
                <Label text={t('Check/Wire reference number')}/>
                <Typography>{referenceNumber}</Typography>
            </Grid>: null}
        </Grid>
    </CardView>
}