import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {config} from './chartsConfig';
import {useTranslation} from 'react-i18next';

const options = (status, text, t) => ({
    chart: {
        type: 'pie',
        height: 200,
        width: 200,
    },
    credits: {
        enabled: false
    },
    plotOptions: {
        pie: {
            innerSize: '85%',
            colors: Object.keys(status).map(k => config[k].color),
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false,
            }
        }
    },

    title: {
        verticalAlign: 'middle',
        floating: true,
        text: `<small>${text}</small><br><strong>${Object.values(status).reduce((c, a) => c + a, 0)}</strong>`
    },
    series: [
        {
            data: [
                ...Object.entries(status).map(([k,v])=> ([t(k),v]))
            ]
        }
    ],
});

export default function StatusChart({status}) {
    const {t} = useTranslation();
    return <PieChart highcharts={Highcharts} options={options(status, t('Total<br>RMAs'), t)}/>;
}