import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {AiOutlineDownload, AiOutlineUndo} from "react-icons/ai";
import {useEffect, useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import {TableBodySection} from "./TableBodySection";
import {SelectColumns} from "./SelectColumns";
import {Filters} from "./Filters";
import {PaginationSection} from "./PaginationSection";
import {Sort} from "./Sort";
import {Trans} from "react-i18next";

const padding = 1;
const cellStyle = {
    padding,
    borderBottom: 'none',
    borderRight: '1px solid #fff'
}
let filterKey = 1;

export default function MaterialTableWithSearch({
                                                    total,
                                                    locale,
                                                    columns,
                                                    records,
                                                    onChange,
                                                    isLoading,
                                                    download,
                                                    disabledFilters,
                                                    columnExtraProps,
                                                    clearAllFiltersHandle
                                                }) {
    const [sortBy, setSortBy] = useState({});
    const [page, setPage] = useState(1);
    const [per_page, setPerPage] = useState(10);
    const [filters, setFilters] = useState({});
    const [visibleColumns, setVisibleColumns] = useState(columns);
    useEffect(() => {
        onChange({page, per_page, ...sortBy, ...filters});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy, page, per_page, filters]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function clearAll() {
        setFilters({});
        setSortBy({});
        filterKey += 1;
    }

    useEffect(() => {
        clearAllFiltersHandle(() => {
            setFilters({});
            setSortBy({});
            filterKey += 1;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeRowsPerPage = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    return <TableContainer component={Paper} sx={{padding: 1, position: 'relative'}}>
        <Box display="flex" justifyContent="space-between">
            <PaginationSection
                align="left"
                locale={locale}
                total={total}
                page={page}
                rowsPerPage={per_page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Box>
                <Tooltip title={locale.Download}>
                    <IconButton onClick={download.onDownload}
                                disabled={download.downloadInProgress}
                                size="small" sx={{border: '1px solid #ddd', mr: 1}}>
                        <AiOutlineDownload/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={locale.Clear_all_filters}>
                    <IconButton onClick={clearAll}
                                size="small"
                                sx={{border: '1px solid #ddd', mr: 1}}>
                        <AiOutlineUndo/>
                    </IconButton>
                </Tooltip>
                <SelectColumns columns={columns}
                               visibleColumns={visibleColumns}
                               setVisibleColumns={setVisibleColumns}
                               locale={locale}
                />
            </Box>
        </Box>
        <Box sx={{overflow: 'auto'}} my={1}>
            <Table size="small" padding="none" sx={{minWidth: 1000}}>
                <TableHead>
                    <TableRow sx={{backgroundColor: '#edc'}}>
                        {visibleColumns.map(column => <TableCell key={column.key}
                                                                 sx={{...cellStyle, width: column.width}}
                                                                 variant="head">
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}><Typography variant="subtitle2"
                                                                                                     sx={{fontWeight: 700}}><Trans>{column.name}</Trans></Typography>
                                {column.sorter ? <Sort direction={sortBy} column={column.key} setSortBy={setSortBy}/>: null}</Box>
                        </TableCell>)}
                    </TableRow>
                    <Filters key={filterKey} columns={visibleColumns} filters={filters} setFilters={setFilters}
                             disabledFilters={disabledFilters}
                             cellStyle={cellStyle}/>
                </TableHead>
                <TableBodySection columExtraProps={columnExtraProps} cellStyle={cellStyle} locale={locale}
                                  records={records} columns={visibleColumns} isLoading={isLoading}/>
            </Table>
        </Box>
        <PaginationSection
            align="right"
            total={total}
            page={page}
            locale={locale}
            rowsPerPage={per_page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </TableContainer>
}



