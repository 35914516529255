import {createContext, useContext, useState} from "react";
import {subDays} from "date-fns";
import {getValue, setValue} from "../services/localStorage";
import {logGAEvent} from "../analytics/ga4";

const START_DATE_STORAGE_KEY = 'startDate';
const END_DATE_STORAGE_KEY = 'endDate';
const DateRangeContext = createContext({
    fromDate: initialize(START_DATE_STORAGE_KEY, subDays(new Date(), 30)),
    toDate: initialize(END_DATE_STORAGE_KEY, new Date())
});

export const DateRangeProvider = ({children}) => {
    const [dateRange, setDateRange] = useState({
        fromDate: initialize(START_DATE_STORAGE_KEY, subDays(new Date(), 30)),
        toDate: initialize(END_DATE_STORAGE_KEY, new Date())
    });
    return (<DateRangeContext.Provider value={{dateRange, setDateRange}}>{children}</DateRangeContext.Provider>);
}

function initialize(storageKey, defaultValue) {
    const storedValue = getValue(storageKey);
    return storedValue ? new Date(storedValue) : defaultValue;
}
export function getEndOfDay(day) {
    day.setHours(23, 59, 59, 999);
    return day;
}
export function getStartOfDay(day){
    day.setHours(0,0,0,0);
    return day;
}
export default function useSubmittedDateRange() {
    const {dateRange, setDateRange} = useContext(DateRangeContext);
    const [fromDate, setFromDate] = useState(() => initialize(START_DATE_STORAGE_KEY, subDays(new Date(), 30)));
    const [toDate, setToDate] = useState(() => initialize(END_DATE_STORAGE_KEY, new Date()));

    function apply() {
        setDateRange({fromDate, toDate});
        setValue(START_DATE_STORAGE_KEY, fromDate.toISOString());
        setValue(END_DATE_STORAGE_KEY, toDate.toISOString());
        logGAEvent({
            action: 'filter_by_date_range',
            category: 'homepage',
        });
    }

    function reset() {
        const from = subDays(new Date(), 30)
        const to = new Date();
        setFromDate(from);
        setToDate(to);
        setDateRange({fromDate: from, toDate: to});
        setValue(START_DATE_STORAGE_KEY, from.toISOString());
        setValue(END_DATE_STORAGE_KEY, to.toISOString());
    }

    return {setFromDate, setToDate, fromDate, toDate, apply, reset, dateRange};
}