import CardView from "../CardView";
import {Trans, useTranslation} from "react-i18next";
import {Grid, IconButton} from "@mui/material";
import {MdDownload, MdPictureAsPdf} from "react-icons/md";
import {useTheme} from "@mui/styles";

export default function Documents({documents, handleDownloadDocument}) {
    const {t} = useTranslation();
    const theme = useTheme();
    return <CardView title={t('Documents')} stretch >
        <Grid container spacing={2}>
            {documents
                ?.map(text => <Grid key={text} item xs={12} md={6}
                                   sx={{display: 'flex', alignItems: 'center'}}>
                    <MdPictureAsPdf size={20} color={theme.palette.grey[500]} style={{marginRight: 5}}/>
                    <Trans>{text}</Trans>
                    <IconButton sx={{marginLeft: 'auto'}} onClick={() => handleDownloadDocument(text)}><MdDownload size={20}/></IconButton>
                </Grid>)}
        </Grid>
    </CardView>;
}