import {Breadcrumbs, IconButton, Typography} from "@mui/material";
import {MdHome} from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";

export default function ShowBreadcrumbs({links, current}){
    const navigate = useNavigate();
    return <Breadcrumbs aria-label="breadcrumb">
        <IconButton color="primary" onClick={() => navigate('/')}><MdHome/></IconButton>
        {links?.map(link => <Link key={link.text} to={link.url}>{link.text}</Link>)}
        <Typography role="main" color="text.primary">{current}</Typography>
    </Breadcrumbs>
}