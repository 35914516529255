import {createSlice} from "@reduxjs/toolkit";

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        message: '',
        open: false,
        severity: 'info',
        delay: 6000
    },
    reducers: {
        openSnackbar:(state, action)=>{
            state.open = true;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
            state.delay = action.payload.delay || 6000;
        },
        closeSnackbar: state => {
            state.open = false;
            state.message = '';
            state.severity = 'info';
        }
    }
});
export const {openSnackbar, closeSnackbar} = snackbarSlice.actions;
export default snackbarSlice.reducer;