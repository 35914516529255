import {MdFileCopy} from "react-icons/md";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Typography
} from "@mui/material";
import {sanitise} from "../../services/helper";
import CardView from "../CardView";
import DialogTitleWithCloseButton from "../DialogTitle";
import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import caseService from "../../services/caseService";
import {loadCaseDetailsSuccess} from "../../model/caseDetails";
import {useDispatch} from "react-redux";
import {logGAEvent} from "../../analytics/ga4";


export default function InstallerNotes({caseLoading, caseError, caseDetails}) {
    const [open, setOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const handleClose = () => {
        setOpen(false);
    }
    const handleClickOpen = () => {
        setOpen(true);
    }
    const onSubmit = () => {
        setSubmitting(true);
        logGAEvent({
            action: 'add_case_note',
            category: 'rma_details_page',
            value: caseDetails.case_number
        })
        caseService.addNotes(caseDetails.case_number, notes)
            .then(data => {
                setSubmitting(false);
                dispatch(loadCaseDetailsSuccess({data}));
                handleClose();
            })
            .catch(_ => {
                setSubmitting(false);
                setError(t('Failed to add note'));
            });
    }
    return <CardView stretch title={t('Additional Notes')} headerIcon={<MdFileCopy/>}
                     action={<Button onClick={handleClickOpen}
                                     size="small"
                                     color="inherit"
                                     variant="outlined"><Trans>Add Note</Trans></Button>}>
        <Box p={2} data-testid="notes" maxHeight={350} overflow="auto">
            {caseLoading && <CircularProgress variant="indeterminate"/>}
            {caseError && <Alert severity="error" color="error">{caseError}</Alert>}
            {sanitise(caseDetails?.installer_notes)?.split(/\n/g).map((s, i) => <Typography key={i}
                                                                                            variant="body2">{s}</Typography>)}
        </Box>
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitleWithCloseButton id="customized-dialog-title" onClose={handleClose}>
                <Trans>Add Additional Notes</Trans>
            </DialogTitleWithCloseButton>
            <DialogContent dividers>
                {error && <Alert severity="error" color="error">{error}</Alert>}
                <TextField
                    id="notes"
                    label={t('Notes')}
                    value={notes}
                    onChange={e => setNotes(e.target.value)}
                    fullWidth
                    autoFocus
                    multiline
                    inputProps={{ maxLength: 500 }}
                    rows={4}
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose} disabled={submitting}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button variant="contained" color="primary" onClick={onSubmit}
                        endIcon={submitting && <CircularProgress variant="indeterminate" size={20}/>}
                        disabled={submitting}>
                    <Trans>Add</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    </CardView>
}