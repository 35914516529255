import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
import rmaStatusReducer from "./model/rmaStatus";
import rmaDetailsReducer from "./model/rmaDetails";
import caseDetailsReducer from './model/caseDetails';
import topicsReducer from './model/topics';
import shippingAddressReducer from './model/shippingAddress';
import siteReducer from './model/site';
import userReducer from "./model/user";
import snackbarReducer from "./model/snackbar";
import rootSaga from './sagas';
const saga = createSagaMiddleware();

export default configureStore({
    reducer: {
        rmaStatus: rmaStatusReducer,
        rmaDetails: rmaDetailsReducer,
        caseDetails: caseDetailsReducer,
        auth: userReducer,
        topics: topicsReducer,
        shippingAddress: shippingAddressReducer,
        site: siteReducer,
        snackbar: snackbarReducer
    },
    middleware: [saga]
});
saga.run(rootSaga);