import api from './api';
import getConfig from "./env";
import tokenService from "./tokenService";
import {serialize} from "./helper";
import {createRMARequest} from "./model/rmaRequest";

class SfdcService{
    getRMAsForCompanyId = (search, active, startDate, endDate) => new Promise((resolve, reject) => {
        const {company_id} = tokenService.decodeToken();
        const rmaRequest = createRMARequest(search, active, startDate, endDate);
        const searchParam = serialize(rmaRequest);
       const url = `${getConfig().sfdc_url}/companies/${company_id}/rmas?${searchParam}`;
        api.get(url)
            .then(({data}) => resolve(data))
            .catch(error => reject(error));
    });

    getRMASummary = (startDate, endDate, siteId)=> new Promise((resolve, reject) => {
       const {company_id} = tokenService.decodeToken();
       const siteIdParam = siteId && +siteId > 0 ? `&siteId=${siteId}`:''
       const url = `${getConfig().sfdc_url}/companies/${company_id}/rmasummary?startDate=${startDate}&endDate=${endDate}${siteIdParam}`;
       api.get(url)
           .then(({data})=> resolve(data))
           .catch(error => reject(error));
    });

    getRMALineItems = (rmaNumber) => new Promise((resolve, reject) => {
        const {company_id} = tokenService.decodeToken();
       const url = `${getConfig().sfdc_url}/companies/${company_id}/rmas/${rmaNumber}/lines`;
       api.get(url)
           .then(({data})=> resolve(data))
           .catch(error => reject(error));
    });
    downloadCsv = (search, active, startDate, endDate) => new Promise(((resolve, reject) => {
        const {company_id} = tokenService.decodeToken();
        const rmaRequest = createRMARequest(search, active, startDate, endDate);
        const searchParam = serialize(rmaRequest);
        const url = `${getConfig().sfdc_url}/companies/${company_id}/rmas/csv?${searchParam}`;
        api.get(url)
            .then(({data}) => {
                const binaryData = [];
                binaryData.push(data);
                let objectUrl = window.URL.createObjectURL(new Blob(binaryData, {type: "text/csv"}));
                let anchor = document.createElement("a");
                document.body.appendChild(anchor);
                anchor.href = objectUrl;
                anchor.download = 'rma_list.csv';
                anchor.click();
                window.URL.revokeObjectURL(objectUrl);
                anchor.remove();
                resolve(true);
            }).catch(error => reject(error));
    }));
}
export default new SfdcService();