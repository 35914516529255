import {Grid, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getCountries, getStatesForCountry} from "../../model/places";
import {useRef} from "react";

export default function AddressComponent({address, disabled, handleChange, errors}) {
    const {t} = useTranslation()
    const address1Ref = useRef(null);
    const address2Ref = useRef(null);
    const stateRef = useRef(null);
    const cityRef = useRef(null);
    const countryRef = useRef(null);
    const zipRef = useRef(null);
    function focusErrorField() {
        if(window.isFocused) return;
        if(errors['address1']){
            address1Ref.current.focus();
            window.isFocused = true;
        }else if(errors['address2']){
            address2Ref.current.focus();
            window.isFocused = true;
        }else if(errors['state']){
            stateRef.current.focus();
            window.isFocused = true;
        }else if(errors['zip']){
            zipRef.current.focus();
            window.isFocused = true;
        }else if(errors['city']){
            cityRef.current.focus();
            window.isFocused = true;
        }else if(errors['country']){
            countryRef.current.focus();
            window.isFocused = true;
        }
    }
    focusErrorField();
    return <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
            <TextField disabled={disabled} value={address?.address1 || ''} fullWidth
                       variant="standard" label={t('Street Address')}
                       error={!!errors['address1']}
                       inputRef={address1Ref}
                       helperText={errors['address1']}
                       onChange={e => handleChange('address1', e.target.value)}
                       required/>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField data-testid="state" SelectProps={{native: true}} disabled={disabled}
                       value={address?.state} fullWidth variant="standard"
                       label={t('State/Province')} select
                       inputRef={stateRef}
                       error={!!errors['state']}
                       helperText={errors['state']}
                       onChange={e => handleChange('state', e.target.value)}
                       required>
                <option value={''}/>
                {getStatesForCountry(address?.country || 'US')?.map(s => <option key={s.code} value={s.code}>{s.name}</option>)})
            </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField onChange={e => handleChange('address2', e.target.value)}
                       disabled={disabled} value={address?.address2 || ''} fullWidth
                       error={!!errors['address2']}
                       inputRef={address2Ref}
                       helperText={errors['address2']}
                       variant="standard" label={t('Street Address 2')}/>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField onChange={e => handleChange('zip', e.target.value)} disabled={disabled}
                       value={address?.zip || ''} fullWidth variant="standard"
                       label={t('Zip/Postal Code')}
                       error={!!errors['zip']}
                       inputRef={zipRef}
                       helperText={errors['zip']}
                       required/>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField onChange={e => handleChange('city', e.target.value)} disabled={disabled}
                       error={!!errors['city']}
                       inputRef={cityRef}
                       helperText={errors['city']}
                       value={address?.city || ''} fullWidth variant="standard" label={t('City')} required/>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField SelectProps={{native: true,}} disabled={disabled} value={address?.country || 'US'}
                       error={!!errors['country']}
                       helperText={errors['country']}
                       inputRef={countryRef}
                       data-testid="country" onChange={e => handleChange('country', e.target.value)} fullWidth
                       variant="standard" label={t('Country')} select required>
                {getCountries()?.map(c => <option key={c} value={c}>{c}</option>)}
            </TextField>
        </Grid>
    </Grid>;
}