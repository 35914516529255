import CardView from "./CardView";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import {MdLens} from "react-icons/md";

export default function RMAStatusCard({status}){
    const {t} = useTranslation();
    return <CardView stretch title={t("RMA Status")} headerIcon={<MdLens/>}>
        <Box p={2}>
            <Typography variant="subtitle2" sx={{fontWeight: 700}}>{status}</Typography>
        </Box>
    </CardView>
}