import {alpha, Box, Hidden, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.dark,
        padding: 5,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'start'
        }
    },
    label: {
        color: alpha(theme.palette.common.white, 0.7),
        marginRight: 2
    },
    value: {
        color: theme.palette.common.white
    }
}));
export default function RMAHeaderView({rmaDetails, caseDetails}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {case_number} = rmaDetails?.rma_case || {case_number: ''};
    return <Box className={classes.root}>
        <Field label={t("RMA No")} value={rmaDetails?.rma_number}/>
        <Field label={t("System Id")} value={rmaDetails?.rma_case?.site_id}/>
        <Field label={t("Case No")} value={case_number}/>
        <Box flex={1}/>
        {caseDetails?.assigned_to && <Field label={t("Assigned To")} value={caseDetails?.assigned_to}/>}
        <Hidden smDown >{rmaDetails?.modified_date && <Typography className={classes.value}>|</Typography>}</Hidden>
        {rmaDetails?.created_date && <Field label={t("Created On")} value={format(new Date(rmaDetails?.created_date),'dd-MM-yyyy')}/>}
        <Hidden smDown >{rmaDetails?.modified_date && <Typography className={classes.value}>|</Typography>}</Hidden>
        {rmaDetails?.modified_date && <Field label={t("Last Updated")} value={format(new Date(rmaDetails?.modified_date),'dd-MM-yyyy')}/>}
    </Box>
}

function Field({label, value}) {
    const classes = useStyles();
    return <Box mx={2} display="flex">
        <Typography className={classes.label}>{label}:</Typography>
        <Typography className={classes.value}>{value}</Typography>
    </Box>
}