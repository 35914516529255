import {Trans, useTranslation} from "react-i18next";
import ShowBreadcrumbs from "../components/BreadCrumbView";
import {useNavigate, useParams} from "react-router-dom";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    LinearProgress,
    Typography
} from "@mui/material";
import React, {useEffect, useReducer, useState} from "react";
import RmaDetails from "../components/LabourReimbursementForm/RmaDetails";
import InstallerDetails from "../components/LabourReimbursementForm/InstallerDetails";
import RegisteredAddress from "../components/LabourReimbursementForm/RegisteredAddress";
import PaymentDetails from "../components/LabourReimbursementForm/PaymentDetails";
import DocumentsUploadSection from "../components/LabourReimbursementForm/DocumentsUploadSection";
import labourReimbursementService from "../services/labourReimbursementService";
import {initialise, initialState, reducer} from "../components/LabourReimbursementForm/formReducer";
import useSnackbar from "../components/useSnackbar";
import InstructionsModal from "../components/LabourReimbursementForm/InstructionsModal";
import {validateForm} from "../components/LabourReimbursementForm/validator";
import {logGAEvent, logGAException} from "../analytics/ga4";

const LabourReimbursementFormPage = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [state, dispatcher] = useReducer(reducer, initialState);
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const [serialNumbers, setSerialNumbers] = useState([]);
    const [files, setFiles] = useState({});
    const enqueueSnackbar = useSnackbar();
    useEffect(() => {
        async function init(){
            if (id) {
                setLoading(true);
                const feature = await labourReimbursementService.checkLabourReimbursementEnabled();
                if(!feature.enabled){
                    navigate(`/rma-details/${id}`);
                }
                Promise.all([labourReimbursementService.checkEligibility(id),
                    labourReimbursementService.loadPrerequisites(id)])
                    .then(([{data}, prerequisite]) => {
                        if(!data?.eligible || !data.eligible_serial_numbers || data.eligible_serial_numbers.length < 1){
                            navigate(`/rma-details/${id}`);
                        }
                        setSerialNumbers(data.eligible_serial_numbers);
                        setFormData(prerequisite);
                        dispatcher(initialise(prerequisite));
                        setLoading(false);
                        setFiles({});
                    })
                    .catch(_ => setLoading(false));
            }
        }
        init();
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    function handlePrefillDocument(){
        const {
            address, cheque_payment, installer_contact_email, installer_contact_name, installer_contact_phone,
            mailing_address
        } = state;
        const payload = {
            address,
            cheque_payment,
            company_name: formData.company.name,
            contact_name:installer_contact_name,
            email:installer_contact_email,
            mailing_address,
            phone_number: installer_contact_phone,
        };
        labourReimbursementService.prefillDocumentDownload(id,payload);
    }

    function handleSubmit() {
        const validity = validateForm(formData.documents_required, files, state, t);
        window.isFocused = false;
        dispatcher(validity);
        if(JSON.stringify(validity.payload.errors) !== '{}'){
            enqueueSnackbar({severity: 'error', message: t('Please correct the errors in the form and resubmit.')});
            return;
        }
        setSubmitting(true);
        const {
            address, cheque_payment, installer_contact_email, installer_contact_name, installer_contact_phone,
            mailing_address
        } = state;
        const payload = {
            address,
            cheque_payment,
            installer_contact_email,
            installer_contact_name,
            installer_contact_phone,
            eligible_serial_numbers: serialNumbers
        };
        if (cheque_payment) {
            payload.mailing_address = mailing_address;
        }
        logGAEvent({
            action: 'lere_submit',
            category: 'lere',
            label: 'lere',
            value: id
        });
        labourReimbursementService.submitReimbursement(id, payload, files)
            .then(_ => {
                enqueueSnackbar({severity: 'success', message: t('Reimbursement submitted successfully')});
                setSubmitting(false);
                navigate(`/rma-details/${id}`);
            })
            .catch(_ => {
                logGAException({description: 'Labor reimbursement failed', fatal: true});
                enqueueSnackbar({severity: 'error', message: t('Failed to submit reimbursement.')});
                setSubmitting(false)
            });
    }

    if (loading) {
        return <LinearProgress variant="indeterminate"/>
    }else if(!formData){
        return <Box maxWidth="600px" marginX="auto" mt={20}>
            <Alert severity="error" action={<Button onClick={() => navigate('/')}><Trans>Back to home</Trans></Button>}>
                <Trans>Failed to load reimbursement form.</Trans>
            </Alert>
        </Box>;
    }
    const registeredAddressHasCompany = !!formData?.latest_reimbursement ? !(formData.latest_reimbursement?.address): true;

    return <>
        <ShowBreadcrumbs links={[{url: `/rma-details/${id}`, text: `RMA - ${id}`}]}
                         current={t('Labour Reimbursement')}/>
        <Card sx={{mt: 1}}>
            <CardHeader
                title={<Typography variant={"subtitle1"} sx={{fontWeight: 600, display: 'flex', alignItems: 'center'}}>
                    <Trans>Labour Reimbursement Request</Trans><InstructionsModal/></Typography>}
                disableTypography/>
            <CardContent>
                <RmaDetails rmaNumber={id}
                            serialNumbers={serialNumbers}
                            systemInfo={formData?.system_info}
                />
                <Box mt={2}/>
                <InstallerDetails companyName={formData?.company.name}
                                  name={state.installer_contact_name}
                                  email={state.installer_contact_email}
                                  phone={state.installer_contact_phone}
                                  dispatch={dispatcher}
                                  errors={state.errors}
                />
                <Box mt={2}/>
                <RegisteredAddress errors={state?.errors?.address || {}}
                                   changeRegisteredAddress={state?.changeRegisteredAddress}
                                   registeredAddress={state?.address} dispatch={dispatcher}/>
                <Box mt={2}/>
                {formData?.cheque_payment_eligible && <PaymentDetails
                    payByCheck={state?.cheque_payment}
                    dispatch={dispatcher}
                    errors={state?.errors?.mailing_address || {} }
                    newAddress={state.mailing_address}
                    mailingAddresses={formData?.mailing_addresses}/>}
                <Box mt={2}/>
                <DocumentsUploadSection
                    errors={state.errors}
                    changeRegisteredAddress={state?.changeRegisteredAddress}
                    country={formData?.system_info?.address?.country}
                    newMailingAddress={state?.newMailingAddress}
                    prefillDocument={handlePrefillDocument}
                    registeredAddressHasCompany={registeredAddressHasCompany}
                    taxForm={formData?.company?.tax_form}
                    files={files} setFiles={setFiles} requiredDocuments={formData?.documents_required}/>
            </CardContent>
        </Card>
        <Box py={5}>
            <Button disabled={submitting}
                    endIcon={submitting && <CircularProgress variant="indeterminate" size={20}/>}
                    sx={{width: 200}} size="large" variant="contained" onClick={handleSubmit}><Trans>Submit</Trans> {}
            </Button>
        </Box>
    </>
}


export default LabourReimbursementFormPage;