import getConfig from "./env";
import api from "./api";

class CaseService{
    getCaseDetails = (caseNumber) =>new Promise((resolve, reject) =>{
        const url = `${getConfig().sfdc_url}/cases/${caseNumber}`
        api.get(url)
            .then(({data}) => resolve(data))
            .catch(error => reject(error));
    });
    createCase = (systemId, payload) => new Promise((resolve, reject) => {
        const url = `${getConfig().service_manager_url}/api/internal/installer/case`;
        const formData = new FormData();
        const json = JSON.stringify(payload)
        formData.append('details', json)
        api
            .post(url, formData)
            .then(({data}) => resolve(data))
            .catch((error) => reject(error))
    })
    addNotes = (caseNumber, note) => new Promise((resolve, reject) => {
       const url = `${getConfig().sfdc_url}/cases/${caseNumber}/notes`;
        api.post(url, {note})
            .then(({data})=> resolve(data))
            .catch(error => reject(error));
    });
}
const caseService = new CaseService();
export default caseService;