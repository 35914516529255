import {createSlice} from "@reduxjs/toolkit";
import {call, put, takeEvery} from "redux-saga/effects";
import shippingAddressService from "../services/shippingAddressService";

export const shippingAddressSlice = createSlice({
    name: 'shippingAddress',
    initialState:{
        loading: false,
        data: [],
        error: ''
    },
    reducers: {
        loadShippingAddress: (state) => {
            state.loading = true;
            state.data = [];
            state.error = '';
        },
        loadShippingAddressSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        loadShippingAddressFailure: (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.payload.error;
        },
    }
});
export const {loadShippingAddress, loadShippingAddressFailure, loadShippingAddressSuccess} = shippingAddressSlice.actions;
export default shippingAddressSlice.reducer;
function* workerFetchShippingAddress({payload: {systemId}}){
    try{
        const data = yield call(() => shippingAddressService.getShippingAddress(systemId));
        yield put(loadShippingAddressSuccess({data}));
    }catch (error){
        yield put(loadShippingAddressFailure({error: 'Error occurred while loading shipping addresses!'}));
    }
}


export function* shippingAddressSaga(){
    yield takeEvery('shippingAddress/loadShippingAddress', workerFetchShippingAddress)
}