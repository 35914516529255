import {Grid, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import SystemInformationView from "../SystemInformationView";
import CardView from "../CardView";
import {useTheme} from "@mui/styles";

export default function RmaDetails({rmaNumber, serialNumbers, systemInfo}){
    const {t} = useTranslation();
    return <CardView title={t('RMA Details')}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Label><Trans>RMAs qualified for reimbursement</Trans></Label>
                <Typography mb={1}>{rmaNumber}</Typography>
                <Label><Trans>Associated Serial Numbers</Trans></Label>
                <Typography sx={{overflowWrap: 'break-word'}}>{serialNumbers?.join(',')}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <SystemInformationView systemInfo={systemInfo}/>
            </Grid>
        </Grid>
    </CardView>
}
function Label({children}) {
    const theme = useTheme();
    return <Typography variant="subtitle2" sx={{color: theme.palette.grey[600]}}>{children}</Typography>
}