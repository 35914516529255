import getConfig from "./env";
import api from "./api";

class ShippingAddressService{
    getShippingAddress = (systemId) => new Promise(((resolve, reject) => {
        const url = `${getConfig().service_manager_url}/api/internal/installer/systems/${systemId}/shipping_address`;
        api.get(url)
            .then(({data})=> resolve(data))
            .catch(error => reject(error));
    }));
}

const shippingAddressService = new ShippingAddressService();
export default shippingAddressService;