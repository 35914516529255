export const initialState = {
    address: { country: 'US'},
    cheque_payment: false,
    installer_contact_email: '',
    installer_contact_name: '',
    installer_contact_phone: '',
    mailing_address: { country: 'US'},
    changeRegisteredAddress: false,
    newMailingAddress: false,
    errors: {}
};

export function reducer(state, action) {
    switch (action.type) {
        case 'INITIALISE':
            return {...state, ...mapPrerequisiteToState(action.payload)}
        case 'UPDATE_FIELD':
            return {...state, [action.payload.name]: action.payload.value};
        case 'UPDATE_ADDRESS':
            return {...state, address: {...state.address, [action.payload.name]: action.payload.value}};
        case 'UPDATE_MAILING_ADDRESS':
            return {...state, mailing_address: {...state.mailing_address, [action.payload.name]: action.payload.value}};
        case 'REPLACE_MAILING_ADDRESS':
            return {...state, mailing_address: action.payload};
        case 'VALIDATE':
            return {...state, errors: action.payload.errors}
        default:
            return state;
    }
}

export function initialise(data) {
    return {type: 'INITIALISE', payload: data};
}

export function updateInstaller_contact_email(value) {
    return updateField('installer_contact_email', value);
}
export function setChangeRegisteredAddress(value){
    return updateField('changeRegisteredAddress', value);
}
export function setNewMailingAddress(value){
    return updateField('newMailingAddress', value);
}

export function updateInstaller_contact_name(value) {
    return updateField('installer_contact_name', value);
}

export function updateInstaller_contact_phone(value) {
    return updateField('installer_contact_phone', value);
}

export function updateAddress(name, value) {
    return {type: 'UPDATE_ADDRESS', payload: {name, value}};
}

export function updateMailingAddress(name, value) {
    return {type: 'UPDATE_MAILING_ADDRESS', payload: {name, value}};
}

export function replaceMailingAddress(address) {
    const {address1, address2, city, country, state, zip} = address;
    return {type: 'REPLACE_MAILING_ADDRESS', payload: {address1, address2, city, country, state, zip}};
}

export function updateCheckPayment(value) {
    return updateField('cheque_payment', value);
}

function updateField(name, value) {
    return {type: 'UPDATE_FIELD', payload: {name, value}};
}

function mapPrerequisiteToState(prerequisite) {
    const latestReimbursementAddress = prerequisite?.latest_reimbursement?.address;
    return {
        cheque_payment: false,
        installer_contact_email: prerequisite.user_info.email,
        installer_contact_name: prerequisite.user_info.name,
        installer_contact_phone: prerequisite.user_info.phone,
        address: mapAddress(latestReimbursementAddress ? latestReimbursementAddress : prerequisite.company.address),
        mailing_address: {country: 'US'}
    };
}

function mapAddress(address) {
    const {address1, address2, city, country, state, zip} = address;
    return {address1, address2, city, country, state, zip};
}