import {createSlice} from "@reduxjs/toolkit";
import {call, put, debounce} from "redux-saga/effects";
import topicService from "../services/topicService";

export const topicsSlice = createSlice({
    name: 'topics',
    initialState: {
        loading: false,
        data: [],
        error: ''
    },
    reducers: {
        loadTopics: (state) => {
            state.loading = true;
            state.error = '';
        },
        loadTopicsSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        loadTopicsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },
    }
});

export const  {loadTopics, loadTopicsSuccess, loadTopicsFailure} = topicsSlice.actions;
export default topicsSlice.reducer;

function* workerFetchTopics(){
    try{
        const data = yield call(() => topicService.getTopics());
        yield put(loadTopicsSuccess({data: data.sort((a,b) => a.key.charCodeAt(0) - b.key.charCodeAt(0))}));
    }catch (error){
        yield put(loadTopicsFailure({error: 'Error occurred while loading topics!'}));
    }
}


export function* topicsSaga(){
    yield debounce(1000, 'topics/loadTopics', workerFetchTopics)
}