import {Box, Button, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Trans, useTranslation} from "react-i18next";
import useSubmittedDateRange from "./useSubmittedDateRange";
import {DatePicker} from "@mui/x-date-pickers";
import clsx from "clsx";
import GlobalSiteSearch from "./GlobalSiteSearch";
import {isAfter} from "date-fns";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#eee',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    item: {
        [theme.breakpoints.down('md')]: {
            marginBottom: 10,
            width: '90%'
        }
    },
    whitebg: {
        backgroundColor: '#fff',
    }
}))

export default function SubmittedDateRangeSelection() {
    const {setToDate, setFromDate, fromDate, toDate, reset, apply} = useSubmittedDateRange();
    const classes = useStyles();
    const {t} = useTranslation();
    return <Box display="flex" alignItems="center" p={2} className={classes.container}>
        <Box mx={2} className={classes.item}><Typography variant="body2" className={classes.item}>
            <Trans>Submitted Date</Trans></Typography></Box>
        <Box mx={2} className={clsx(classes.whitebg, classes.item)}>
            <DatePicker
                label={t('From')}
                value={fromDate}
                onChange={e => setFromDate(e)}
                disableFuture
                renderInput={(params) => <TextField size="small" fullWidth {...params} />}
            />
        </Box>
        <Box mx={2} className={clsx(classes.whitebg, classes.item)}>
            <DatePicker
                label={t('To')}
                value={toDate}
                onChange={e => setToDate(e)}
                minDate={fromDate}
                disableFuture
                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
            />
        </Box>
        <Box mx={2} className={classes.item}>
            <Button size="small" disabled={isAfter(fromDate, toDate)} onClick={apply}
                    fullWidth
                    variant="contained"><Trans>Apply</Trans></Button>
        </Box>
        <Box mx={2} className={classes.item}>
            <Button size="small" onClick={reset}
                    fullWidth
                    variant="outlined"><Trans>Reset</Trans></Button>
        </Box>
        <Box ml="auto">
            <GlobalSiteSearch/>
        </Box>
    </Box>
}