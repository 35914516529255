import {createSlice} from '@reduxjs/toolkit';
import {call, put, debounce} from 'redux-saga/effects';
import sfdcService from "../services/sfdcService";
import {subDays} from "date-fns";
import {getEndOfDay, getStartOfDay} from "../components/useSubmittedDateRange";

export const rmaStatusSlice = createSlice({
    name: 'rmaStatus',
    initialState: {
        loading: false,
        data: [],
        error: '',
        selected: []
    },
    reducers: {
        loadRmaStatus: (state) => {
            state.loading = true;
            state.error = '';
        },
        loadRmaStatusSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        loadRmaStatusFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },
        toggleSelectedStatus: (state, action) => {
            if(state.selected.includes(action.payload.status)){
                state.selected = state.selected.filter(status => status !== action.payload.status);
            }else{
                state.selected = [...new Set([...state.selected, action.payload.status])];
            }
        },
        clearAllSelection: (state, action) => {
            state.selected.splice(0,state.selected.length);
        }
    },
});
function* workerFetchRmaStatus({payload: {fromDate = subDays(new Date(), 30), toDate= new Date(), siteId}}){
    try {
        const data = yield call(() => sfdcService.getRMASummary(getStartOfDay(fromDate).toISOString(), getEndOfDay(toDate).toISOString(), siteId));
        yield put(rmaStatusSlice.actions.loadRmaStatusSuccess({data}));
    }catch (error){
        yield put(rmaStatusSlice.actions.loadRmaStatusFailure({error: 'Error occurred while loading status!'}));
    }
}

export function* rmaStatusSaga() {
    yield debounce(1000, 'rmaStatus/loadRmaStatus', workerFetchRmaStatus)
}

// Action creators are generated for each case reducer function
export const {
    loadRmaStatus,
    loadRmaStatusFailure,
    loadRmaStatusSuccess,
    toggleSelectedStatus,
    clearAllSelection
} = rmaStatusSlice.actions

export default rmaStatusSlice.reducer;