import TableView from "./TableView";
import {sanitise} from "../services/helper";
import {CHR_FREIGHT_METHODS, CHR_TRACK, FEDEX_FREIGHT_METHODS, FEDEX_TRACK} from "../constants";

const columns = [
    {name: 'defectiveSerialNumber', text: 'Defective Serial Number'},
    {name: 'defectiveProduct', text: 'Defective Product'},
    {name: 'replacement', text: 'Replacement Product'},
    {name: 'actualShipDate', text: 'Actual Ship Date'},
    {name: 'trackingNumber', text: 'Tracking Number'},
]
const getTrackingUrl = (trackingNumber, freightMethod) => {
    if(!sanitise(freightMethod)){
        return  '';
    } else if(CHR_FREIGHT_METHODS.includes(freightMethod.toUpperCase())){
        return `${CHR_TRACK}${sanitise(trackingNumber)}`
    } else if(FEDEX_FREIGHT_METHODS.includes(freightMethod.toUpperCase())){
        return `${FEDEX_TRACK}${sanitise(trackingNumber)}`
    }
    return '';
}
export default function WarrantyClaimLinesView({warrantyClaimLines}){
    return warrantyClaimLines ? <TableView columns={columns} data={warrantyClaimLines?.map(line => ({
        id: line.line_number,
        defectiveSerialNumber: sanitise(line.defective_serial_number),
        defectiveProduct: sanitise(line.defective_product_name),
        replacement: sanitise(line.replacement_serial_number),
        actualShipDate: sanitise(line.actual_ship_date),
        trackingNumber: sanitise(line.shipment_tracking_number),
        trackingLink: getTrackingUrl(line.shipment_tracking_number, line.freight_method)
    }))}/>: null
}