import {createSlice} from "@reduxjs/toolkit";

export const siteSlice = createSlice({
    name: 'site',
    initialState: {site:{id: -1, name: 'All Sites Selected'}},
    reducers:{
        selectSite: (state, action) => {
            state.site = action.payload.site;
        },
        selectAllSites: (state) => {
            state.site = {id: -1, name: 'All Sites Selected'};
        }
    }
});

export const {selectSite, selectAllSites} = siteSlice.actions;
export default siteSlice.reducer;
