export const columns = [
    {
        name: 'RMA Number',
        key: 'rmaNumber',
        width: 800,
        type: 'string',
        sorter: true,
        advanceSearch: true
    },
    {
        name: 'Case Number',
        key: 'caseNumber',
        width: 800,
        type: 'string',
        sorter: true,
        advanceSearch: true
    },
    {
        name: 'RMA Status',
        key: 'rmaStatus',
        width: 800,
        type: 'string',
        sorter: false,
        advanceSearch: false,
        filters: [
            {value: 'In Progress', text: 'In Progress' },
            {value: 'Pending Approval', text: 'Pending Approval'},
            {value: 'Approved', text: 'Approved'},
            {value: 'Shipped', text: 'Shipped'},
            {value: 'Cancelled', text: 'Cancelled'},
        ],
        displayText: 'In Progress'
    },
    {
        name: 'System Id',
        key: 'systemId',
        width: 800,
        type: 'string',
        sorter: true,
        advanceSearch: true
    },
    {
        name: 'System Name',
        key: 'systemName',
        width: 800,
        type: 'string',
        sorter: true,
        advanceSearch: true
    },
    {
        name: 'Modified Date',
        key: 'modifiedDate',
        width: 800,
        type: 'date',
        advanceSearch: true,
        dateFilter: true,
        sorter: true,
        disableFuture: true
    },
    {
        name: 'Submitted Date',
        key: 'submittedDate',
        width: 800,
        type: 'date',
        advanceSearch: true,
        dateFilter: true,
        sorter: true,
        disableFuture: true
    }
];
