import {Autocomplete, Checkbox, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import React from "react";
import {MdCheckBox, MdCheckBoxOutlineBlank} from "react-icons/md";

const icon = <MdCheckBoxOutlineBlank/>;
const checkedIcon = <MdCheckBox/>;

export default function DevicesSelection({devices, selectedDevices, setSelectedDevices}) {
    const {t} = useTranslation();
    const options = [];
    devices.forEach(category => {
        category.devices.forEach(device => {
            options.push({serial_num: device.serial_num, category: category.category, product_name: device.product_name});
        })
    });

    const onSelect = (value) => {
        setSelectedDevices(value)
    }
    return <Autocomplete
        multiple
        id="select-devices"
        options={options.sort((a, b) => -b.category.localeCompare(a.category))}
        disableCloseOnSelect
        getOptionLabel={(option) => option.serial_num}
        onChange={(_,value) => onSelect(value.map(v => v.serial_num))}
        isOptionEqualToValue={(option, value) => option.serial_num === value.serial_num}
        groupBy={(option) => option.category}
        renderOption={(props, option, {selected}) => (
            <li {...props}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{marginRight: 8}}
                    checked={selected}
                />
                {option.serial_num}<Typography component="span" color="rgba(0,0,0,0.3)" sx={{ml: 1}}>{`${option.product_name}`}</Typography>
            </li>
        )}
        disabled={options.length < 1}
        renderInput={(params) => (
            <TextField {...params} label={t('Add Device')} fullWidth />
        )}
    />

}