import React from 'react'
import {useLocation} from 'react-router-dom'
import * as analytics from './ga4'
import tokenService from "../services/tokenService";

export function useAnalytics() {
    const location = useLocation()

    React.useEffect(() => {
        const {company_id} = tokenService.decodeToken();
        analytics.init(company_id)
    }, [])

    React.useEffect(() => {
        const path = location.pathname + location.search
        analytics.sendPageView(path)
    }, [location])
}

export default useAnalytics;
