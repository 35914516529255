import {createSlice} from "@reduxjs/toolkit";
import {call, put, takeEvery} from "redux-saga/effects";
import caseService from "../services/caseService";

export const caseDetailsSlice = createSlice({
    name: 'caseDetails',
    initialState: {
        loading: false,
        data: [],
        error: '',
    },
    reducers: {
        loadCaseDetails: (state) => {
            state.loading = true;
            state.error = '';
        },
        loadCaseDetailsSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        loadCaseDetailsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },
    },
});

function* workerFetchCaseDetails({payload: {caseNumber}}){
    try {
        const data = yield call(() => caseService.getCaseDetails(caseNumber));
        yield put(caseDetailsSlice.actions.loadCaseDetailsSuccess({data}));
    }catch (error){
        yield put(caseDetailsSlice.actions.loadCaseDetailsFailure({error: 'Error occurred while loading Case Details!'}));
    }
}

export function* caseDetailsSaga() {
    yield takeEvery('caseDetails/loadCaseDetails', workerFetchCaseDetails)
}


export const {loadCaseDetails, loadCaseDetailsSuccess, loadCaseDetailsFailure} = caseDetailsSlice.actions;
export default caseDetailsSlice.reducer;