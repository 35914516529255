import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {Alert, Box} from "@mui/material";


export function TableBodySection({records, columns, isLoading, columExtraProps, cellStyle, locale}) {
    if (!records) return null;
    return <TableBody sx={{
        "& .MuiTableRow-root:hover": {
            backgroundColor: '#e2ebff'
        },
        position: 'relative'
    }}>
        {!isLoading && (!records||records.length === 0) ? <TableRow><td colSpan={columns.length}>
            <Alert severity="warning">{locale.No_Data}</Alert>
        </td></TableRow>: null}
        {isLoading && (!records||records.length === 0) ? <TableRow><td colSpan={columns.length}>
            <Box height={100}/>
            </td></TableRow>:null}
        {records.map((record, index) => <TableRow sx={{backgroundColor: index % 2 === 0 ? '' : '#f6f6f6'}} key={index}
                                                  hover
                                                  classes={{hover: 'hover-background'}}>
            {columns.map((column, index) => <TableCell sx={cellStyle} key={index}
                                                       title={record[column.key]}>
                {columExtraProps[column.key]?.render ? columExtraProps[column.key].render('', record): record[column.key]}
            </TableCell>)}
        </TableRow>)}
        <Backdrop
            component={'tr'}
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute'}}
            open={isLoading}
        >
            <td><CircularProgress color="inherit"/></td>
        </Backdrop>
    </TableBody>
}