const fieldMap = {
    caseNumber: 'caseNumber',
    rmaNumber: 'rmaNumber',
    rmaStatus: 'orderStatus',
    systemName: 'systemName',
    systemId: 'siteId',
    source: 'source',
    modifiedDate: 'modifiedDate',
    submittedDate: 'submittedDate'
}

export const getSortKey = (obj) => {
    const sortKey = Object.keys(obj).filter(k => k.startsWith('sort_by_'));
    return sortKey && sortKey.length > 0 ? sortKey[0] : '';
}

function padZero(digit){
    return +digit < 10 ? `0${digit}`: digit;
}

export const createRMARequest = (search, active, startDate, endDate) => {
    if (!search) {
        return {};
    }
    const {per_page, page} = search;

    const rmaRequest = {
        limit: per_page,
        offset: (page - 1) * per_page,
        startDate,
        endDate,
    };
    const sortKey = getSortKey(search)
    if (sortKey) {
        const sort = [fieldMap[sortKey.split('sort_by_')[1]], search[sortKey]];
        rmaRequest.sort = sort;
    }
    Object.keys(fieldMap).forEach(key => {
        const value = search[key]
        if (value) {
            if(key === 'submittedDate'){
                const [year, month, day] = value.split('/');
                const date = new Date(year, month-1, day);
                date.setDate(date.getDate()+1);
                rmaRequest.startDate =  `${year}-${month}-${day}T00:00:00.000Z`;
                rmaRequest.endDate =  `${date.getFullYear()}-${padZero(date.getMonth()+1)}-${padZero(date.getDate())}T00:00:00.000Z`;
            }else if (key === 'modifiedDate') {
                const [year, month, day] = value.split('/');
                rmaRequest[fieldMap[key]] = `${year}-${month}-${day}T00:00:00.000Z`;
            } else {
                rmaRequest[fieldMap[key]] = Array.isArray(value) ? value.join(',') : value;
            }
        }
    });
    rmaRequest.active = active;
    return rmaRequest;
}


