import {Card, CardContent, CardHeader} from "@mui/material";

export default function CardView({title, headerIcon, children, stretch, height, action}) {
    const style = {
        ...(stretch ? {height: height || '96.5%'} : {})
    };
    return <Card sx={style}>
        <CardHeader title={title} disableTypography sx={{backgroundColor: '#f4f4f4', color: 'rgba(0,0,0,0.7)'}}
                    action={action}
                    avatar={headerIcon}/>
        <CardContent>
            {children}
        </CardContent>
    </Card>
}