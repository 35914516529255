import Header from "./Header";
import {Box, useMediaQuery} from "@mui/material";
import {Outlet, useLocation} from "react-router-dom";
import {useTheme} from "@mui/styles";
import tokenService from "../../services/tokenService";
import {useEffect} from "react";
import CreateRMA from "../CreateRMA";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../model/user";
import LabourReimbursementLink from "../LabourReimbursementLink";
import { useAnalytics } from '../../analytics/useAnalytics';
export default function Layout() {
    useAnalytics();
    const {search} = useLocation();
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.auth);
    const location = useLocation();
    useEffect(() => {
        if (search) {
            tokenService.setTokenFromUrl(search);
        }
        const tokenData = tokenService.decodeToken();
        dispatch(setUser({user: tokenData}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const shouldShowCreateRmaButton = user.company_id && user.company_id !== 5  && !location.pathname.split('/').includes('labour-reimbursement-form');
    return <Box m={3}>
        <Box display="flex" flexDirection={matches ? 'column' : 'row'}
             alignItems="center">
            <Header/>
            {matches && <br/>}
            <Box flex={1}/>
            <LabourReimbursementLink/>
            <Box ml={1}/>
            {shouldShowCreateRmaButton && <CreateRMA/>}
            {matches && <br/>}
        </Box>
        <Outlet/>
    </Box>;
}