import CardView from "../CardView";
import {useTranslation} from "react-i18next";
import {Grid, TextField} from "@mui/material";
import {
    updateInstaller_contact_email,
    updateInstaller_contact_name,
    updateInstaller_contact_phone
} from "./formReducer";
import {useRef} from "react";

export default function InstallerDetails({companyName, name, email, phone, dispatch, errors}) {
    const {t} = useTranslation();
    const emailRef = useRef(null);
    const nameRef = useRef(null);
    const phoneRef = useRef(null);
    function focusErrorField() {
        if(window.isFocused)return;
        if(errors['installer_contact_email']){
            emailRef.current.focus();
            window.isFocused = true;
        }else if(errors['installer_contact_name']){
            nameRef.current.focus();
            window.isFocused = true;
        }else if(errors['installer_contact_phone']){
            phoneRef.current.focus();
            window.isFocused = true;
        }
    }
    focusErrorField();
    return <CardView title={t('Installer Details')}>
        <Grid container spacing={2}>
            <Grid md={6} xs={12} item>
                <TextField disabled variant="standard" value={companyName || ''} fullWidth name="installerCompany" label={t('Installer Company')} />
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField variant="standard" value={email || ''}
                           inputRef={emailRef}
                           error={!!errors['installer_contact_email']}
                           helperText={errors['installer_contact_email']}
                           onChange={e => dispatch(updateInstaller_contact_email(e.target.value))} fullWidth name="installerEmail" label={t('Email')} required/>
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField variant="standard" value={name || ''}
                           inputRef={nameRef}
                           error={!!errors['installer_contact_name']}
                           helperText={errors['installer_contact_name']}
                           onChange={e => dispatch(updateInstaller_contact_name(e.target.value))} fullWidth name="installerName" label={t('Contact Name')} required/>
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField variant="standard" value={phone || ''}
                           inputRef={phoneRef}
                           error={!!errors['installer_contact_phone']}
                           helperText={errors['installer_contact_phone']}
                           onChange={e => dispatch(updateInstaller_contact_phone(e.target.value))} fullWidth name="installerPhone" label={t('Phone')} required/>
            </Grid>
        </Grid>
    </CardView>
}