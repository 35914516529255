import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {DropdownFilter} from "./DropdownFilter";
import {DateFilter} from "./DateFilter";
import {TextFilter} from "./TextFilter";

export function Filters({columns, cellStyle, filters, setFilters, disabledFilters}) {
    const onFilter = (key, filter) => {
        if (!filter || (filter.length === 1 && filter[0] === '')) {
            delete filters[key]
            setFilters({...filters});
            return;
        }
        const validFilters = {};
        Object.keys(filters).forEach(f => {
            if (filters[f] && filters[f].length > 0) {
                validFilters[f] = filters[f];
            }
        })
        setFilters({...validFilters, [key]: filter});
    }
    return <TableRow>
        {columns.map(column => <TableCell key={column.key} sx={cellStyle} variant="head">
            {column.type === 'string' && column.filters ?
                <DropdownFilter onFilter={(filter) => onFilter(column.key, filter)}
                                disabled={disabledFilters?.includes(column.key)}
                                options={column.filters}/> : null}
            {column.type === 'string' && !column.filters ?
                <TextFilter onFilter={(filter) => onFilter(column.key, filter)} disabled={disabledFilters?.includes(column.key)}/> : null}
            {column.type === 'date' ? <DateFilter onFilter={(filter) => onFilter(column.key, filter)} disabled={disabledFilters?.includes(column.key)}/> : null}
        </TableCell>)}
    </TableRow>
}
