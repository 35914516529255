import {Box, Typography, useMediaQuery} from "@mui/material";
import {WarningFilled} from "@enphase/designsystems/Icons";
import {useTheme} from "@mui/styles";
import {Trans} from "react-i18next";

export default function WarningMessage() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return <Box display="flex" my={2} flexDirection={matches ? 'column': 'row'} borderRadius={theme.shape.borderRadius/4} style={{backgroundColor: '#f4f4f4'}}>
        <Box pl={2} minWidth={matches ? 10: 50} justifyContent="start" display="flex" alignItems="center"><WarningFilled fontSize={50}/></Box>
        <Typography gutterBottom m={2} variant="body2">
        <Trans>Support Request can take time to process and may require additional troubleshooting before the issue can be
            resolved. Feel free to contact Enphase Customer Support for more information.</Trans>
    </Typography>
    </Box>
}