import CardView from "./CardView";
import {MdLocalShipping} from "react-icons/md";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import {sanitise} from "../services/helper";
import ReturnDisposition from "./ReturnDisposition";

export default function ShipmentAddressView({title, address, isReturn}) {
    const {t} = useTranslation();
    const headerAction = isReturn ? <ReturnDisposition/>: null;
    return <CardView headerIcon={<MdLocalShipping/>} title={t(title)} action={headerAction}>
        {address && <Typography data-testid={`shipping-address-${title}`} variant="body2">
            {sanitise(address.company_name)}
                <br/> {sanitise(address.street)}
                <br/> {`${sanitise(address.city)} ${sanitise(address.state)} ${sanitise(address.zip_code)} ${sanitise(address.country)}`}
        </Typography>}
    </CardView>;
}