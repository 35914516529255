import ShowBreadcrumbs from "../components/BreadCrumbView";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Box, Button, Card, CardContent, CardHeader, Grid, LinearProgress, Typography} from "@mui/material";
import Status from "../components/LabourReimbursement/Status";
import RMADetails from "../components/LabourReimbursement/RMADetails";
import PaymentDetails from "../components/LabourReimbursement/PaymentDetails";
import Documents from "../components/LabourReimbursement/Documents";
import FAQ from "../components/LabourReimbursement/FAQ";
import React, {useEffect, useState} from "react";
import labourReimbursementService from "../services/labourReimbursementService";
import {toast} from "react-toastify";

export default function TrackLabourReimbursement() {
    const {t} = useTranslation();
    const {rmaNumber} = useParams();
    const [reimbursement, setReimbursement] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        async function initialise() {
            if (!rmaNumber) {
                return;
            }
            try {
                setLoading(true);
                setError('');
                const feature = await labourReimbursementService.checkLabourReimbursementEnabled();
                if (!feature.enabled) {
                    navigate(`/rma-details/${rmaNumber}`);
                }
                const eligibilityResponse = await labourReimbursementService.checkEligibility(rmaNumber);
                if (!eligibilityResponse?.data?.eligible) {
                    navigate(`/rma-details/${rmaNumber}`);
                }
                labourReimbursementService.loadReimbursement(rmaNumber, eligibilityResponse?.data?.labour_reimbursement_id)
                    .then(data => setReimbursement({...data, id: eligibilityResponse?.data?.labour_reimbursement_id}))
                    .catch(err => setError(t('Failed to fetch reimbursement details.')))
                    .finally(() => setLoading(false));
            } catch (e) {
                setLoading(false);
                setError(t('Failed to fetch reimbursement details.'));
                toast.error(t('Failed to fetch reimbursement details.'));
            }
        }

        initialise();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rmaNumber]);
    function handleDownload(documentName){
        labourReimbursementService.downloadDocument(rmaNumber, reimbursement.id, documentName);
    }

    function openSupportPage() {
        window.open('http://support.enphase.com/', '_blank');
    }
    if(isLoading){
        return <LinearProgress variant="indeterminate"/>;
    }
    if(error){
        return <Box maxWidth="600px" marginX="auto" mt={20}>
            <Alert severity="error" action={<Button onClick={() => navigate('/')}><Trans>Back to home</Trans></Button>}>{error}</Alert>
        </Box>;
    }
    return <>
        <ShowBreadcrumbs links={[{url: `/rma-details/${rmaNumber}`, text: `RMA - ${rmaNumber}`}]}
                         current={t('Track Reimbursement Request')}/>
        <Card sx={{mt: 1}} variant="outlined">
            <CardHeader
                title={<Typography variant={"subtitle1"} sx={{fontWeight: 600}}>
                    <Trans>Track Reimbursement Request</Trans>
                </Typography>}
                action={<Button variant="outlined" onClick={openSupportPage}>
                        <Typography><Trans>Contact Support</Trans></Typography>
                    </Button>}
                disableTypography/>
            <CardContent>
                <Status status={reimbursement?.request_status}/>
                <Box mt={2}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <RMADetails systemInfo={reimbursement?.system_info}
                                    address={reimbursement?.address}
                                    rmaNumber={rmaNumber}
                                    serialNumbers={reimbursement?.serial_numbers}/>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'stretch'}}>
                        <PaymentDetails isCheckPayment={reimbursement?.cheque_payment}
                                        amount={reimbursement?.amount}
                                        referenceNumber={reimbursement?.payment_reference_number}
                                        paymentCurrency={reimbursement?.payment_currency}
                                        paymentMode={reimbursement?.payment_mode}
                                        isApproved={!!reimbursement?.request_status?.approved?.completedDate}
                                        mailingAddress={reimbursement?.mailing_address}/>
                        {reimbursement?.documents_required?.length > 0 ? <><Box mt={2}/>
                        <Documents documents={reimbursement?.documents_required}
                            handleDownloadDocument={handleDownload}/></>: null}
                        {/*<Box mt={2} display="flex">*/}
                        {/*    <Card sx={{flex: 1}}>*/}
                        {/*        <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>*/}
                        {/*            <Box><Document fontSize={72}/></Box>*/}
                        {/*            <Button><Trans>View Application</Trans></Button>*/}
                        {/*        </CardContent>*/}
                        {/*    </Card>*/}
                        {/*    <Box mr={2}/>*/}
                        {/*    <Card sx={{flex: 1}}>*/}
                        {/*        <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>*/}
                        {/*            <Box><Download fontSize={72}/></Box>*/}
                        {/*            <Button><Trans>Download Invoice</Trans></Button>*/}
                        {/*        </CardContent>*/}
                        {/*    </Card>*/}
                        {/*</Box>*/}

                    </Grid>


                </Grid>
                <Box mt={2}><FAQ/></Box>
            </CardContent>
        </Card>
    </>
}