import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import CardView from "../CardView";
import {useTheme} from "@mui/styles";

export default function RMADetails({rmaNumber, serialNumbers, systemInfo, address}) {
    const {t} = useTranslation();
    return <CardView title={t('RMA Details')} stretch height={'100%'}>

        <Label text={t('RMAs qualified for reimbursement')}/>
        <Typography>{rmaNumber}</Typography>
        <Box mt={5}/>
        <Label text={t('Associated Serial Numbers')}/>
        <Typography sx={{overflowWrap: 'break-word'}}>{serialNumbers?.join(',')}</Typography>
        <Box mt={5}/>
        <Label text={t('System Information')}/>
        <Typography>{systemInfo?.name}</Typography>
        <Typography>{systemInfo?.address?.address1} {systemInfo?.address?.address2} {systemInfo?.address?.city}</Typography>
        <Typography>{systemInfo?.address?.zip} {systemInfo?.address?.state} {systemInfo?.address?.country}</Typography>
        <Box mt={5}/>
        <Label text={t('Address')}/>
        <Typography>{address?.address1} {address?.address2} {address?.city}</Typography>
        <Typography>{address?.zip} {address?.state} {address?.country}</Typography>


    </CardView>
}


export function Label({text}) {
    const theme = useTheme();
    return <Typography variant="subtitle2" fontStyle={{color: theme.palette.grey[500]}}>{text}</Typography>
}