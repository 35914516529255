import {Trans} from "react-i18next";
import {Button, Typography} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {useState} from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {logGAEvent} from "../../analytics/ga4";

export default function CannotTrackDialog() {
    const [open, setOpen] = useState(false);
    const onClick = () =>{
        setOpen(true);
        logGAEvent({
            action: 'lre_not_trackable_dialog',
            category: 'rma_details_page',
        });
    }
    return <>
        <Button role="button" variant="outlined" onClick={onClick}>
            <Trans><Trans>Track Labor Reimbursement</Trans></Trans>
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogContent>
                <Typography>
                    <Trans>You can only track your labor claims requested on or after 1st May 2022</Trans>
                </Typography>
            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
                <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
                    <Trans>Ok</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    </>
}