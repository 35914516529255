import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {makeStyles} from "@mui/styles";
import {Link} from "@mui/material";
import {Trans} from "react-i18next";

const useStyles = makeStyles({
    head: {
        backgroundColor: '#f4f4f4'
    }
});

export default function TableView({columns, data}) {
    const classes = useStyles();
    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table" size="small">
                <TableHead className={classes.head}>
                    <TableRow>
                        {columns.map(column => <TableCell key={column.name}><Trans>{column.text}</Trans></TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {row.defectiveSerialNumber}
                            </TableCell>
                            <TableCell>{row.defectiveProduct}</TableCell>
                            <TableCell>{row.replacement}</TableCell>
                            <TableCell>{row.actualShipDate}</TableCell>
                            <TableCell>{row.trackingLink ? <Link target="_blank" rel="noopener noreferrer"
                                             href={row.trackingLink}>{row.trackingNumber}</Link>: row.trackingNumber}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
