import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {MdExpandMore, MdInfo} from "react-icons/md";
import {Trans, useTranslation} from "react-i18next";
import DestinationInfo from "./DestinationInfo";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import returnDispositionService from "../../../services/returnDispositionService";
import {toast} from "react-toastify";
import {logGAEvent} from "../../../analytics/ga4";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const config = {
    freeway: {
        analytics: { action: 'return_disposition_freeway_submit', label: 'rma', category: 'return_disposition' },
        successMessage: 'Freeway has been notified successfully',
        failureMessage: 'Failed to notify Freeway',
        caption: 'freeway_caption',
    },
    ch_robinson: {
        analytics: { action: 'return_disposition_chr_submit', label: 'rma', category: 'return_disposition' },
        successMessage: 'CH Robinson has been notified successfully',
        failureMessage: 'Failed to notify CH Robinson',
        caption: 'ch_robinson_caption',
    },
}
export const ChRobinsonSection = ({rmaNumber, returnLabelTypes}) => {
    const {register, handleSubmit, formState: {errors}} = useForm();

    const {t} = useTranslation();
    const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const vendor = returnLabelTypes.includes('freeway')
        ? 'freeway'
        : returnLabelTypes.includes('ch_robinson')
            ? 'ch_robinson'
            : new Error('unsupported vendor')
    useEffect(() => {
        setLoading(true);
        returnDispositionService.loadShippingDetails(rmaNumber)
            .then(data => setDetails(data))
            .catch(error => toast.error(t('Error occurred while loading form.')))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rmaNumber]);
    const onSubmit = data => {
        logGAEvent({
            action: 'return_disposition_chr_submit',
            category: 'rma',
            label: 'return_disposition',
            value: rmaNumber
        });
        const payload = {
            name: data.name,
            phone: data.phone,
            email: data.email,
        }
        payload.commodities = Object.entries(data.commodities).map(
            ([type, value]) => ({
                product_type: type,
                pickup_information: {
                    company_name: value.pickup_information.companyName,
                    from_address: value.pickup_information.address,
                    from_city: value.pickup_information.city,
                    from_state: value.pickup_information.state,
                    from_zip_code: value.pickup_information.zipCode,
                    warehouse_phone: value.pickup_information.warehousePhone,
                },
                freight_details: {
                    pallet_count: value.freight_details.palletCount,
                    actual_weight: value.freight_details.actualWeight,
                    ready_date: value.freight_details.readyDate,
                    dimensions: value.freight_details.dimensions,
                    special_notes: value.freight_details.specialNotes,
                },
            })
        )
        setSubmitting(true);
        returnDispositionService.submitStorageDetails(rmaNumber, payload)
            .then(res => toast.success(t(config[vendor].successMessage)))
            .catch(err => toast.error(t(config[vendor].failureMessage)))
            .finally(() => setSubmitting(false));
    };
    if (loading) {
        return <LinearProgress variant="indeterminate"/>
    }
    return (<Box mt={2}>
            <Card>
                <Accordion defaultExpanded>
                    <AccordionSummary sx={{backgroundColor: '#f7f7f7'}}
                                      expandIcon={<MdExpandMore size={30}/>}>
                        <Box>
                            <Typography variant="h6"><Trans>RMA Return form</Trans></Typography>
                            <Typography variant="caption">
                                <Trans>rma_return_form_caption</Trans>
                                <InfoDialog vendor={vendor}/>
                            </Typography>
                        </Box>
                        <Divider/>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12} my={2}>
                                    <Typography variant="subtitle1"><Trans>CONTACT INFORMATION</Trans></Typography>
                                    <Typography variant="caption"><Trans>{`${vendor}_caption`}</Trans></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}><TextField variant="standard" fullWidth required
                                                                     InputProps={{...register("name", {required: t('Name is required')})}}
                                                                     error={!!errors.name}
                                                                     helperText={errors?.name?.message}
                                                                     label={t('Name')}/></Grid>
                                <Grid item xs={12} md={6}><TextField variant="standard" fullWidth required
                                                                     InputProps={{...register("phone", {required: t('Phone is required')})}}
                                                                     error={!!errors.phone}
                                                                     helperText={errors?.phone?.message}
                                                                     label={t('Phone')}/></Grid>
                                <Grid item xs={12} md={6}><TextField variant="standard" fullWidth required
                                                                     InputProps={{
                                                                         ...register("email", {
                                                                             required: t('Email is required'),
                                                                             pattern: {
                                                                                 value: EMAIL_REGEX,
                                                                                 message: t('Please enter a valid email')
                                                                             }
                                                                         })
                                                                     }}
                                                                     error={!!errors.email}
                                                                     helperText={errors?.email?.message}
                                                                     label={t('Email')}/></Grid>
                            </Grid>
                            {details?.commodities?.map((commodity) => (
                                <Accordion key={commodity.product_type} defaultExpanded>
                                    <AccordionSummary expandIcon={<MdExpandMore size={30} />}>
                                        <Trans>{commodity.product_type}</Trans>
                                    </AccordionSummary>
                                    <AccordionDetails data-testid={commodity.product_type}>
                                        <Grid container spacing={2}>
                                <Grid item xs={12} my={2}>
                                    <Typography variant="subtitle1"><Trans>FREIGHT DETAILS</Trans></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextField minRows={3} multiline fullWidth disabled
                                                       value={commodity?.description || ''}
                                                       label={t('Commodity Description')}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField size="small" variant="standard" fullWidth
                                                       InputProps={{
                                                           ...register(
                                                               `commodities.${commodity.product_type}.freight_details.palletCount`
                                                           ),
                                                       }}
                                                       label={t('Pallet Count')}/>
                                            <TextField size="small" sx={{mt: 1}} variant="standard" fullWidth
                                                       InputProps={{
                                                           ...register(
                                                               `commodities.${commodity.product_type}.freight_details.actualWeight`
                                                           ),
                                                       }}
                                                       label={t('Actual Weight')}/>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField variant="standard" fullWidth label={t('Ready Date')}
                                               InputProps={{
                                                   ...register(
                                                       `commodities.${commodity.product_type}.freight_details.readyDate`
                                                   ),
                                               }}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField variant="standard" fullWidth label={t('Dimensions (LxWxH)')}
                                               InputProps={{
                                                   ...register(
                                                       `commodities.${commodity.product_type}.freight_details.dimensions`
                                                   ),
                                               }}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField variant="standard" fullWidth label={t('Special Notes')}
                                               InputProps={{
                                                   ...register(
                                                       `commodities.${commodity.product_type}.freight_details.specialNotes`
                                                   ),
                                               }}/>
                                </Grid>
                                <Grid item xs={12} my={2}>
                                    <Typography variant="subtitle1"><Trans>PICKUP INFORMATION</Trans></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField variant="standard" fullWidth required label={t('Company Name')}
                                               InputProps={{...register( `commodities.${commodity.product_type}.pickup_information.companyName`,
                                                       {required: t('Company Name is required')})}}
                                               error={!!(errors?.commodities && errors?.commodities[commodity.product_type]?.pickup_information?.companyName?.message)}
                                               helperText={errors?.commodities ? errors?.commodities[commodity.product_type]?.pickup_information?.companyName?.message: ''}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField variant="standard" fullWidth required label={t('Address')}
                                               InputProps={{...register(`commodities.${commodity.product_type}.pickup_information.address`,
                                                       {required: t('Address is required')})}}
                                               error={!!(errors?.commodities && errors?.commodities[commodity.product_type]?.pickup_information?.address?.message)}
                                               helperText={errors?.commodities ? errors?.commodities[commodity.product_type]?.pickup_information?.address?.message: ''}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField variant="standard" fullWidth required label={t('City')}
                                               InputProps={{...register( `commodities.${commodity.product_type}.pickup_information.city`,
                                                       {required: t('City is required')})}}
                                               error={!!(errors?.commodities && errors?.commodities[commodity.product_type]?.pickup_information?.city?.message)}
                                               helperText={errors?.commodities ? errors?.commodities[commodity.product_type]?.pickup_information?.city?.message: ''}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField variant="standard" fullWidth required label={t('State')}
                                               InputProps={{...register(`commodities.${commodity.product_type}.pickup_information.state`,
                                                       {required: t('State is required')})}}
                                               error={!!(errors?.commodities && errors?.commodities[commodity.product_type]?.pickup_information?.state?.message)}
                                               helperText={errors?.commodities ? errors?.commodities[commodity.product_type]?.pickup_information?.state?.message: ''}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField variant="standard" fullWidth required label={t('Zip Code')}
                                               InputProps={{...register(`commodities.${commodity.product_type}.pickup_information.zipCode`,
                                                       {required: t('Zip Code is required')})}}
                                               error={!!(errors?.commodities && errors?.commodities[commodity.product_type]?.pickup_information?.zipCode?.message)}
                                               helperText={errors?.commodities ? errors?.commodities[commodity.product_type]?.pickup_information?.zipCode?.message: ''}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField variant="standard" fullWidth
                                               InputProps={{...register(`commodities.${commodity.product_type}.pickup_information.warehousePhone`)}}
                                               label={t('Warehouse Phone (If applicable)')}/>
                                </Grid>
                                <DestinationInfo
                                    rmaNumber={rmaNumber}
                                    destinationAddress={commodity?.destination_address}/>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>)
                            )}
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Divider/>
                                    <Box display="flex" mt={2}>
                                        <Button disabled={submitting} sx={{ml: 'auto'}} color="primary" type="submit" variant="contained"><Trans>Submit</Trans></Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </Box>);
};
const InfoDialog = ({vendor}) => {
    return <Tooltip title={<Box>
        <ol>
            <li><Trans>{`${vendor}_instruction_1`}</Trans></li>
            <li><Trans>{`${vendor}_instruction_2`}</Trans></li>
            <li><Trans>{`${vendor}_instruction_3`}</Trans></li>
            <li><Trans>{`${vendor}_instruction_4`}</Trans></li>
        </ol>
    </Box>} arrow>
        <IconButton size="small" aria-label='info'><MdInfo/></IconButton>
    </Tooltip>
}
export default ChRobinsonSection;