import React, {useEffect, useState} from 'react';
import {Close} from '@enphase/designsystems/Icons';
import './UploadFile.css';
import {useTranslation} from "react-i18next";

const UploadFile = (props) => {
    const {
        id, name, onChange, className, value, text, required, helperText, buttonname, error
    } = props;
    const [nameOfField, setNameOfField] = useState(value || text || 'No file selected');
    const [filesObj, setFilesObj] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        setNameOfField(value || text || 'No file selected');
    }, [value, text]);

    function onInputUploadField(e) {
        let obj = e.target.files[0];
        if (obj) {
            setFilesObj(obj);
            setNameOfField(obj.name);
            onChange(obj);
        } else {
            setNameOfField(text || 'No file selected');
        }
    }

    function removeSingleFile() {
        if(document.getElementById(id).value){
            document.getElementById(id).value = null;
        }
        setFilesObj([]);
        setNameOfField(text || 'No file selected');
        onChange && onChange([]);
    }

    return (<>
            <div className={className}>
                {nameOfField !== text && <span className="ES_Upload_File_Selected_Label">{text} <span className="ES_Upload_File_Required_Asterisk">{required? '*': ''}</span></span>}
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className="ES_Upload_File">
                        <span
                            style={nameOfField === 'No file selected' ? {} : {opacity: '1'}}
                            className="ES_Upload_File_Chosen"
                        >
                            {nameOfField} <span className="ES_Upload_File_Required_Asterisk">{required && nameOfField === text? '*': ''}</span>
                        </span>
                        <label className="ES_Upload_Custom_Button_Input_FIle" htmlFor={id}>
                            {buttonname || t('Choose File')}
                        </label>

                        <input
                            name={name}
                            id={id}
                            type="file"
                            accept="application/pdf"
                            onChange={(e) => onInputUploadField(e)}
                            hidden
                        />
                    </div>
                    {(filesObj && filesObj.name) || value ? (<div
                            className="ES_DropZone_Cross"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                padding: '0.5em',
                                marginLeft: '1em'
                            }}
                            onClick={() => removeSingleFile()}
                        >
                            <Close/>
                        </div>) : ('')}
                </div>
                <div className="ES_Upload_File_Error">
                    {error}
                </div>
                <div className="ES_Upload_Description">
                    {helperText}
                </div>
            </div>
        </>);
};

export default UploadFile;
