import {Box, ButtonBase, Chip, CircularProgress, InputAdornment, Popover, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useRef, useState} from "react";
import {useSiteSearch} from "../useSiteSearch";

export default function SiteSearch({selectedSite, setSelectedSite}) {
    const [open, setOpen] = useState(false);
    const anchorEl = useRef();
    const {t} = useTranslation();
    const {inProgress, error, site,siteId,setSiteId, searchSite} = useSiteSearch();
    const handleSelect = (site) => {
        setOpen(false);
        setSiteId('');
        setSelectedSite(site);
    }
    const showHelperText = () => {
        if(!siteId || inProgress){
            return '';
        }else if(error){
            return t(error);
        }else if(site?.result?.length === 0){
            return t('No Results Found');
        }
    }
    return <div>
        <Box display="flex" alignItems="center">
            <TextField label={t('Enter Site Id')} fullWidth ref={anchorEl}
                       InputProps={{
                startAdornment: (<InputAdornment position={"start"}>
                    {selectedSite?.id && <Chip label={selectedSite.name} onDelete={() => setSelectedSite({})}/>}
                </InputAdornment>),
                endAdornment: (
                    <InputAdornment position='end'>
                        {inProgress && <CircularProgress variant="indeterminate"/>}
                    </InputAdornment>
                )
            }} value={siteId} onChange={e => searchSite(e.target.value, () => setOpen(true))}
                       disabled={!!(selectedSite?.id)}
                       error={!!error}
                       helperText={showHelperText()}
            />
        </Box>
        {site?.result?.length > 0 && <Popover
            id="site-results"
            open={open}
            anchorEl={anchorEl.current}
            onClose={() => setOpen(false)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <ButtonBase onClick={() => handleSelect(site.result[0])}
                        style={{width: anchorEl.current.offsetWidth, justifyContent: 'start'}}
                        sx={{p: 2}}>{site.result[0].name} - {site.result[0].id}</ButtonBase>
        </Popover>}
    </div>
}