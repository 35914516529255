import {Typography} from "@mui/material";
import {Trans} from "react-i18next";

export default function SystemInformationView({systemInfo}){
    const address = systemInfo?.address;
    return <div>
        <Typography variant="subtitle2" mb={1} sx={{fontWeight: 700}}><Trans>System Information</Trans></Typography>
        <Typography>{systemInfo?.name}</Typography>
        <Typography>{address?.address1} {address?.address2} {address?.city}</Typography>
        <Typography>{address?.zip} {address?.state} {address?.country}</Typography>
    </div>
}