import CardView from "../CardView";
import {Trans, useTranslation} from "react-i18next";
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import {MdExpandMore} from "react-icons/md";

export default function FAQ() {
    const {t} = useTranslation();
    return <CardView title={t('FAQ')}>
        {[1,2,3].map(q => <Accordion key={q}>
            <AccordionSummary
                expandIcon={<MdExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography><Trans>{`question_${q}`}</Trans></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography sx={{color: 'rgba(0,0,0,.5)'}}><Trans>{`answer_${q}`}</Trans></Typography>
            </AccordionDetails>
        </Accordion>)}
        <Accordion>
            <AccordionSummary
                expandIcon={<MdExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography><Trans>{`question_4`}</Trans></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="div" sx={{color: 'rgba(0,0,0,.5)'}}>
                    <Box mb={1}><Trans>The most common reasons for the delay in payment are:</Trans></Box>
                    <ol>
                        <li><Trans>The bank information is either incorrect or not complete.</Trans></li>
                        <li><Trans>The address information is either not complete or incorrect.</Trans></li>
                        <li><Trans>Relevant forms have not been submitted (IRF, W9, W8BEN – E etc)</Trans></li>
                    </ol>
                    <Box><Trans>Note: To make sure your request is processed within time please provide all the information correctly as asked.</Trans></Box>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<MdExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography><Trans>{`For US installers, is it necessary to return the defective parts in order to get the reimbursement amount?`}</Trans></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography sx={{color: 'rgba(0,0,0,.5)'}}><Trans>{`Yes, Installers in US need to return at least 50% of the defective products mentioned in the RMA back to Enphase in order to get the reimbursement request approved.`}</Trans></Typography>
            </AccordionDetails>
        </Accordion>
    </CardView>;
}