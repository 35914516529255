import { getValue } from './localStorage';

const development = {
    sfdc_url: 'https://sfms-stage.qa-enphaseenergy.com',
    // sfdc_url: 'http://localhost:8080',
    service_manager_url: 'https://service-qa2.enphaseenergy.com',
    labour_reimbursement_url: 'https://lrms.qa-enphaseenergy.com',
    rd_url: 'https://returnlabel-rel.qa-enphaseenergy.com'
    // rd_url: 'http://localhost:3001'
};
//for unit test
const test = {
    sfdc_url: 'sfms-endpoint',
    service_manager_url: 'servicemanager-endpoint',
    labour_reimbursement_url: 'labour-reimbursement-endpoint',
    rd_url: 'rd-endpoint'
};

const qa2 = {
    sfdc_url: 'https://sfms-stage.qa-enphaseenergy.com',
    service_manager_url: 'https://service-qa2.enphaseenergy.com',
    labour_reimbursement_url: 'https://lrms.qa-enphaseenergy.com',
    rd_url: 'https://returnlabel-qa.enphaseenergy.com',
    google_analytics_id: 'G-VD88FBR93Z'
};

const qa4 = {
    sfdc_url: 'https://sfms-stage.qa-enphaseenergy.com',
    service_manager_url: 'https://servicemanager-dev.qa-enphaseenergy.com',
    labour_reimbursement_url: 'https://lrms.qa-enphaseenergy.com',
    rd_url: 'https://returnlabel-qa.enphaseenergy.com'

};
const dev = {
    sfdc_url: 'https://sfms-dev.qa-enphaseenergy.com',
    service_manager_url: 'https://service-qa2.enphaseenergy.com',
    labour_reimbursement_url: 'https://lrms.qa-enphaseenergy.com',
    rd_url: 'https://returnlabel-qa.enphaseenergy.com'
};

const stg = {
    sfdc_url: 'https://sfms-stage.qa-enphaseenergy.com',
    service_manager_url: 'https://service-qa2.enphaseenergy.com',
    labour_reimbursement_url: 'https://lrms.qa-enphaseenergy.com',
    rd_url: 'https://returnlabel-qa.enphaseenergy.com'
}

const rel = {
    sfdc_url: 'https://sfms-rel.qa-enphaseenergy.com',
    service_manager_url: 'https://service-rel.qa-enphaseenergy.com',
    labour_reimbursement_url: 'https://lrms-rel.enphaseenergy.com',
    rd_url: 'https://returnlabel-rel.qa-enphaseenergy.com'
}


const preprod = {
    sfdc_url: 'https://sfms-preprod.enphaseenergy.com',
    service_manager_url: 'https://service-qa2.enphaseenergy.com',
    labour_reimbursement_url: 'https://lrms-preprod.enphaseenergy.com',
    rd_url: 'https://returnlabel-qa.enphaseenergy.com'
};

const production = {
    sfdc_url: 'https://sfms.enphaseenergy.com',
    service_manager_url:'https://service.enphaseenergy.com',
    labour_reimbursement_url: 'https://lrms.enphaseenergy.com',
    rd_url: 'https://returnlabel.enphaseenergy.com',
    google_analytics_id: 'G-EZ6Y4LV9V7'
};

const DEFAULT = {};

const CONFIG = {
    development,
    production,
    preprod,
    test,
    qa2,
    qa4,
    dev,
    stg,
    rel
};

const getConfig = env => {
    let hostname;
    if(process.env.TEST_NODE_ENV === 'test'){
        env = 'test';
    }else if (typeof window !== 'undefined') {
        hostname = window.location.hostname;
        if (hostname.search('enlighten.enphaseenergy') >= 0 || hostname.search('rma-dashboard.enphaseenergy.com') >= 0) {
            env = 'production';
        } else if (hostname.search('enlighten-preprod.enphaseenergy') >= 0 || hostname.search('rma-dashboard-preprod.enphaseenergy.com') >= 0) {
            env = 'preprod';
        } else if (hostname.search('qa2.enphaseenergy') >= 0 || hostname.search('rma-dashboard-stg.qa-enphaseenergy') >= 0) {
            env = 'qa2';
        } else if (hostname.search('enlighten-qa4') >= 0 || hostname.search('rma-dashboard-dev.qa-enphaseenergy') >= 0) {
            env = 'qa4';
        } else if (hostname.search('enlighten-dev') >= 0 || hostname.search('rma-dashboard-dev.qa-enphaseenergy') >= 0) {
            env = 'dev';
        } else if (hostname.search('enlighten-stg') >= 0 || hostname.search('rma-dashboard-stg.qa-enphaseenergy') >= 0) {
            env = 'stg';
        } else if (hostname.search('enlighten-rel') >= 0 || hostname.search('rma-dashboard-rel.qa-enphaseenergy') >= 0) {
            env = 'rel';
        } else if (window.location.port === '7000') {
            env = getValue('development_env') || 'development';
        } else {
            env = 'development';
        }
    }
    if(env !== 'test') console.log('App running environment is:', env);
    const envConfig = CONFIG[env];
    if (envConfig) {
        return {...DEFAULT, ...envConfig};
    }
    throw new Error('Unknown environment : ' + env);
};

export default getConfig;
