const places =[
    {
        "country_code": "US",
        "region_code": "MS",
        "region_name": "Mississippi"
    },
    {
        "country_code": "US",
        "region_code": "MO",
        "region_name": "Missouri"
    },
    {
        "country_code": "US",
        "region_code": "MT",
        "region_name": "Montana"
    },
    {
        "country_code": "US",
        "region_code": "NE",
        "region_name": "Nebraska"
    },
    {
        "country_code": "US",
        "region_code": "NV",
        "region_name": "Nevada"
    },
    {
        "country_code": "US",
        "region_code": "NH",
        "region_name": "New Hampshire"
    },
    {
        "country_code": "US",
        "region_code": "NJ",
        "region_name": "New Jersey"
    },
    {
        "country_code": "US",
        "region_code": "NM",
        "region_name": "New Mexico"
    },
    {
        "country_code": "US",
        "region_code": "NY",
        "region_name": "New York"
    },
    {
        "country_code": "US",
        "region_code": "NC",
        "region_name": "North Carolina"
    },
    {
        "country_code": "US",
        "region_code": "ND",
        "region_name": "North Dakota"
    },
    {
        "country_code": "US",
        "region_code": "OH",
        "region_name": "Ohio"
    },
    {
        "country_code": "US",
        "region_code": "OK",
        "region_name": "Oklahoma"
    },
    {
        "country_code": "US",
        "region_code": "OR",
        "region_name": "Oregon"
    },
    {
        "country_code": "US",
        "region_code": "PA",
        "region_name": "Pennsylvania"
    },
    {
        "country_code": "US",
        "region_code": "RI",
        "region_name": "Rhode Island"
    },
    {
        "country_code": "US",
        "region_code": "SC",
        "region_name": "South Carolina"
    },
    {
        "country_code": "US",
        "region_code": "SD",
        "region_name": "South Dakota"
    },
    {
        "country_code": "US",
        "region_code": "TN",
        "region_name": "Tennessee"
    },
    {
        "country_code": "US",
        "region_code": "TX",
        "region_name": "Texas"
    },
    {
        "country_code": "US",
        "region_code": "UT",
        "region_name": "Utah"
    },
    {
        "country_code": "US",
        "region_code": "VT",
        "region_name": "Vermont"
    },
    {
        "country_code": "US",
        "region_code": "VA",
        "region_name": "Virginia"
    },
    {
        "country_code": "US",
        "region_code": "WA",
        "region_name": "Washington"
    },
    {
        "country_code": "US",
        "region_code": "WV",
        "region_name": "West Virginia"
    },
    {
        "country_code": "US",
        "region_code": "WI",
        "region_name": "Wisconsin"
    },
    {
        "country_code": "US",
        "region_code": "WY",
        "region_name": "Wyoming"
    },
    {
        "country_code": "US",
        "region_code": "AS",
        "region_name": "American Samoa"
    },
    {
        "country_code": "US",
        "region_code": "AA",
        "region_name": "Armed Forces Americas (except Canada)"
    },
    {
        "country_code": "US",
        "region_code": "AE",
        "region_name": "Armed Forces Europe"
    },
    {
        "country_code": "US",
        "region_code": "AP",
        "region_name": "Armed Forces Pacific"
    },
    {
        "country_code": "US",
        "region_code": "PR",
        "region_name": "Puerto Rico"
    },
    {
        "country_code": "US",
        "region_code": "VI",
        "region_name": "Virgin Islands"
    },
    {
        "country_code": "US",
        "region_code": "AL",
        "region_name": "Alabama"
    },
    {
        "country_code": "US",
        "region_code": "AK",
        "region_name": "Alaska"
    },
    {
        "country_code": "US",
        "region_code": "AZ",
        "region_name": "Arizona"
    },
    {
        "country_code": "US",
        "region_code": "AR",
        "region_name": "Arkansas"
    },
    {
        "country_code": "US",
        "region_code": "CA",
        "region_name": "California"
    },
    {
        "country_code": "US",
        "region_code": "CO",
        "region_name": "Colorado"
    },
    {
        "country_code": "US",
        "region_code": "CT",
        "region_name": "Connecticut"
    },
    {
        "country_code": "US",
        "region_code": "DE",
        "region_name": "Delaware"
    },
    {
        "country_code": "US",
        "region_code": "DC",
        "region_name": "District Of Columbia"
    },
    {
        "country_code": "US",
        "region_code": "FL",
        "region_name": "Florida"
    },
    {
        "country_code": "US",
        "region_code": "GA",
        "region_name": "Georgia"
    },
    {
        "country_code": "US",
        "region_code": "HI",
        "region_name": "Hawaii"
    },
    {
        "country_code": "US",
        "region_code": "ID",
        "region_name": "Idaho"
    },
    {
        "country_code": "US",
        "region_code": "IL",
        "region_name": "Illinois"
    },
    {
        "country_code": "US",
        "region_code": "IN",
        "region_name": "Indiana"
    },
    {
        "country_code": "US",
        "region_code": "IA",
        "region_name": "Iowa"
    },
    {
        "country_code": "US",
        "region_code": "KS",
        "region_name": "Kansas"
    },
    {
        "country_code": "US",
        "region_code": "KY",
        "region_name": "Kentucky"
    },
    {
        "country_code": "US",
        "region_code": "LA",
        "region_name": "Louisiana"
    },
    {
        "country_code": "US",
        "region_code": "ME",
        "region_name": "Maine"
    },
    {
        "country_code": "US",
        "region_code": "MD",
        "region_name": "Maryland"
    },
    {
        "country_code": "US",
        "region_code": "MA",
        "region_name": "Massachusetts"
    },
    {
        "country_code": "US",
        "region_code": "MI",
        "region_name": "Michigan"
    },
    {
        "country_code": "US",
        "region_code": "MN",
        "region_name": "Minnesota"
    },
    {
        "country_code": "UA",
        "region_code": "CR",
        "region_name": "Autonomous Republic of Crimea"
    },
    {
        "country_code": "UA",
        "region_code": "CK",
        "region_name": "Cherkashchyna"
    },
    {
        "country_code": "UA",
        "region_code": "CN",
        "region_name": "Chernihivshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "CV",
        "region_name": "Chernivechchyna"
    },
    {
        "country_code": "UA",
        "region_code": "DP",
        "region_name": "Dnipropetrovshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "DN",
        "region_name": "Donechchyna"
    },
    {
        "country_code": "UA",
        "region_code": "IF",
        "region_name": "Ivano-Frankivshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "KH",
        "region_name": "Kharkivshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "KS",
        "region_name": "Khersonshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "KM",
        "region_name": "Khmel'nychchyna"
    },
    {
        "country_code": "UA",
        "region_code": "KV",
        "region_name": "Kyivshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "KR",
        "region_name": "Kirovohradshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "LG",
        "region_name": "Luhanshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "LV",
        "region_name": "L'vivshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "MK",
        "region_name": "Mykolaivshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "OD",
        "region_name": "Odeshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "PL",
        "region_name": "Poltavshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "RV",
        "region_name": "Rivnenshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "SM",
        "region_name": "Sumshchyna"
    },
    {
        "country_code": "UA",
        "region_code": "TAS",
        "region_name": "Ternopil'shchyna"
    },
    {
        "country_code": "UA",
        "region_code": "VN",
        "region_name": "Vinnychchyna"
    },
    {
        "country_code": "UA",
        "region_code": "VOLYN",
        "region_name": "Volyn'"
    },
    {
        "country_code": "UA",
        "region_code": "ZK",
        "region_name": "Zakarpattia"
    },
    {
        "country_code": "UA",
        "region_code": "ZP",
        "region_name": "Zaporizhzhia"
    },
    {
        "country_code": "UA",
        "region_code": "ZT",
        "region_name": "Zhytomyrshchyna"
    },
    {
        "country_code": "NZ",
        "region_code": "AUK",
        "region_name": "Auckland"
    },
    {
        "country_code": "NZ",
        "region_code": "BOP",
        "region_name": "Bay of Plenty"
    },
    {
        "country_code": "NZ",
        "region_code": "CAN",
        "region_name": "Canterbury"
    },
    {
        "country_code": "NZ",
        "region_code": "HKB",
        "region_name": "Hawke's Bay"
    },
    {
        "country_code": "NZ",
        "region_code": "MWT",
        "region_name": "Manawatu-Wanganui"
    },
    {
        "country_code": "NZ",
        "region_code": "NTL",
        "region_name": "Northland"
    },
    {
        "country_code": "NZ",
        "region_code": "OTA",
        "region_name": "Otago"
    },
    {
        "country_code": "NZ",
        "region_code": "STL",
        "region_name": "Southland"
    },
    {
        "country_code": "NZ",
        "region_code": "TKI",
        "region_name": "Taranaki"
    },
    {
        "country_code": "NZ",
        "region_code": "WKO",
        "region_name": "Waikato"
    },
    {
        "country_code": "NZ",
        "region_code": "WGN",
        "region_name": "Wellington"
    },
    {
        "country_code": "NZ",
        "region_code": "WTC",
        "region_name": "West Coast"
    },
    {
        "country_code": "NZ",
        "region_code": "GIS",
        "region_name": "Gisborne District"
    },
    {
        "country_code": "NZ",
        "region_code": "MBH",
        "region_name": "Marlborough District"
    },
    {
        "country_code": "NZ",
        "region_code": "NSN",
        "region_name": "Nelson City"
    },
    {
        "country_code": "NZ",
        "region_code": "TAS",
        "region_name": "Tasman District"
    },
    {
        "country_code": "NZ",
        "region_code": "CIT",
        "region_name": "Chatham Islands Territory"
    },
    {
        "country_code": "NO",
        "region_code": "AK",
        "region_name": "Akershus"
    },
    {
        "country_code": "NO",
        "region_code": "AA",
        "region_name": "Aust-Agder"
    },
    {
        "country_code": "NO",
        "region_code": "BU",
        "region_name": "Buskerud"
    },
    {
        "country_code": "NO",
        "region_code": "FI",
        "region_name": "Finnmark"
    },
    {
        "country_code": "NO",
        "region_code": "HE",
        "region_name": "Hedmark"
    },
    {
        "country_code": "NO",
        "region_code": "HO",
        "region_name": "Hordaland"
    },
    {
        "country_code": "NO",
        "region_code": "MR",
        "region_name": "Møre og Romsdal"
    },
    {
        "country_code": "NO",
        "region_code": "NT",
        "region_name": "Nord-Trøndelag"
    },
    {
        "country_code": "NO",
        "region_code": "NO",
        "region_name": "Nordland"
    },
    {
        "country_code": "NO",
        "region_code": "OP",
        "region_name": "Oppland"
    },
    {
        "country_code": "NO",
        "region_code": "OS",
        "region_name": "Oslo"
    },
    {
        "country_code": "NO",
        "region_code": "RO",
        "region_name": "Rogaland"
    },
    {
        "country_code": "NO",
        "region_code": "SF",
        "region_name": "Sogn og Fjordane"
    },
    {
        "country_code": "NO",
        "region_code": "ST",
        "region_name": "Sør-Trøndelag"
    },
    {
        "country_code": "NO",
        "region_code": "TE",
        "region_name": "Telemark"
    },
    {
        "country_code": "NO",
        "region_code": "TR",
        "region_name": "Troms"
    },
    {
        "country_code": "NO",
        "region_code": "VA",
        "region_name": "Vest-Agder"
    },
    {
        "country_code": "NO",
        "region_code": "VF",
        "region_name": "Vestfold"
    },
    {
        "country_code": "NO",
        "region_code": "ØF",
        "region_name": "Østfold"
    },
    {
        "country_code": "NL",
        "region_code": "DR",
        "region_name": "Drenthe"
    },
    {
        "country_code": "NL",
        "region_code": "FL",
        "region_name": "Flevoland"
    },
    {
        "country_code": "NL",
        "region_code": "FR",
        "region_name": "Friesland"
    },
    {
        "country_code": "NL",
        "region_code": "GL",
        "region_name": "Gelderland"
    },
    {
        "country_code": "NL",
        "region_code": "GR",
        "region_name": "Groningen"
    },
    {
        "country_code": "NL",
        "region_code": "LB",
        "region_name": "Limburg"
    },
    {
        "country_code": "NL",
        "region_code": "NB",
        "region_name": "Noord-Brabant"
    },
    {
        "country_code": "NL",
        "region_code": "NH",
        "region_name": "Noord-Holland"
    },
    {
        "country_code": "NL",
        "region_code": "OV",
        "region_name": "Overijssel"
    },
    {
        "country_code": "NL",
        "region_code": "UT",
        "region_name": "Utrecht"
    },
    {
        "country_code": "NL",
        "region_code": "ZH",
        "region_name": "Zuid-Holland"
    },
    {
        "country_code": "NL",
        "region_code": "ZL",
        "region_name": "Zeeland"
    },
    {
        "country_code": "MX",
        "region_code": "BCN",
        "region_name": "Baja California Norte"
    },
    {
        "country_code": "MX",
        "region_code": "BCS",
        "region_name": "Baja California Sur"
    },
    {
        "country_code": "MX",
        "region_code": "CAM",
        "region_name": "Campeche"
    },
    {
        "country_code": "MX",
        "region_code": "CHIS",
        "region_name": "Chiapas"
    },
    {
        "country_code": "MX",
        "region_code": "CHIH",
        "region_name": "Chihuahua"
    },
    {
        "country_code": "MX",
        "region_code": "COAH",
        "region_name": "Coahuila"
    },
    {
        "country_code": "MX",
        "region_code": "COL",
        "region_name": "Colima"
    },
    {
        "country_code": "MX",
        "region_code": "DF",
        "region_name": "Distrito Federal"
    },
    {
        "country_code": "MX",
        "region_code": "DGO",
        "region_name": "Durango"
    },
    {
        "country_code": "MX",
        "region_code": "GTO",
        "region_name": "Guanajuato"
    },
    {
        "country_code": "MX",
        "region_code": "GRO",
        "region_name": "Guerrero"
    },
    {
        "country_code": "MX",
        "region_code": "HGO",
        "region_name": "Hidalgo"
    },
    {
        "country_code": "MX",
        "region_code": "JAL",
        "region_name": "Jalisco"
    },
    {
        "country_code": "MX",
        "region_code": "MEX",
        "region_name": "México (Estado de)"
    },
    {
        "country_code": "MX",
        "region_code": "MICH",
        "region_name": "Michoacán"
    },
    {
        "country_code": "MX",
        "region_code": "MOR",
        "region_name": "Morelos"
    },
    {
        "country_code": "MX",
        "region_code": "NAY",
        "region_name": "Nayarit"
    },
    {
        "country_code": "MX",
        "region_code": "NL",
        "region_name": "Nuevo León"
    },
    {
        "country_code": "MX",
        "region_code": "OAX",
        "region_name": "Oaxaca"
    },
    {
        "country_code": "MX",
        "region_code": "PUE",
        "region_name": "Puebla"
    },
    {
        "country_code": "MX",
        "region_code": "QRO",
        "region_name": "Querétaro"
    },
    {
        "country_code": "MX",
        "region_code": "QROO",
        "region_name": "Quintana Roo"
    },
    {
        "country_code": "MX",
        "region_code": "SLP",
        "region_name": "San Luis Potosí"
    },
    {
        "country_code": "MX",
        "region_code": "SIN",
        "region_name": "Sinaloa"
    },
    {
        "country_code": "MX",
        "region_code": "SON",
        "region_name": "Sonora"
    },
    {
        "country_code": "MX",
        "region_code": "TAB",
        "region_name": "Tabasco"
    },
    {
        "country_code": "MX",
        "region_code": "TAMPS",
        "region_name": "Tamaulipas"
    },
    {
        "country_code": "MX",
        "region_code": "TLAX",
        "region_name": "Tlaxcala"
    },
    {
        "country_code": "MX",
        "region_code": "VER",
        "region_name": "Veracruz"
    },
    {
        "country_code": "MX",
        "region_code": "YUC",
        "region_name": "Yucatán"
    },
    {
        "country_code": "MX",
        "region_code": "ZAC",
        "region_name": "Zacatecas"
    },
    {
        "country_code": "MX",
        "region_code": "AGS",
        "region_name": "Aguascalientes"
    },
    {
        "country_code": "IT",
        "region_code": "AG",
        "region_name": "Agrigento"
    },
    {
        "country_code": "IT",
        "region_code": "AL",
        "region_name": "Alessandria"
    },
    {
        "country_code": "IT",
        "region_code": "AN",
        "region_name": "Ancona"
    },
    {
        "country_code": "IT",
        "region_code": "AO",
        "region_name": "Aosta / Aoste"
    },
    {
        "country_code": "IT",
        "region_code": "AR",
        "region_name": "Arezzo"
    },
    {
        "country_code": "IT",
        "region_code": "AP",
        "region_name": "Ascoli Piceno"
    },
    {
        "country_code": "IT",
        "region_code": "AT",
        "region_name": "Asti"
    },
    {
        "country_code": "IT",
        "region_code": "AV",
        "region_name": "Avellino"
    },
    {
        "country_code": "IT",
        "region_code": "BA",
        "region_name": "Bari"
    },
    {
        "country_code": "IT",
        "region_code": "BT",
        "region_name": "Barletta-Andria-Trani"
    },
    {
        "country_code": "IT",
        "region_code": "BL",
        "region_name": "Belluno"
    },
    {
        "country_code": "IT",
        "region_code": "BN",
        "region_name": "Benevento"
    },
    {
        "country_code": "IT",
        "region_code": "BG",
        "region_name": "Bergamo"
    },
    {
        "country_code": "IT",
        "region_code": "BI",
        "region_name": "Biella"
    },
    {
        "country_code": "IT",
        "region_code": "BO",
        "region_name": "Bologna"
    },
    {
        "country_code": "IT",
        "region_code": "BZ",
        "region_name": "Bolzano / Bolzen"
    },
    {
        "country_code": "IT",
        "region_code": "BS",
        "region_name": "Brescia"
    },
    {
        "country_code": "IT",
        "region_code": "BR",
        "region_name": "Brindisi"
    },
    {
        "country_code": "IT",
        "region_code": "CA",
        "region_name": "Cagliari"
    },
    {
        "country_code": "IT",
        "region_code": "CL",
        "region_name": "Caltanisetta"
    },
    {
        "country_code": "IT",
        "region_code": "CB",
        "region_name": "Campobasso"
    },
    {
        "country_code": "IT",
        "region_code": "CI",
        "region_name": "Carbonia-Iglesias"
    },
    {
        "country_code": "IT",
        "region_code": "CE",
        "region_name": "Caserta"
    },
    {
        "country_code": "IT",
        "region_code": "CT",
        "region_name": "Catania"
    },
    {
        "country_code": "IT",
        "region_code": "CZ",
        "region_name": "Catanzaro"
    },
    {
        "country_code": "IT",
        "region_code": "CH",
        "region_name": "Chieti"
    },
    {
        "country_code": "IT",
        "region_code": "CO",
        "region_name": "Como"
    },
    {
        "country_code": "IT",
        "region_code": "CS",
        "region_name": "Cosenza"
    },
    {
        "country_code": "IT",
        "region_code": "CR",
        "region_name": "Cremona"
    },
    {
        "country_code": "IT",
        "region_code": "KR",
        "region_name": "Crotone"
    },
    {
        "country_code": "IT",
        "region_code": "CN",
        "region_name": "Cuneo"
    },
    {
        "country_code": "IT",
        "region_code": "EN",
        "region_name": "Enna"
    },
    {
        "country_code": "IT",
        "region_code": "FM",
        "region_name": "Fermo"
    },
    {
        "country_code": "IT",
        "region_code": "FE",
        "region_name": "Ferrara"
    },
    {
        "country_code": "IT",
        "region_code": "FI",
        "region_name": "Firenze"
    },
    {
        "country_code": "IT",
        "region_code": "FG",
        "region_name": "Foggia"
    },
    {
        "country_code": "IT",
        "region_code": "FC",
        "region_name": "Forlì-Cesena"
    },
    {
        "country_code": "IT",
        "region_code": "FR",
        "region_name": "Frosinone"
    },
    {
        "country_code": "IT",
        "region_code": "GE",
        "region_name": "Genova"
    },
    {
        "country_code": "IT",
        "region_code": "GO",
        "region_name": "Gorizia"
    },
    {
        "country_code": "IT",
        "region_code": "GR",
        "region_name": "Grosseto"
    },
    {
        "country_code": "IT",
        "region_code": "IM",
        "region_name": "Imperia"
    },
    {
        "country_code": "IT",
        "region_code": "IS",
        "region_name": "Isernia"
    },
    {
        "country_code": "IT",
        "region_code": "AQ",
        "region_name": "L'Aquila"
    },
    {
        "country_code": "IT",
        "region_code": "SP",
        "region_name": "La Spezia"
    },
    {
        "country_code": "IT",
        "region_code": "LT",
        "region_name": "Latina"
    },
    {
        "country_code": "IT",
        "region_code": "LE",
        "region_name": "Lecce"
    },
    {
        "country_code": "IT",
        "region_code": "LC",
        "region_name": "Lecco"
    },
    {
        "country_code": "IT",
        "region_code": "LI",
        "region_name": "Livorno"
    },
    {
        "country_code": "IT",
        "region_code": "LO",
        "region_name": "Lodi"
    },
    {
        "country_code": "IT",
        "region_code": "LU",
        "region_name": "Lucca"
    },
    {
        "country_code": "IT",
        "region_code": "MC",
        "region_name": "Macerata"
    },
    {
        "country_code": "IT",
        "region_code": "MN",
        "region_name": "Mantova"
    },
    {
        "country_code": "IT",
        "region_code": "MS",
        "region_name": "Massa-Carrara"
    },
    {
        "country_code": "IT",
        "region_code": "MT",
        "region_name": "Matera"
    },
    {
        "country_code": "IT",
        "region_code": "VS",
        "region_name": "Medio Campidano"
    },
    {
        "country_code": "IT",
        "region_code": "ME",
        "region_name": "Messina"
    },
    {
        "country_code": "IT",
        "region_code": "MI",
        "region_name": "Milano"
    },
    {
        "country_code": "IT",
        "region_code": "MO",
        "region_name": "Modena"
    },
    {
        "country_code": "IT",
        "region_code": "MB",
        "region_name": "Monza e della Brianza"
    },
    {
        "country_code": "IT",
        "region_code": "NA",
        "region_name": "Napoli"
    },
    {
        "country_code": "IT",
        "region_code": "NO",
        "region_name": "Novara"
    },
    {
        "country_code": "IT",
        "region_code": "NU",
        "region_name": "Nuoro"
    },
    {
        "country_code": "IT",
        "region_code": "OG",
        "region_name": "Ogliastra"
    },
    {
        "country_code": "IT",
        "region_code": "OT",
        "region_name": "Olbia-Tempio"
    },
    {
        "country_code": "IT",
        "region_code": "OR",
        "region_name": "Oristano"
    },
    {
        "country_code": "IT",
        "region_code": "PD",
        "region_name": "Padova"
    },
    {
        "country_code": "IT",
        "region_code": "PA",
        "region_name": "Palermo"
    },
    {
        "country_code": "IT",
        "region_code": "PR",
        "region_name": "Parma"
    },
    {
        "country_code": "IT",
        "region_code": "PV",
        "region_name": "Pavia"
    },
    {
        "country_code": "IT",
        "region_code": "PG",
        "region_name": "Perugia"
    },
    {
        "country_code": "IT",
        "region_code": "PS",
        "region_name": "Pesaro-Urbino"
    },
    {
        "country_code": "IT",
        "region_code": "PE",
        "region_name": "Pescara"
    },
    {
        "country_code": "IT",
        "region_code": "PC",
        "region_name": "Piacenza"
    },
    {
        "country_code": "IT",
        "region_code": "PI",
        "region_name": "Pisa"
    },
    {
        "country_code": "IT",
        "region_code": "PT",
        "region_name": "Pistoia"
    },
    {
        "country_code": "IT",
        "region_code": "PN",
        "region_name": "Pordenone"
    },
    {
        "country_code": "IT",
        "region_code": "PZ",
        "region_name": "Potenza"
    },
    {
        "country_code": "IT",
        "region_code": "PO",
        "region_name": "Prato"
    },
    {
        "country_code": "IT",
        "region_code": "RG",
        "region_name": "Ragusa"
    },
    {
        "country_code": "IT",
        "region_code": "RA",
        "region_name": "Ravenna"
    },
    {
        "country_code": "IT",
        "region_code": "RC",
        "region_name": "Reggio di Calabria"
    },
    {
        "country_code": "IT",
        "region_code": "RE",
        "region_name": "Reggio nell'Emilia"
    },
    {
        "country_code": "IT",
        "region_code": "RI",
        "region_name": "Rieti"
    },
    {
        "country_code": "IT",
        "region_code": "RN",
        "region_name": "Rimini"
    },
    {
        "country_code": "IT",
        "region_code": "RM",
        "region_name": "Roma"
    },
    {
        "country_code": "IT",
        "region_code": "RO",
        "region_name": "Rovigo"
    },
    {
        "country_code": "IT",
        "region_code": "SA",
        "region_name": "Salerno"
    },
    {
        "country_code": "IT",
        "region_code": "SS",
        "region_name": "Sassari"
    },
    {
        "country_code": "IT",
        "region_code": "SV",
        "region_name": "Savona"
    },
    {
        "country_code": "IT",
        "region_code": "SI",
        "region_name": "Siena"
    },
    {
        "country_code": "IT",
        "region_code": "SR",
        "region_name": "Siracusa"
    },
    {
        "country_code": "IT",
        "region_code": "SO",
        "region_name": "Sondrio"
    },
    {
        "country_code": "IT",
        "region_code": "TA",
        "region_name": "Taranto"
    },
    {
        "country_code": "IT",
        "region_code": "TE",
        "region_name": "Teramo"
    },
    {
        "country_code": "IT",
        "region_code": "TR",
        "region_name": "Terni"
    },
    {
        "country_code": "IT",
        "region_code": "TO",
        "region_name": "Torino"
    },
    {
        "country_code": "IT",
        "region_code": "TP",
        "region_name": "Trapani"
    },
    {
        "country_code": "IT",
        "region_code": "TN",
        "region_name": "Trento"
    },
    {
        "country_code": "IT",
        "region_code": "TV",
        "region_name": "Treviso"
    },
    {
        "country_code": "IT",
        "region_code": "TS",
        "region_name": "Trieste"
    },
    {
        "country_code": "IT",
        "region_code": "UD",
        "region_name": "Udine"
    },
    {
        "country_code": "IT",
        "region_code": "VA",
        "region_name": "Varese"
    },
    {
        "country_code": "IT",
        "region_code": "VE",
        "region_name": "Venezia"
    },
    {
        "country_code": "IT",
        "region_code": "VB",
        "region_name": "Verbano-Cusio-Ossola"
    },
    {
        "country_code": "IT",
        "region_code": "VC",
        "region_name": "Vercelli"
    },
    {
        "country_code": "IT",
        "region_code": "VR",
        "region_name": "Verona"
    },
    {
        "country_code": "IT",
        "region_code": "VV",
        "region_name": "Vibo Valentia"
    },
    {
        "country_code": "IT",
        "region_code": "VI",
        "region_name": "Vicenza"
    },
    {
        "country_code": "IT",
        "region_code": "VT",
        "region_name": "Viterbo"
    },
    {
        "country_code": "IN",
        "region_code": "NL",
        "region_name": "Nagaland"
    },
    {
        "country_code": "IN",
        "region_code": "OR",
        "region_name": "Orissa"
    },
    {
        "country_code": "IN",
        "region_code": "PB",
        "region_name": "Punjab"
    },
    {
        "country_code": "IN",
        "region_code": "RJ",
        "region_name": "Rajasthan"
    },
    {
        "country_code": "IN",
        "region_code": "SK",
        "region_name": "Sikkim"
    },
    {
        "country_code": "IN",
        "region_code": "TN",
        "region_name": "Tamil Nadu"
    },
    {
        "country_code": "IN",
        "region_code": "TR",
        "region_name": "Tripura"
    },
    {
        "country_code": "IN",
        "region_code": "UL",
        "region_name": "Uttarakhand"
    },
    {
        "country_code": "IN",
        "region_code": "UP",
        "region_name": "Uttar Pradesh"
    },
    {
        "country_code": "IN",
        "region_code": "WB",
        "region_name": "West Bengal"
    },
    {
        "country_code": "IN",
        "region_code": "AN",
        "region_name": "Andaman and Nicobar Islands"
    },
    {
        "country_code": "IN",
        "region_code": "CH",
        "region_name": "Chandigarh"
    },
    {
        "country_code": "IN",
        "region_code": "DN",
        "region_name": "Dadra and Nagar Haveli"
    },
    {
        "country_code": "IN",
        "region_code": "DD",
        "region_name": "Daman and Diu"
    },
    {
        "country_code": "IN",
        "region_code": "DL",
        "region_name": "Delhi"
    },
    {
        "country_code": "IN",
        "region_code": "LD",
        "region_name": "Lakshadweep"
    },
    {
        "country_code": "IN",
        "region_code": "PY",
        "region_name": "Puducherry"
    },
    {
        "country_code": "IN",
        "region_code": "AP",
        "region_name": "Andhra Pradesh"
    },
    {
        "country_code": "IN",
        "region_code": "AR",
        "region_name": "Arunachal Pradesh"
    },
    {
        "country_code": "IN",
        "region_code": "AS",
        "region_name": "Assam"
    },
    {
        "country_code": "IN",
        "region_code": "BR",
        "region_name": "Bihar"
    },
    {
        "country_code": "IN",
        "region_code": "CT",
        "region_name": "Chhattisgarh"
    },
    {
        "country_code": "IN",
        "region_code": "GA",
        "region_name": "Goa"
    },
    {
        "country_code": "IN",
        "region_code": "GJ",
        "region_name": "Gujarat"
    },
    {
        "country_code": "IN",
        "region_code": "HR",
        "region_name": "Haryana"
    },
    {
        "country_code": "IN",
        "region_code": "HP",
        "region_name": "Himachal Pradesh"
    },
    {
        "country_code": "IN",
        "region_code": "JK",
        "region_name": "Jammu and Kashmir"
    },
    {
        "country_code": "IN",
        "region_code": "JH",
        "region_name": "Jharkhand"
    },
    {
        "country_code": "IN",
        "region_code": "KA",
        "region_name": "Karnataka"
    },
    {
        "country_code": "IN",
        "region_code": "KL",
        "region_name": "Kerala"
    },
    {
        "country_code": "IN",
        "region_code": "MP",
        "region_name": "Madhya Pradesh"
    },
    {
        "country_code": "IN",
        "region_code": "MH",
        "region_name": "Maharashtra"
    },
    {
        "country_code": "IN",
        "region_code": "MN",
        "region_name": "Manipur"
    },
    {
        "country_code": "IN",
        "region_code": "ML",
        "region_name": "Meghalaya"
    },
    {
        "country_code": "IN",
        "region_code": "MZ",
        "region_name": "Mizoram"
    },
    {
        "country_code": "HR",
        "region_code": "BJ",
        "region_name": "Bjelovarsko-bilogorska"
    },
    {
        "country_code": "HR",
        "region_code": "SB",
        "region_name": "Brodsko-posavska"
    },
    {
        "country_code": "HR",
        "region_code": "DU",
        "region_name": "Dubrovačko-neretvanska"
    },
    {
        "country_code": "HR",
        "region_code": "ZG",
        "region_name": "Grad Zagreb"
    },
    {
        "country_code": "HR",
        "region_code": "IS",
        "region_name": "Istarska"
    },
    {
        "country_code": "HR",
        "region_code": "KA",
        "region_name": "Karlovačka"
    },
    {
        "country_code": "HR",
        "region_code": "KC",
        "region_name": "Koprivničko-križevačka"
    },
    {
        "country_code": "HR",
        "region_code": "KR",
        "region_name": "Krapinsko-zagorska"
    },
    {
        "country_code": "HR",
        "region_code": "GS",
        "region_name": "Ličko-senjska"
    },
    {
        "country_code": "HR",
        "region_code": "CA",
        "region_name": "Međimurska"
    },
    {
        "country_code": "HR",
        "region_code": "OS",
        "region_name": "Osječko-baranjska"
    },
    {
        "country_code": "HR",
        "region_code": "PZ",
        "region_name": "Požeško-slavonska"
    },
    {
        "country_code": "HR",
        "region_code": "PG",
        "region_name": "Primorsko-goranska"
    },
    {
        "country_code": "HR",
        "region_code": "SK",
        "region_name": "Sisačko-moslavačka"
    },
    {
        "country_code": "HR",
        "region_code": "ST",
        "region_name": "Splitsko-dalmatinska"
    },
    {
        "country_code": "HR",
        "region_code": "SI",
        "region_name": "Šibensko-kninska"
    },
    {
        "country_code": "HR",
        "region_code": "VZ",
        "region_name": "Varaždinska"
    },
    {
        "country_code": "HR",
        "region_code": "VT",
        "region_name": "Virovitičko-podravska"
    },
    {
        "country_code": "HR",
        "region_code": "VU",
        "region_name": "Vukovarsko-srijemska"
    },
    {
        "country_code": "HR",
        "region_code": "ZD",
        "region_name": "Zadarska"
    },
    {
        "country_code": "HR",
        "region_code": "ZGZ",
        "region_name": "Zagrebačka"
    },
    {
        "country_code": "FR",
        "region_code": "ARA",
        "region_name": "Auvergne-Rh™ne-Alpes"
    },
    {
        "country_code": "FR",
        "region_code": "BFC",
        "region_name": "Bourgogne-Franche-ComtЋ"
    },
    {
        "country_code": "FR",
        "region_code": "BRE",
        "region_name": "Bretagne"
    },
    {
        "country_code": "FR",
        "region_code": "COR",
        "region_name": "Corse"
    },
    {
        "country_code": "FR",
        "region_code": "CVL",
        "region_name": "Centre-Val de Loire"
    },
    {
        "country_code": "FR",
        "region_code": "GES",
        "region_name": "Grand Est"
    },
    {
        "country_code": "FR",
        "region_code": "GU",
        "region_name": "Guyane"
    },
    {
        "country_code": "FR",
        "region_code": "GUA",
        "region_name": "Guadeloupe"
    },
    {
        "country_code": "FR",
        "region_code": "HDF",
        "region_name": "Hauts-de-France"
    },
    {
        "country_code": "FR",
        "region_code": "IDF",
        "region_name": "лle-de-France"
    },
    {
        "country_code": "FR",
        "region_code": "LRE",
        "region_name": "La RЋunion"
    },
    {
        "country_code": "FR",
        "region_code": "MAY",
        "region_name": "Mayotte"
    },
    {
        "country_code": "FR",
        "region_code": "MQ",
        "region_name": "Martinique"
    },
    {
        "country_code": "FR",
        "region_code": "NAQ",
        "region_name": "Nouvelle-Aquitaine"
    },
    {
        "country_code": "FR",
        "region_code": "NOR",
        "region_name": "Normandie"
    },
    {
        "country_code": "FR",
        "region_code": "OCC",
        "region_name": "Occitanie"
    },
    {
        "country_code": "FR",
        "region_code": "PAC",
        "region_name": "Provence-Alpes-C™te d'Azur"
    },
    {
        "country_code": "FR",
        "region_code": "PDL",
        "region_name": "Pays de la Loire"
    },
    {
        "country_code": "ES",
        "region_code": "AND",
        "region_name": "Andalucía"
    },
    {
        "country_code": "ES",
        "region_code": "ARA",
        "region_name": "Aragón"
    },
    {
        "country_code": "ES",
        "region_code": "CAN",
        "region_name": "Canarias"
    },
    {
        "country_code": "ES",
        "region_code": "CAN",
        "region_name": "Cantabria"
    },
    {
        "country_code": "ES",
        "region_code": "CLM",
        "region_name": "Castilla-La Mancha"
    },
    {
        "country_code": "ES",
        "region_code": "CyL",
        "region_name": "Castilla y León"
    },
    {
        "country_code": "ES",
        "region_code": "CAT",
        "region_name": "Cataluña"
    },
    {
        "country_code": "ES",
        "region_code": "CEU",
        "region_name": "Ceuta"
    },
    {
        "country_code": "ES",
        "region_code": "MAD",
        "region_name": "Comunidad de Madrid"
    },
    {
        "country_code": "ES",
        "region_code": "CVA",
        "region_name": "Comunidad Valenciana"
    },
    {
        "country_code": "ES",
        "region_code": "EXT",
        "region_name": "Extremadura"
    },
    {
        "country_code": "ES",
        "region_code": "GAL",
        "region_name": "Galicia"
    },
    {
        "country_code": "ES",
        "region_code": "IB",
        "region_name": "Islas Baleares"
    },
    {
        "country_code": "ES",
        "region_code": "LRJ",
        "region_name": "La Rioja"
    },
    {
        "country_code": "ES",
        "region_code": "MEL",
        "region_name": "Melilla"
    },
    {
        "country_code": "ES",
        "region_code": "NAV",
        "region_name": "Navarra"
    },
    {
        "country_code": "ES",
        "region_code": "PV",
        "region_name": "País Vasco"
    },
    {
        "country_code": "ES",
        "region_code": "AST",
        "region_name": "Principado de Asturias"
    },
    {
        "country_code": "ES",
        "region_code": "RMU",
        "region_name": "Región de Murcia"
    },
    {
        "country_code": "DK",
        "region_code": "RH",
        "region_name": "Region Hovedstaden"
    },
    {
        "country_code": "DK",
        "region_code": "RM",
        "region_name": "Region Midtjylland"
    },
    {
        "country_code": "DK",
        "region_code": "RN",
        "region_name": "Region Nordjylland"
    },
    {
        "country_code": "DK",
        "region_code": "RSj",
        "region_name": "Region Sjælland"
    },
    {
        "country_code": "DK",
        "region_code": "RSy",
        "region_name": "Region Syddanmark"
    },
    {
        "country_code": "DE",
        "region_code": "BW",
        "region_name": "Baden-Württemberg"
    },
    {
        "country_code": "DE",
        "region_code": "BY",
        "region_name": "Bayern"
    },
    {
        "country_code": "DE",
        "region_code": "BE",
        "region_name": "Berlin"
    },
    {
        "country_code": "DE",
        "region_code": "BR",
        "region_name": "Brandenburg"
    },
    {
        "country_code": "DE",
        "region_code": "HB",
        "region_name": "Bremen"
    },
    {
        "country_code": "DE",
        "region_code": "HH",
        "region_name": "Hamburg"
    },
    {
        "country_code": "DE",
        "region_code": "HE",
        "region_name": "Hessen"
    },
    {
        "country_code": "DE",
        "region_code": "MV",
        "region_name": "Mecklenburg-Vorpommern"
    },
    {
        "country_code": "DE",
        "region_code": "NI",
        "region_name": "Niedersachsen"
    },
    {
        "country_code": "DE",
        "region_code": "NW",
        "region_name": "Nordrhein-Westfalen"
    },
    {
        "country_code": "DE",
        "region_code": "RP",
        "region_name": "Rheinland-Pfalz"
    },
    {
        "country_code": "DE",
        "region_code": "SL",
        "region_name": "Saarland"
    },
    {
        "country_code": "DE",
        "region_code": "SN",
        "region_name": "Sachsen"
    },
    {
        "country_code": "DE",
        "region_code": "ST",
        "region_name": "Sachsen-Anhalt"
    },
    {
        "country_code": "DE",
        "region_code": "SH",
        "region_name": "Schleswig-Holstein"
    },
    {
        "country_code": "DE",
        "region_code": "TH",
        "region_name": "Thüringen"
    },
    {
        "country_code": "CA",
        "region_code": "AB",
        "region_name": "Alberta"
    },
    {
        "country_code": "CA",
        "region_code": "BC",
        "region_name": "British Columbia"
    },
    {
        "country_code": "CA",
        "region_code": "MB",
        "region_name": "Manitoba"
    },
    {
        "country_code": "CA",
        "region_code": "NB",
        "region_name": "New Brunswick"
    },
    {
        "country_code": "CA",
        "region_code": "NL",
        "region_name": "Newfoundland and Labrador"
    },
    {
        "country_code": "CA",
        "region_code": "NT",
        "region_name": "Northwest Territories"
    },
    {
        "country_code": "CA",
        "region_code": "NS",
        "region_name": "Nova Scotia"
    },
    {
        "country_code": "CA",
        "region_code": "NU",
        "region_name": "Nunavut"
    },
    {
        "country_code": "CA",
        "region_code": "ON",
        "region_name": "Ontario"
    },
    {
        "country_code": "CA",
        "region_code": "PE",
        "region_name": "Prince Edward Island"
    },
    {
        "country_code": "CA",
        "region_code": "QC",
        "region_name": "Quebec"
    },
    {
        "country_code": "CA",
        "region_code": "SK",
        "region_name": "Saskatchewan"
    },
    {
        "country_code": "CA",
        "region_code": "YT",
        "region_name": "Yukon"
    },
    {
        "country_code": "BR",
        "region_code": "AC",
        "region_name": "Acre"
    },
    {
        "country_code": "BR",
        "region_code": "AL",
        "region_name": "Alagoas"
    },
    {
        "country_code": "BR",
        "region_code": "AP",
        "region_name": "Amapá"
    },
    {
        "country_code": "BR",
        "region_code": "AM",
        "region_name": "Amazonas"
    },
    {
        "country_code": "BR",
        "region_code": "BA",
        "region_name": "Bahía"
    },
    {
        "country_code": "BR",
        "region_code": "CE",
        "region_name": "Ceará"
    },
    {
        "country_code": "BR",
        "region_code": "DF",
        "region_name": "Distrito Federal"
    },
    {
        "country_code": "BR",
        "region_code": "ES",
        "region_name": "Espirito Santo"
    },
    {
        "country_code": "BR",
        "region_code": "GO",
        "region_name": "Goiás"
    },
    {
        "country_code": "BR",
        "region_code": "MA",
        "region_name": "Maranhão"
    },
    {
        "country_code": "BR",
        "region_code": "MT",
        "region_name": "Mato Grosso"
    },
    {
        "country_code": "BR",
        "region_code": "MS",
        "region_name": "Mato Grosso do Sul"
    },
    {
        "country_code": "BR",
        "region_code": "MG",
        "region_name": "Minas Gerais"
    },
    {
        "country_code": "BR",
        "region_code": "PR",
        "region_name": "Paraná"
    },
    {
        "country_code": "BR",
        "region_code": "PB",
        "region_name": "Paraíba"
    },
    {
        "country_code": "BR",
        "region_code": "PA",
        "region_name": "Pará"
    },
    {
        "country_code": "BR",
        "region_code": "PE",
        "region_name": "Pernambuco"
    },
    {
        "country_code": "BR",
        "region_code": "PI",
        "region_name": "Piauí"
    },
    {
        "country_code": "BR",
        "region_code": "RN",
        "region_name": "Rio Grande do Norte"
    },
    {
        "country_code": "BR",
        "region_code": "RS",
        "region_name": "Rio Grande do Sul"
    },
    {
        "country_code": "BR",
        "region_code": "RJ",
        "region_name": "Rio de Janeiro"
    },
    {
        "country_code": "BR",
        "region_code": "RO",
        "region_name": "Rondônia"
    },
    {
        "country_code": "BR",
        "region_code": "RR",
        "region_name": "Roraima"
    },
    {
        "country_code": "BR",
        "region_code": "SC",
        "region_name": "Santa Catarina"
    },
    {
        "country_code": "BR",
        "region_code": "SE",
        "region_name": "Sergipe"
    },
    {
        "country_code": "BR",
        "region_code": "SP",
        "region_name": "São Paulo"
    },
    {
        "country_code": "BR",
        "region_code": "TO",
        "region_name": "Tocantins"
    },
    {
        "country_code": "AU",
        "region_code": "ACT",
        "region_name": "Australian Capital Territory"
    },
    {
        "country_code": "AU",
        "region_code": "NSW",
        "region_name": "New South Wales"
    },
    {
        "country_code": "AU",
        "region_code": "NT",
        "region_name": "Northern Territory"
    },
    {
        "country_code": "AU",
        "region_code": "QLD",
        "region_name": "Queensland"
    },
    {
        "country_code": "AU",
        "region_code": "SA",
        "region_name": "South Australia"
    },
    {
        "country_code": "AU",
        "region_code": "TAS",
        "region_name": "Tasmania"
    },
    {
        "country_code": "AU",
        "region_code": "VIC",
        "region_name": "Victoria"
    },
    {
        "country_code": "AU",
        "region_code": "WA",
        "region_name": "Western Australia"
    }
];

export function getCountries() {
    return [...new Set(places.map(place => place.country_code))];
}
export function getStatesForCountry(country) {
    return country ? places.filter(place => place.country_code === country).map(place => ({code: place.region_code, name: place.region_name})): [];
}