/* istanbul ignore file */
import './App.css';
import theme from "./theme";
import {Box, ThemeProvider} from "@mui/material";
import {ThemeProvider as StorybookThemeProvider} from '@enphase/designsystems/Theme';
import {BrowserRouter} from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from "@mui/x-date-pickers";
import 'react-toastify/dist/ReactToastify.css';
import Routes from "./Routes";
import SnackbarView from "./components/Snackbar";
import useRegisterLogout from "./components/useRegisterLogout";
import {ToastContainer} from "react-toastify";

function App() {
    useRegisterLogout();
    return (
        <Box>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <StorybookThemeProvider>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <BrowserRouter>
                            <Routes/>
                        </BrowserRouter>
                    </LocalizationProvider>
                </ThemeProvider>
            </StorybookThemeProvider>
            <SnackbarView/>
        </Box>
    );
}

export default App;
