import {Typography} from "@mui/material";
import {makeStyles} from '@mui/styles';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    header: {
        fontSize: 35,
        fontWeight: 700,
        [theme.breakpoints.down('md')]:{
            fontSize: 28,
        }
    }
}))

export default function Header() {
    const classes = useStyles();
    const {t} = useTranslation();
    return <Typography className={classes.header} color="primary.dark">{t('RMA Status Dashboard')}</Typography>
}