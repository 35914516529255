

export const sanitise = (string)=> {
    return (string || '').replace('null','');
}
export const mapRMAResponse = (response) => {
    if (!response) {
        return {};
    }
    return {
        total: response.total,
        per_page: response.limit,
        page: Math.floor(response.offset / response.limit) + 1,
        records: response.records.map(r => ({
            caseNumber: sanitise(r?.rma_case?.case_number),
            rmaNumber: sanitise(r?.rma_number),
            rmaStatus: sanitise(r?.rma_customer_status),
            systemName: sanitise(r.rma_case?.site_name),
            systemId: sanitise(r?.rma_case?.site_id),
            source: sanitise(r?.source),
            modifiedDate: new Date(r?.modified_date).toLocaleDateString(),
            submittedDate: new Date(r?.created_date).toLocaleDateString()
        }))
    }
}

export const serialize = (obj) => {
    const str = [];
    for (let    k in obj) {
        if (obj.hasOwnProperty(k)) {
            const v = obj[k];
            str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}