import {useEffect, useState} from "react";
import {format} from "date-fns";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";

export function DateFilter({onFilter, disabled}) {
    const [date, setDate] = useState(null);
    const [keyboardInputValue, setKeyboardInputValue] = useState('');
    useEffect(() => {
        processDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    function processDate() {
        if (date instanceof Date && !isNaN(date.getDate())) {
            onFilter(format(date, 'yyyy/MM/dd'));
        } else {
            if (!keyboardInputValue) onFilter(null);
        }
    }

    return <form onSubmit={e => {
        e.preventDefault();
        processDate();
    }
    }>
        <DesktopDatePicker
            label={" "}
            value={date}
            onChange={(e, kv) => {
                setDate(e);
                setKeyboardInputValue(kv);
            }}
            inputFormat="dd/MM/yyyy"
            disableFuture
            disabled={disabled}
            clearable
            renderInput={(params) => <TextField size="small" data-testid="input" onBlur={processDate} InputLabelProps={{shrink: false}}
                                                fullWidth {...params} />}
        />
    </form>

}
